import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'
import VueRouter from "vue-router";

var SHOW_DUMMY_AKTINOGRAPH = false;
export default {
    async dummyDataAKTINOGRAPH(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01": [
                    {
                    "TGL"    : "2021-01",
                    "COL1"   : 1, 
                    "COL2"   : 2,
                    "COL3"   : 3,
                    "COL4"   : 4,
                    "COL5"   : 5,
                    "COL6"   : 6,
                    "COL7"   : 7,
                    "COL8"   : 8,
                    "COL9"   : 9,
                    "COL10"  : 10,
                    "COL11"  : 11,
                    "COL12"  : 12,
                    "COL13"  : 13,
                    "COL14"  : 14,
                    "COL15"  : 15,
                    "COL16"  : 16,
                    "COL17"  : 17,
                    "COL18"  : 18,
                    "COL19"  : 19,
                    "COL20"  : 20,
                    },
                    {
                    "TGL"    : "2021-03",
                    "COL1"   : 3, 
                    "COL2"   : 2,
                    "COL3"   : 3,
                    "COL4"   : 4,
                    "COL5"   : 5,
                    "COL6"   : 6,
                    "COL7"   : 7,
                    "COL8"   : 8,
                    "COL9"   : 9,
                    "COL10"  : 10,
                    "COL11"  : 11,
                    "COL12"  : 12,
                    "COL13"  : 13,
                    "COL14"  : 14,
                    "COL15"  : 15,
                    "COL16"  : 16,
                    "COL17"  : 17,
                    "COL18"  : 18,
                    "COL19"  : 19,
                    "COL20"  : 20,
                    },
                    {
                    "TGL"    : "2021-02",
                    "COL1"   : 2, 
                    "COL2"   : 2,
                    "COL3"   : 3,
                    "COL4"   : 4,
                    "COL5"   : 5,
                    "COL6"   : 6,
                    "COL7"   : 7,
                    "COL8"   : 8,
                    "COL9"   : 9,
                    "COL10"  : 10,
                    "COL11"  : 11,
                    "COL12"  : 12,
                    "COL13"  : 13,
                    "COL14"  : 14,
                    "COL15"  : 15,
                    "COL16"  : 16,
                    "COL17"  : 17,
                    "COL18"  : 18,
                    "COL19"  : 19,
                    "COL20"  : 20,
                    },
                ]
            }

            var dataBuild = {};
            for(var i in datas){
                dataBuild[i] = {"RAW" : [], "CALCULATE" : {} };
                var rawPeriode = datas[i];
                dataBuild[i]["RAW"] = rawPeriode.sort(function(a, b) {
                    return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                });
                
                var Total = {};
                var Count = {};
                var Rata  = {};
                var MaxRaw   = {};

                var RAW_DATA = [];
                for(var icount = 1; icount <= 31; icount++){
                    RAW_DATA.push({
                        "TGL"    : "00"+icount.substring(icount.length,2),
                        "COL1"   : 0, 
                        "COL2"   : 0,
                        "COL3"   : 0,
                        "COL4"   : 0,
                        "COL5"   : 0,
                        "COL6"   : 0,
                        "COL7"   : 0,
                        "COL8"   : 0,
                        "COL9"   : 0,
                        "COL10"  : 0,
                        "COL11"  : 0,
                        "COL12"  : 0,
                        "COL13"  : 0,
                        "COL14"  : 0,
                        "COL15"  : 0,
                        "COL16"  : 0,
                        "COL17"  : 0,
                        "COL18"  : 0,
                        "COL19"  : 0,
                        "COL20"  : 0,
                    });   
                }

                for(var j in rawPeriode){
                    var infoDara = rawPeriode[j];
                    var newDate = new Date(infoDara.TGL);

                    for(var m in infoDara){

                        if(RAW_DATA[Number(newDate.getDate())] != undefined ){
                            RAW_DATA[Number(newDate.getDate())][m] = infoDara[m];
                        }

                        if(m == "TGL"){
                            continue;
                        }

                        if(Total[m] == undefined ){
                            Total[m] = 0;
                            Count[m] = 0;
                            MaxRaw[m] = 0;
                        }
                        Total[m] += infoDara[m];
                        Count[m]++;
                        Rata[m] = Total[m] / Count[m];
                        if(infoDara[m] >= MaxRaw[m]){
                            MaxRaw[m] = infoDara[m];
                        }
                    }
                }
                dataBuild[i]["RAW"] = RAW_DATA;
                dataBuild[i]["CALCULATE"] = {JUMLAH : Total, RATA : Rata, MAX_RAW : MaxRaw};
            }
            datas = dataBuild;
            resolve(datas);
        });
    },

    async storeDataAKTINOGRAPH(STATION_ID,PERIODE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_AKTINOGRAPH(STATION_ID,PERIODE);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataAKTINOGRAPH(STATION_ID,PERIODE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_AKTINOGRAPH == true){
                var datas = await this.dummyDataAKTINOGRAPH();
                resolve(datas);
            }else{
                var thisData = await this.storeDataAKTINOGRAPH(STATION_ID,PERIODE);
                var datas = thisData.data;
                
                var dataBuild = {};
                for(var i in datas){
                    dataBuild[i] = {"RAW" : [], "CALCULATE" : {} };
                    var rawPeriode = datas[i];
                    dataBuild[i]["RAW"] = rawPeriode.sort(function(a, b) {
                        return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                    });
                    
                    var Total = {};
                    var Count = {};
                    var Rata  = {};
                    var MaxRaw   = {};

                    var RAW_DATA = [];
                    for(var icount = 1; icount <= 31; icount++){
                        RAW_DATA.push({
                            "TGL"    : "00"+icount.toString().substring(icount.length,2),
                            "COL1"   : "", 
                            "COL2"   : "",
                            "COL3"   : "",
                            "COL4"   : "",
                            "COL5"   : "",
                            "COL6"   : "",
                            "COL7"   : "",
                            "COL8"   : "",
                            "COL9"   : "",
                            "COL10"  : "",
                            "COL11"  : "",
                            "COL12"  : "",
                            "COL13"  : "",
                            "COL14"  : "",
                            "COL15"  : "",
                            "COL16"  : "",
                            "COL17"  : "",
                            "COL18"  : "",
                            "COL19"  : "",
                            "COL20"  : "",
                        });   
                    }
                    
                    for(var j in rawPeriode){
                        var infoDara = rawPeriode[j];
                        var newDate = new Date(infoDara.TGL);

                        for(var m in infoDara){
                            if(RAW_DATA[Number(newDate.getDate())-1] != undefined ){
                                RAW_DATA[Number(newDate.getDate())-1][m] = infoDara[m];
                            }
                            if(m == "TGL"){
                                continue;
                            }
                            if(Total[m] == undefined ){
                                Total[m] = 0;
                                Count[m] = 0;
                                MaxRaw[m] = 0;
                            }

                            if(infoDara[m] != null && infoDara[m] !="null"){
                                Total[m] += Number(infoDara[m]);
                                Count[m]++;
                                Rata[m] = Number(Total[m] / Count[m]).toFixed(1);
                                if(Number(infoDara[m]) >= MaxRaw[m]){
                                    MaxRaw[m] = Number(infoDara[m]);
                                }
                                dataBuild[i]["RAW"][j][m] = Number(Number(infoDara[m]).toFixed(1));
                            }else{
                                dataBuild[i]["RAW"][j][m] = "-";
                            }
                        }
                    }
                    dataBuild[i]["RAW"] = RAW_DATA;
                    dataBuild[i]["CALCULATE"] = {JUMLAH : Total, RATA : Rata, MAX_RAW : MaxRaw};
                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}