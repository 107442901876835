<template>
  <div style="margin:25px;">
    <!-- {{dataPDF}} -->
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <table class="title-report-AGMIB" style="margin-bottom:10px;">
        <tr>
          <td style="text-align:left;width:200px;">AgM 1-b</td>
          <td style="text-align:center;text-transform: uppercase;" colspan="5">TEMPERATUR TANAH DI {{ dataPDF.station.length > 0 ? dataPDF.station[0].station_name : "" }} TAHUN {{ dataPDF.tahun }}</td>
          <td style="text-align:right;">Halaman 1</td>
        </tr>
        <tr>
          <td>Garis Lintang</td>
          <td>:</td>
          <td style="width:400px;">
            <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].current_latitude }}  {{ (Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS")}}</span>
          </td>
          <td style="width:30%;"></td>
          <td rowspan="3">Waktu Peramatan</td>
          <td>I.</td>
          <td>Jam 07.00 WS</td>
        </tr>
        <tr>
          <td>Garis Bujur</td>
          <td>:</td>
          <td>
            <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].current_longitude }}</span>
          </td>
          <td></td>
          <td>II.</td>
          <td>Jam 14.00 WS</td>
        </tr>
        <tr>
          <td>Tinggi di atas permukaan</td>
          <td>:</td>
          <td>
            <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_elevation }}</span>
          </td>
          <td></td>
          <td>III.</td>
          <td>Jam 18.00 WS</td>
        </tr>
      </table>

      <table class="table-report-AGMIB">
        <thead>
          <tr>
            <td rowspan="3">No Periode</td>
            <td rowspan="3">No Mingguan</td>
            <td rowspan="3">TGL</td>
            <td colspan="11">Temperatur pada tanah gundul dalam derajat C</td>
            <td colspan="11">Temperatur pada tanah berumput pendek dalam derajat C</td>
            <td rowspan="3" colspan="2">Keterangan tentang keadaan tanah dan jatuhnya hujan</td>
          </tr>
          <tr>
            <td colspan="3" style="border-top:none;">I</td>
            <td colspan="3" style="border-top:none;">II</td>
            <td colspan="5" style="border-top:none;">III</td>
            <td colspan="3" style="border-top:none;">I</td>
            <td colspan="3" style="border-top:none;">II</td>
            <td colspan="5" style="border-top:none;border-right:none;">III</td>
          </tr>
          <tr>
            <td style="border-top:none;">5 cm</td>
            <td style="border-top:none;">10 cm</td>
            <td style="border-top:none;">20 cm</td>
            <td style="border-top:none;">5 cm</td>
            <td style="border-top:none;">10 cm</td>
            <td style="border-top:none;">20 cm</td>
            <td style="border-top:none;">5 cm</td>
            <td style="border-top:none;">10 cm</td>
            <td style="border-top:none;">20 cm</td>
            <td style="border-top:none;">50 cm</td>
            <td style="border-top:none;">100 cm</td>
            <td style="border-top:none;">5 cm</td>
            <td style="border-top:none;">10 cm</td>
            <td style="border-top:none;">20 cm</td>
            <td style="border-top:none;">5 cm</td>
            <td style="border-top:none;">10 cm</td>
            <td style="border-top:none;">20 cm</td>
            <td style="border-top:none;">5 cm</td>
            <td style="border-top:none;">10 cm</td>
            <td style="border-top:none;">20 cm</td>
            <td style="border-top:none;">50 cm</td>
            <td style="border-top:none;border-right:none;">100 cm</td>
          </tr>
          <tr>
            <td v-for="j in 26" :key="j + '_headerEmptyAGMIB'" style="border-top:none;">{{ j }}</td>
          </tr>
        </thead>
        <span style="display:none;">{{ (jml_start = 0) }}</span>
        <tbody v-for="(mingguRaw, indexMinggu) in item.HAL_1" :key="indexMinggu">
          <span style="display:none;">{{ (no = 1) }}</span>
          <tr v-for="(raw, indexRaw) in mingguRaw.RAW" :key="indexRaw" class="rowpan">
            <td v-if="jml_start == 0" v-bind:rowspan="JUMLAH">I</td>
            <td v-if="no == 1" v-bind:rowspan="mingguRaw.RAW.length">{{ indexMinggu }}</td>
            <td v-for="(colRaw, indexCol) in raw" :key="indexCol">{{ colRaw }}</td>
            <span style="display:none;">{{ no++ }}{{ jml_start++ }}{{ JUMLAH++ }}</span>
          </tr>
          <span style="display:none;">{{ (jml_start = 0) }}</span>
          <tr class="rowpan">
            <td colspan="2">Jumlah</td>
            <td v-for="(jmlRaw, indexJml) in mingguRaw.JUMLAH" :key="indexJml">{{ fixedValueNumber(jmlRaw.toFixed(1)) }}</td>
          </tr>
          <tr class="rowpan">
            <td colspan="2">Rata</td>
            <td v-for="(rataRaw, indexRata) in mingguRaw.RATA" :key="indexRata">{{ rataRaw }}</td>
          </tr>
          <span style="display:none;">{{ (JUMLAH = JUMLAH + 2) }}</span>
        </tbody>
      </table>

      <div class="html2pdf__page-break" />

      <table class="title-report-AGMIB" style="margin-bottom:10px;">
        <tr>
          <td colspan="3" style="text-align:center;padding:10px;text-transform: uppercase;">PENGUAPAN PANCI TERBUKA DI {{ dataPDF.station.length > 0 ? dataPDF.station[0].station_name : "" }} TAHUN {{ dataPDF.tahun }}</td>
        </tr>
        <tr>
          <td style="width:200px;text-align:left;">AgM 1-b</td>
          <td style="text-align:center;">CATATAN INTENSITAS KEJADIAN-KEJADIAN</td>
          <td style="width:200px;text-align:right;">Halaman 2</td>
        </tr>
      </table>

      <table class="table-report-AGMIA">
        <thead>
          <tr>
            <td rowspan="3">No Periode</td>
            <td rowspan="3">No Minggu</td>
            <td rowspan="3">Tgl</td>
            <td colspan="7">I</td>
            <td colspan="7">II</td>
            <td colspan="7">III</td>
            <td colspan="2">Jumlah</td>
            <td rowspan="3">Keterangan</td>
          </tr>
          <tr>
            <td style="border-top:none;" rowspan="2">Curah Hujan (mm)</td>
            <td style="border-top:none;" colspan="2">Kecepatan Angin Rata2</td>
            <td style="border-top:none;" rowspan="2">Suhu udara (C)</td>
            <td style="border-top:none;" rowspan="2">Kelembaban udara (%)</td>
            <td style="border-top:none;" rowspan="2">Suhu air (C)</td>
            <td style="border-top:none;" rowspan="2">Penguapan (mm)</td>
            <td style="border-top:none;" rowspan="2">Curah Hujan (mm)</td>
            <td style="border-top:none;" colspan="2">Kecepatan Angin Rata2</td>
            <td style="border-top:none;" rowspan="2">Suhu udara (C)</td>
            <td style="border-top:none;" rowspan="2">Kelembaban udara (%)</td>
            <td style="border-top:none;" rowspan="2">Suhu air (C)</td>
            <td style="border-top:none;" rowspan="2">Penguapan (mm)</td>
            <td style="border-top:none;" rowspan="2">Curah Hujan (mm)</td>
            <td style="border-top:none;" colspan="2">Kecepatan Angin Rata2</td>
            <td style="border-top:none;" rowspan="2">Suhu udara (C)</td>
            <td style="border-top:none;" rowspan="2">Kelembaban udara (%)</td>
            <td style="border-top:none;" rowspan="2">Suhu air (C)</td>
            <td style="border-top:none;" rowspan="2">Penguapan (mm)</td>
            <td style="border-top:none;" rowspan="2">Curah hujan (mm)</td>
            <td style="border-top:none;border-right:none;" rowspan="2">Penguap- an (mm)</td>
          </tr>
          <tr>
            <td style="border-top:none;">0,5 m</td>
            <td style="border-top:none;">10 m</td>
            <td style="border-top:none;">0,5 m</td>
            <td style="border-top:none;">10 m</td>
            <td style="border-top:none;">0,5 m</td>
            <td style="border-top:none;border-right:none;">10 m</td>
          </tr>
          <tr>
            <td v-for="j in 27" :key="j + '_headerEmptyAGMIB'" style="border-top:none;">{{ j }}</td>
          </tr>
        </thead>

        <span style="display:none;">{{ (jml_start = 0) }}</span>
        <tbody v-for="(mingguRaw, indexMinggu) in item.HAL_2" :key="indexMinggu">
          <span style="display:none;">{{ (no = 1) }}</span>
          <tr v-for="(raw, indexRaw) in mingguRaw.RAW" :key="indexRaw" class="rowpan">
            <td v-if="jml_start == 0" v-bind:rowspan="JUMLAH_2">I</td>
            <td v-if="no == 1" v-bind:rowspan="mingguRaw.RAW.length">{{ indexMinggu }}</td>
            <td v-for="(colRaw, indexCol) in raw" :key="indexCol">{{ colRaw }}</td>
            <span style="display:none;">{{ no++ }}{{ jml_start++ }}{{ JUMLAH_2++ }}</span>
          </tr>
          <span style="display:none;">{{ (jml_start = 0) }}</span>
          <tr class="rowpan">
            <td colspan="2">Jumlah</td>
            <td v-for="(jmlRaw, indexJml) in mingguRaw.JUMLAH" :key="indexJml">{{ fixedValueNumber(jmlRaw.toFixed(1)) }}</td>
          </tr>
          <tr class="rowpan">
            <td colspan="2">Rata</td>
            <td v-for="(rataRaw, indexRata) in mingguRaw.RATA" :key="indexRata">{{ rataRaw }}</td>
          </tr>
          <span style="display:none;">{{ (JUMLAH_2 = JUMLAH_2 + 2) }}</span>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportAGMIB",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult;
    },
  },
  data: function() {
    return {
      JUMLAH: 0,
      JUMLAH_2: 0,
    };
  },
};
</script>

<style>
.title-report-AGMIB {
  width: 1303px;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
}

.AGMIB td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-AGMIB td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-AGMIB > thead > tr > td {
  text-align: center;
  background: #e0e4e7;
  color: #000;
  border-left: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
}

.table-report-AGMIB tbody > tr > td {
  padding: 3px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 1px #000;
  /* border-bottom: solid 1px #000; */
}

.table-report-AGMIB tbody > tr > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-AGMIB tr {
  border: none !important;
}

.table-report-AGMIB thead :not(.table-code) > td {
  padding: 2.5px;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
}

.table-report-AGMIB thead :not(.table-code) > td:last-of-type {
  border-right: solid 0.5px #000;
}

.rowpan td {
  border: solid 0.5px #000;
  background-color: #fff;
}

.table-report-AGMIB {
  font-size: 7.5px !important;
  margin-bottom: 20px !important;
  width: 1303px;
}

.table-report-AGMIB td {
  max-width: 90px;
}
</style>
