<template>
  <div style="margin:25px;">
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <div class="title-report-GUNBELLANI">
        {{ dataPDF.title }}
        <div v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_name }}</div>
      </div>

      <div>
        <div style="font-size:12px;margin-top:20px;margin-bottom:20px;color:#000;">
          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;">BULAN</div>
            <div style="float:left;width:20px;">:</div>
            <div style="float:left;min-width:200px;">{{ dataPDF.bulan }}</div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_latitude }}  {{ (Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS")}}</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">LINTANG</div>
          </div>
          <div style="clear:both"></div>

          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;">TAHUN</div>
            <div style="float:left;width:20px;">:</div>
            <div style="float:left;min-width:200px;">{{ dataPDF.tahun }}</div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_longitude }} BT</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">BUJUR</div>
          </div>
          <div style="clear:both"></div>

          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;"></div>
            <div style="float:left;width:20px;"></div>
            <div style="float:left;min-width:200px;"></div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].station_elevation }} m</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">ELEVASI</div>
          </div>
          <div style="clear:both"></div>
        </div>
      </div>

      <div style="margin-top:10px;">
        <table class="table-report-LAMAPENYINARAN">
          <thead>
            <tr>
              <td rowspan="3">TGL</td>
              <td colspan="12">LAMANYA PENYINARAN ( JAM )</td>
              <td colspan="4">LAMA PENYINARAN</td>
            </tr>
            <tr>
              <td rowspan="2" style="border-top:none;">6-7</td>
              <td rowspan="2" style="border-top:none;">7-8</td>
              <td rowspan="2" style="border-top:none;">8-9</td>
              <td rowspan="2" style="border-top:none;">9-10</td>
              <td rowspan="2" style="border-top:none;">10-11</td>
              <td rowspan="2" style="border-top:none;">11-12</td>
              <td rowspan="2" style="border-top:none;">12-13</td>
              <td rowspan="2" style="border-top:none;">13-14</td>
              <td rowspan="2" style="border-top:none;">14-15</td>
              <td rowspan="2" style="border-top:none;">15-16</td>
              <td rowspan="2" style="border-top:none;">16-17</td>
              <td rowspan="2" style="border-top:none;">17-18</td>
              <td colspan="2" style="border-top:none;">8 JAM</td>
              <td colspan="2" style="border-top:none;">12 JAM</td>
            </tr>
            <tr>
              <td style="border-top:none;">JUMLAH</td>
              <td style="border-top:none;">%</td>
              <td style="border-top:none;">JUMLAH</td>
              <td style="border-top:none;">%</td>
            </tr>
            <tr>
              <td style="border-top:none;width:47px;"></td>
              <td style="border-top:none;width:47px;" v-for="i in 16" :key="i + '_headerEmptyLAMAPENYINARAN'">{{ i }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(raw, indexRaw) in item.RAW" :key="indexRaw + '_bodyLAMAPENYINARAN'">
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(indexRaw + 1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL4) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL5) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL6) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL7) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL8) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL9) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL10) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL11) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL12) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL13) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL14, 2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL15) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL16, 2) }}</td>
            </tr>

            <tr v-for="j in 31 - item.RAW.length" :key="j + '_bodyEmptyLAMAPENYINARAN'">
              <td>{{ j + item.RAW.length }}</td>
              <td v-for="k in 16" :key="k + '_rawEmptyLAMAPENYINARAN'"></td>
            </tr>

            <tr>
              <td style="border-top:none;text-align:left;">Jumlah</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL4) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL5) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL6) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL7) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL8) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL9) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL10) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL11) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL12) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL13) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL14, 2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL15) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL16, 2) }}</td>
            </tr>

            <tr>
              <td style="border-top:none;text-align:left;">Rata<sup>2</sup></td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL4) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL5) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL6) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL7) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL8) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL9) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL10) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL11) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL12) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL13) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL14, 2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL15) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL16, 2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportLAMAPENYINARAN",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values, DIGIT_DECIMAL = 1) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(DIGIT_DECIMAL));
      }
      return DataResult;
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.title-report-LAMAPENYINARAN {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
  color: #000;
}

.LAMAPENYINARAN td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-LAMAPENYINARAN td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-LAMAPENYINARAN > thead > tr > td {
  text-align: center;
  background: #e0e4e7;
  color: #000000;
  border-left: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
}

.table-report-LAMAPENYINARAN tbody > tr > td {
  padding: 5px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
  color: #000;
}

.table-report-LAMAPENYINARAN tbody > tr > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-LAMAPENYINARAN tr {
  border: none !important;
}

.table-report-LAMAPENYINARAN thead :not(.table-code) > td {
  padding: 5px;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
}

.table-report-LAMAPENYINARAN thead :not(.table-code) > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-LAMAPENYINARAN {
  font-size: 8.5px !important;
  margin-bottom: 20px !important;
  width: 100%;
}

.table-report-LAMAPENYINARAN tbody > tr {
  background-color: #ffffff;
}

.table-report-LAMAPENYINARAN tbody > tr:nth-child(even) {
  background-color: #ffffff;
}

/* .table-report-LAMAPENYINARAN td{
  max-width: 90px;
  height: 30px;
} */
</style>
