<template>
  <div>
    <div v-for="(itemPeriode, indexPeriode) in dataPDF.data" :key="indexPeriode">
      <div slot="pdf-content" v-for="(item, index) in itemPeriode" :key="index">
        <div style="margin:25px; font-size:12px;">
          <div style="width:1303px">
            <div class="title-report-FENOLOGI">
              {{ dataPDF.title }}
            </div>
          </div>

          <div style="width:1303px">
            <div style="float:left;min-with:200px;padding:5px;">Tempat Pemeriksaan</div>
            <div style="float:left;padding:5px;">:</div>
            <div style="float:left;padding:5px;;min-with:200px;margin-right:50px;">{{ dataPDF.station.length > 0 ? dataPDF.station[0].station_name : "" }}</div>
            <div style="float:left;min-with:200px;padding:5px;">Kabupaten</div>
            <div style="float:left;padding:5px;">:</div>
            <div style="float:left;padding:5px;min-width:100px;margin-right:50px;">{{ dataPDF.station[0].kabupaten_name }}</div>
            <div style="float:left;min-with:200px;padding:5px;">Propinsi</div>
            <div style="float:left;padding:5px;">:</div>
            <div style="float:left;padding:5px;min-width:100px;margin-right:50px;">{{ dataPDF.station[0].propinsi_name }}</div>
            <div style="float:left;min-with:200px;padding:5px;">Pada tgl</div>
            <div style="float:left;padding:5px;">:</div>
            <div style="float:left;padding:5px;min-width:100px;margin-right:50px;">{{ item.TGL }}</div>
            <div style="clear:both;"></div>

            <div style="float:left;min-with:200px;padding:5px;">Minggu seragam no</div>
            <div style="float:left;padding:5px;">:</div>
            <div style="float:left;padding:5px;min-width:100px;margin-right:50px;">{{ item.MINGGU_NO }}</div>
          </div>
          <div style="clear:both;"></div>
          <table class="table-report-FENOLOGI-CUSTOME" style="margin-top:10px;">
            <thead>
              <tr>
                <td colspan="2" style="border:solid 1px #000;">Hasil Bumi / Pohon yang diamati <br />(1)</td>
                <td style="border-right:solid 1px #000;border-top:solid 1px #000; border-bottom:solid 1px #000;">Peramatan Fenologi *) <br />(2)</td>
                <td style="border-right:solid 1px #000;border-top:solid 1px #000; border-bottom:solid 1px #000;">Keadaan Fenomena @ <br />(3)</td>
                <td style="border-right:solid 1px #000;border-top:solid 1px #000; border-bottom:solid 1px #000;">
                  keterangan Seperti penyerangan oleh hama atau penyakit, penguguran kembang, gandum, buah-buahan, dan cuaca yang berhubungan dengan itu.<br />(4)
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="border-left:solid 1px #000;width:100px;">
                  <div style="float:right;min-width:30px;text-align:left;">A</div>
                  &nbsp;
                </td>
                <td style="border-right:solid 1px #000;width:300.75px;">
                  <div style="border-bottom:solid 1px #000; float:left;">Tanaman Makanan Utama</div>
                  &nbsp;
                </td>
                <td style="border-right:solid 1px #000;width:300.75px;"></td>
                <td style="border-right:solid 1px #000;width:300.75px;"></td>
                <td style="border-right:solid 1px #000;width:300.75px;"></td>
              </tr>

              <tr v-for="(itemA, indexA) in item.RAW.A" :key="indexA">
                <td style="border-left:solid 1px #000;">
                  <div style="float:right;min-width:30px;text-align:left;"></div>
                  &nbsp;
                </td>
                <td style="border-right:solid 1px #000;" v-html="indexA"></td>
                <td style="border-right:solid 1px #000;font-weight: normal !important;">{{ itemA.COL2 }}</td>
                <td style="border-right:solid 1px #000;font-weight: normal !important;">{{ itemA.COL3 }}</td>
                <td style="border-right:solid 1px #000;font-weight: normal !important;">{{ itemA.COL4 }}</td>
              </tr>

              <!-- ================================= CONTENT B -->

              <tr>
                <td style="border-left:solid 1px #000;width:100px;">
                  <div style="float:right;min-width:30px;text-align:left;">B</div>
                  &nbsp;
                </td>
                <td style="border-right:solid 1px #000;width:300.75px;">
                  <div style="border-bottom:solid 1px #000; float:left;">Tanaman Buah-buahan yang menghasilkan hampir sepanjang tahun</div>
                  &nbsp;
                </td>
                <td style="border-right:solid 1px #000;width:300.75px;"></td>
                <td style="border-right:solid 1px #000;width:300.75px;"></td>
                <td style="border-right:solid 1px #000;width:300.75px;"></td>
              </tr>
              <tr v-for="(itemB, indexB) in item.RAW.B" :key="indexB">
                <td style="border-left:solid 1px #000;">
                  <div style="float:right;min-width:30px;text-align:left;"></div>
                  &nbsp;
                </td>
                <td style="border-right:solid 1px #000;" v-html="indexB"></td>
                <td style="border-right:solid 1px #000;font-weight: normal !important;">{{ itemB.COL2 }}</td>
                <td style="border-right:solid 1px #000;font-weight: normal !important;">{{ itemB.COL3 }}</td>
                <td style="border-right:solid 1px #000;font-weight: normal !important;">{{ itemB.COL4 }}</td>
              </tr>

              <!-- ================================= CONTENT C -->

              <tr>
                <td style="border-left:solid 1px #000;width:100px;">
                  <div style="float:right;min-width:30px;text-align:left;">C</div>
                  &nbsp;
                </td>
                <td style="border-right:solid 1px #000;width:300.75px;">
                  <div style="border-bottom:solid 1px #000;float:left;">Tanaman Buah-buahan yang menghasilkan menurut musim</div>
                  &nbsp;
                </td>
                <td style="border-right:solid 1px #000;width:300.75px;"></td>
                <td style="border-right:solid 1px #000;width:300.75px;"></td>
                <td style="border-right:solid 1px #000;width:300.75px;"></td>
              </tr>
              <tr v-for="(itemC, indexC) in item.RAW.C" :key="indexC">
                <td style="border-left:solid 1px #000;">
                  <div style="float:right;min-width:30px;text-align:left;"></div>
                  &nbsp;
                </td>
                <td style="border-right:solid 1px #000;" v-html="indexC"></td>
                <td style="border-right:solid 1px #000;font-weight: normal !important;">{{ itemC.COL2 }}</td>
                <td style="border-right:solid 1px #000;font-weight: normal !important;">{{ itemC.COL3 }}</td>
                <td style="border-right:solid 1px #000;font-weight: normal !important;">{{ itemC.COL4 }}</td>
              </tr>
            </tbody>
          </table>
          <div style="width:1303px; padding:10px; margin-top:10px;">
            * Penjelasan dibawah kolom ini tingkat dari perkembangan seperti kecambah / pertumbuhan daun-daun ( daun-daun baru ) bunga / kuncup bunga, penanaman gandum / buah-buahan, pemasakan, panen. <br />
            @ sangat baik, baik, sedang, buruk.
          </div>
        </div>
        <div v-if="itemPeriode.length - 1 != index" class="html2pdf__page-break" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportFENOLOGI",
  props: {
    dataPDF: Object,
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.table-report-FENOLOGI-CUSTOME {
  width: 1303px;
  font-size: 9px;
  border-bottom: solid 1px #000;
}

.table-report-FENOLOGI-CUSTOME > thead > tr > td {
  background: #e0e4e7;
  color: #000000;
  padding: 2px;
  text-align: center;
}

.table-report-FENOLOGI-CUSTOME tbody tr td {
  padding: 5px;
}

.title-report-FENOLOGI {
  padding-bottom: 5px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold !important;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  border-bottom: solid 1px #000;
}

.FENOLOGI td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-FENOLOGI td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-FENOLOGI > thead > tr > td {
  text-align: center;
}

.table-report-FENOLOGI tbody > tr > td {
  padding: 3px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
}

.table-report-FENOLOGI tbody > tr > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-FENOLOGI tr {
  border: none !important;
}

.table-report-FENOLOGI thead :not(.table-code) > td {
  padding: 3.5px;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
  border-top: solid 0.5px #000;
}

.table-report-FENOLOGI thead :not(.table-code) > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-FENOLOGI {
  font-size: 8.5px !important;
  font-weight: bold;
  margin-bottom: 20px !important;
}

.table-report-FENOLOGI td {
  max-width: 90px;
  height: 30px;
}
</style>
