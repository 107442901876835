<template>
  <div slot="pdf-content"  style="margin:10px;">
    <div class="title-report-METAR">{{dataPDF.title}}</div>
    <div class="title-report-label" style="margin-bottom:5px; height:30px">
      <div style="float:left;margin-left:15px;">WMO ID : {{dataPDF.wmo_id}}</div>
    </div>
    <table class="table-report-METAR" v-for="(item, itemIndex) in dataPDF.data" :key="itemIndex">
      <tbody>
        <tr style="width:max-content;" v-for="(decode, decodeIndex) in item.decoded_metar_speci" :key="decodeIndex">
          <td style="width:min-content;text-align:left;">{{decodeIndex == 0 ? (itemIndex + 1) + ")" : ""}}</td>
          <td style="width:max-content;text-align:right;">{{decode.label}}:</td>
          <td style="width:max-content;text-align:left;">{{decode.text}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>



<script>
export default {
    name: 'ReportMETAR',
    props: {
      dataPDF: Object
    },
    data: function () {
        return {
         
        };
    },

};
</script>

<style>
.title-report-METAR{
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: bold  !important;
}


.METAR td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align:bottom;
}

.table-report-METAR > thead > tr > td {
  text-align: center;
}

.table-report-METAR  tbody > tr > td {
  padding: 3px !important;
  margin: 0px;
  text-align: center;
}

.table-report-METAR thead :not(.table-code) > td{
  padding: 3.5px;
}

.table-report-METAR {
  font-size: 8.5px  !important;
  font-weight: bold;
  margin-bottom: 20px !important;
}

.table-report-METAR td{
  max-width: auto;
  height: auto;
}

</style>
