const BASE_URL = process.env.VUE_APP_BASE_URL// "http://116.90.165.45:7080/db/bmkgsatu/";

import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper"
export default {  

  getReportBeritaClimat(wmoid, periode, waktu) {
    // var url = Helper.getBaseUrl()+"@report_berita_climat?wmo_id="+wmoid+"&periode="+periode
    var url = Helper.getBaseUrl()+"@report_berita_climat?wmo_id="+wmoid+"&periode="+periode+"&yearback="+waktu
    return axios.get(url, Helper.getConfig());
  },

  getReportWxrevHarian(stationid, periode) {
    var url = Helper.getBaseUrl()+"@report_wxrev_harian?station_id="+stationid+"&periode="+periode
    return axios.get(url, Helper.getConfig());
  },
};
