import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_WXREV = false;
export default {
    async dummyDataWXREV(){
        return  new Promise((resolve) => {
            var datas = [];
            for (let i = 1; i <= 30; i+=2) {
                datas.push({
                    "TGL": `${i}`,
                    "COL_1": "06014",
                    "COL_2": "96249",
                    "COL_3": "03025",
                    "COL_4": "01009",
                    "COL_5": "29781",
                    "COL_6": "00046",
                    "COL_7": "33420",
                    "COL_8": "-",
                    "COL_9": "02 juni 2020",
                    "COL_10": "00.00 Z",
                    "COL_11": "VPN",
                    "COL_12": "VPN = Virtual Private Network",
                });
                datas.push({
                    "TGL": `${i+1}`,
                    "COL_1": "06014",
                    "COL_2": "96249",
                    "COL_3": "03025",
                    "COL_4": "01009",
                    "COL_5": "29781",
                    "COL_6": "00046",
                    "COL_7": "33000",
                    "COL_8": "21310",
                    "COL_9": "02 juni 2020",
                    "COL_10": "00.00 Z",
                    "COL_11": "VPN",
                    "COL_12": "VPN = Virtual Private Network",
                });
            }
            resolve(datas);
        });
    },

    async storeDataWXREV(STATION_ID,PERIODE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_WXREV(STATION_ID,PERIODE);
            axios.get(url, Helper.getConfig())
                .then(function (dataResponse) {
                    resolve(dataResponse);
                })
                .catch(function (error) {
                    resolve({success : false, message : error});
                });
        });
    },

    async callDataWXREV(STATION_ID,PERIODE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_WXREV == true){
                var datas = await this.dummyDataWXREV();
                console.log(datas);
                resolve(datas);
            }else{
                var thisData = await this.storeDataWXREV(STATION_ID,PERIODE);
                var datas = thisData.data[PERIODE];
                console.log(datas);
                resolve(datas);
            }
        });
    }
}
