import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_FENOLOGI = false;
export default {
    async dummyDataFENOLOGI(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01": [
                    {
                        "MINGGU_NO"     : "", // MINGGU KEBERATA
                        "TGL"           : "", // START DATE - END DATE
                        "RAW" : {
                            "A" :{
                                "A1" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "A2" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "A3" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "A4" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "A5" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                            },
                            "B" :{
                                "B1" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "B2" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "B3" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "B4" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "B5" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                            },
                            "C" :{
                                "C1" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "C2" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "C3" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "C4" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "C5" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "C6" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                                "C7" : {
                                    "COL2"   : 1, 
                                    "COL3"   : 2,
                                    "COL4"   : 3,
                                },
                            }
                        }
                    },
                ]
            }
            resolve(datas);
        });
    },

    async storeDataFENOLOGI(STATION_ID,PERIODE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_FENOLOGI(STATION_ID,PERIODE);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataFENOLOGI(STATION_ID,PERIODE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_FENOLOGI == true){
                var datas = await this.dummyDataFENOLOGI();
                resolve(datas);
            }else{
                var thisData = await this.storeDataFENOLOGI(STATION_ID,PERIODE);
                var datas = thisData.data;
                resolve(datas);
            }
        });
    }
}