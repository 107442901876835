<template>
  <div style="margin:25px;">
    <!-- {{dataPDF}} -->
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <table class="title-report-OPPENGUAPAN" style="margin-bottom:20px;font-size:12px;color:#000;">
        <tbody>
          <tr>
            <td rowspan="6" style="vertical-align:top;">PEMERIKSAAN PENGUAPAN PANCI TERBUKA</td>
            <td>TAHUN</td>
            <td>:</td>
            <td>{{ dataPDF.tahun }}</td>
            <td>POS PENGAMATAN NO :</td>
          </tr>
          <tr>
            <td>BULAN</td>
            <td>:</td>
            <td>{{ dataPDF.bulan }}</td>
            <td></td>
          </tr>
          <tr>
            <td>TEMPAT PEMERIKSAAN</td>
            <td>:</td>
            <td>
              <span style="font-size:12px;" v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_name }}</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>KECAMATAN</td>
            <td>:</td>
            <td>
              <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].kecamatan_name }}</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>KABUPATEN</td>
            <td>:</td>
            <td>
              <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].kabupaten_name }}</span>
            </td>
            <td>Pemeriksaan penguapan tiap pagi jam 07:00 WS</td>
          </tr>
          <tr>
            <td>PROPINSI</td>
            <td>:</td>
            <td>
              <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].propinsi_name }}</span>
            </td>
            <td>Jika tidak ada hujan kolom diisi</td>
          </tr>
        </tbody>
      </table>

      <table class="table-report-OPPENGUAPAN">
        <thead>
          <tr>
            <td rowspan="2">Tanggal Pengukuran</td>
            <td>Beda</td>
            <td>Hujan</td>
            <td colspan="2">Penguapan</td>

            <td rowspan="2">Tanggal Pengukuran</td>
            <td>Beda</td>
            <td>Hujan</td>
            <td colspan="2">Penguapan</td>

            <td rowspan="2">Tanggal Pengukuran</td>
            <td>Kecepatan Angin</td>
            <td>Suhu Air</td>

            <td rowspan="2">Tanggal Pengukuran</td>
            <td>Kecepatan Angin</td>
            <td>Suhu Air</td>
          </tr>
          <tr>
            <td style="border-top:none;">Tinggi H (mm)</td>
            <td style="border-top:none;">P (mm)</td>
            <td style="border-top:none;">E = P + H (mm)</td>
            <td style="border-top:none;">Jam</td>

            <td style="border-top:none;">Tinggi H (mm)</td>
            <td style="border-top:none;">P (mm)</td>
            <td style="border-top:none;">E = P + H (mm)</td>
            <td style="border-top:none;">Jam</td>

            <td style="border-top:none;">(Km)</td>
            <td style="border-top:none;">(<sup>o</sup>C)</td>
            <td style="border-top:none;">(Km)</td>
            <td style="border-top:none;">(<sup>o</sup>C)</td>
          </tr>
          <tr>
            <td v-for="j in 16" :key="j + '_headerEmptyOPPENGUAPAN'" style="border-top:none;width:81px !important;">{{ j }}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="j in 15" :key="j + '_bodyOPPENGUAPAN'">
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][j - 1] == undefined ? j : item["RAW_1"]["RAW"][j - 1]["TGL"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][j - 1] == undefined ? "" : item["RAW_1"]["RAW"][j - 1]["COL2"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][j - 1] == undefined ? "" : item["RAW_1"]["RAW"][j - 1]["COL3"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][j - 1] == undefined ? "" : item["RAW_1"]["RAW"][j - 1]["COL4"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ item["RAW_1"]["RAW"][j - 1] == undefined ? "" : item["RAW_1"]["RAW"][j - 1]["COL5"] }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][j + 14] == undefined ? j + 14 : item["RAW_1"]["RAW"][j + 14]["TGL"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][j + 14] == undefined ? "" : item["RAW_1"]["RAW"][j + 14]["COL2"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][j + 14] == undefined ? "" : item["RAW_1"]["RAW"][j + 14]["COL3"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][j + 14] == undefined ? "" : item["RAW_1"]["RAW"][j + 14]["COL4"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ item["RAW_1"]["RAW"][j + 15] == undefined ? "" : item["RAW_1"]["RAW"][j + 14]["COL5"] }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_2"]["RAW"][j - 1] == undefined ? j : item["RAW_1"]["RAW"][j - 1]["TGL"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_2"]["RAW"][j - 1] == undefined ? "" : item["RAW_1"]["RAW"][j - 1]["COL12"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_2"]["RAW"][j - 1] == undefined ? "" : item["RAW_1"]["RAW"][j - 1]["COL13"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_2"]["RAW"][j + 14] == undefined ? j + 14 : item["RAW_1"]["RAW"][j + 14]["TGL"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_2"]["RAW"][j + 14] == undefined ? "" : item["RAW_1"]["RAW"][j + 14]["COL12"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_2"]["RAW"][j + 14] == undefined ? "" : item["RAW_1"]["RAW"][j + 14]["COL13"]) }}</td>
          </tr>
          <tr>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][30] == undefined ? 31 : item["RAW_1"]["RAW"][30]["TGL"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][30] == undefined ? "" : item["RAW_1"]["RAW"][30]["COL2"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][30] == undefined ? "" : item["RAW_1"]["RAW"][30]["COL3"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][30] == undefined ? "" : item["RAW_1"]["RAW"][30]["COL4"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["RAW"][30] == undefined ? "" : item["RAW_1"]["RAW"][30]["COL5"]) }}</td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_2"]["RAW"][30] == undefined ? 31 : item["RAW_1"]["RAW"][30]["TGL"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_2"]["RAW"][30] == undefined ? "" : item["RAW_1"]["RAW"][30]["COL12"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_2"]["RAW"][30] == undefined ? "" : item["RAW_1"]["RAW"][30]["COL13"]) }}</td>
          </tr>
          <tr>
            <td style="border-top:none;">Jumlah</td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["JUMLAH"][1] == undefined ? "" : item["RAW_1"]["JUMLAH"][1]["COL3"]) }}</td>
            <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item["RAW_1"]["JUMLAH"][1] == undefined ? "" : item["RAW_1"]["JUMLAH"][1]["COL4"]) }}</td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;text-align:right;">
              {{ fixedValueNumber(item["RAW_1"]["JUMLAH"][2] == undefined ? "" : item["RAW_1"]["JUMLAH"][3] != undefined ? item["RAW_1"]["JUMLAH"][2]["COL3"] + item["RAW_1"]["JUMLAH"][3]["COL4"] : item["RAW_1"]["JUMLAH"][2]["COL3"]) }}
            </td>
            <td style="border-top:none;text-align:right;">
              {{ fixedValueNumber(item["RAW_1"]["JUMLAH"][2] == undefined ? "" : item["RAW_1"]["JUMLAH"][3] != undefined ? item["RAW_1"]["JUMLAH"][2]["COL4"] + item["RAW_1"]["JUMLAH"][3]["COL4"] : item["RAW_1"]["JUMLAH"][2]["COL4"]) }}
            </td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
            <td style="border-top:none;"></td>
          </tr>
        </tbody>
      </table>
      <table style=" width: 1303px;font-size:12px;font-weight:bold;">
        <tr>
          <td style="width:30%">
            <table style="width:70%">
              <tr>
                <td colspan="3">Jumlah</td>
              </tr>
              <tr>
                <td>Hujan Sebulan</td>
                <td>:</td>
                <td>{{ item["HUJAN_SEBULAN"] }} mm</td>
              </tr>
              <tr>
                <td>Penguapan Sebulan</td>
                <td>:</td>
                <td>{{ item["PENGUAPAN_SEBULAN"] }} mm</td>
              </tr>
              <tr>
                <td>Banyaknya Hujan Sebulan</td>
                <td>:</td>
                <td>{{ item["BANYAK_HUJAN"] }} hari</td>
              </tr>
            </table>
          </td>
          <td style="width:35%">
            <div style="float:left;">H =</div>
            <div style="float:left;width:300px;margin-left:10px;">
              Beda pembacaan air bejana 1 hari sebelum dengan waktu pengamatan
            </div>
            <div style="clear:both;margin-bottom:20px;"></div>
            <div style="float:left;">H =</div>
            <div style="float:left; width:300px;margin-left:10px;">
              Negatif jika pembacaan lebih besar dari pembacaan 1 hari sebelumnya
            </div>
          </td>
          <td style="width:35%">
            <div style="float:left;">H =</div>
            <div style="float:left;width:300px;margin-left:10px;">
              Positif jika pembacaan lebih kecil dari pembacaan 1 hari sebelumnya
            </div>
            <div style="clear:both;margin-bottom:20px;"></div>
            <div style="float:left;">P =</div>
            <div style="float:left;width:300px;margin-left:10px;">
              Banyak hujan selama 1 hari dalam mm
            </div>
          </td>
        </tr>
      </table>

      <div class="html2pdf__page-break" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportOPPENGUAPAN",
  props: {
    dataPDF: Object,
  },
  data: function() {
    return {
      BANYAK_HUJAN: {},
      PENGUAPAN: {},
      HUJAN: {},
    };
  },
  methods: {
    onCheck() {
      this.BANYAK_HUJAN = {};
      this.PENGUAPAN = {};
      this.HUJAN = {};

      for (var i in this.dataPDF.data) {
        if (this.BANYAK_HUJAN[i] == undefined) {
          this.BANYAK_HUJAN[i] = 0;
          this.PENGUAPAN[i] = 0;
          this.HUJAN[i] = 0;
        }
        for (var j in this.dataPDF.data[i]["RAW_1"]["RAW"]) {
          if (Number(this.dataPDF.data[i]["RAW_1"]["RAW"][j]["COL3"]) > 0) {
            this.BANYAK_HUJAN[i] += 1;
          }
          this.HUJAN[i] += Number(this.dataPDF.data[i]["RAW_1"]["RAW"][j]["COL3"]);
          this.PENGUAPAN[i] += Number(this.dataPDF.data[i]["RAW_1"]["RAW"][j]["COL4"]);
        }
      }
      console.log(this.HUJAN, this.PENGUAPAN);
    },
    fixedValueNumber(values) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult;
    },
  },
  mounted: function() {
    this.onCheck();
  },
  ready: function() {
    this.onCheck();
  },
};
</script>

<style>
/* * {
  font-weight: bold !important;
} */

.title-report-OPPENGUAPAN {
  width: 1303px;
  padding: 10px;
  text-align: left;
  font-weight: bold !important;
}

.OPPENGUAPAN td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-OPPENGUAPAN td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-OPPENGUAPAN > thead > tr > td {
  text-align: center;
  background: #e0e4e7;
  color: #000000;
  border-left: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
}

.table-report-OPPENGUAPAN tbody > tr > td {
  padding: 3px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
}

.table-report-OPPENGUAPAN tbody > tr > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-OPPENGUAPAN tr {
  border: none !important;
}

.table-report-OPPENGUAPAN thead :not(.table-code) > td {
  padding: 3.5px;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
}

.table-report-OPPENGUAPAN thead :not(.table-code) > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-OPPENGUAPAN tbody > tr {
  background-color: #ffffff;
}

.table-report-OPPENGUAPAN tbody > tr:nth-child(even) {
  background-color: #ffffff;
}

.table-report-OPPENGUAPAN {
  font-size: 8.5px !important;
  margin-bottom: 20px !important;
  width: 1303px;
}

/* .table-report-OPPENGUAPAN td{
  max-width: 90px;
} */
</style>
