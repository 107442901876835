import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_AGM1A = false;
export default {
    async dummyDataAGM1A(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01": {
                    "HAL_1" : {
                        "06":{
                            "RAW" : [
                                {
                                "COL3":"1", 
                                "COL4":"", 
                                "COL5": "", 
                                "COL6":"",  
                                "COL7":"", 
                                "COL8":"", 
                                "COL9":"", 
                                "COL10":"",  
                                "COL11":"",  
                                "COL12":"", 
                                "COL13":"", 
                                "COL14":"",
                                "COL15":"",
                                "COL16":"",  
                                "COL17":"",  
                                "COL18":"", 
                                "COL19":"", 
                                "COL20":"", 
                                "COL21":"",  
                                "COL22":"", 
                                "COL23":"", 
                                "COL24":"",
                                "COL25":"",  
                                "COL26":"" ,
                                "COL27":"", 
                                "COL28":"", 
                                "COL29":"", 
                                "COL30":"",
                                },
                                {
                                "COL3":"2", 
                                "COL4":"", 
                                "COL5": "", 
                                "COL6":"",  
                                "COL7":"", 
                                "COL8":"", 
                                "COL9":"", 
                                "COL10":"",  
                                "COL11":"",  
                                "COL12":"", 
                                "COL13":"", 
                                "COL14":"",
                                "COL15":"",
                                "COL16":"",  
                                "COL17":"",  
                                "COL18":"", 
                                "COL19":"", 
                                "COL20":"", 
                                "COL21":"",  
                                "COL22":"", 
                                "COL23":"", 
                                "COL24":"",
                                "COL25":"",  
                                "COL26":"" ,
                                "COL27":"", 
                                "COL28":"", 
                                "COL29":"", 
                                "COL30":"",
                                },
                            ],
                            "JUMLAH" : {},
                            "RATA"   : {}
                        }
                    },
                    "HAL_2":{
                        "06":{
                            "RAW" : [
                                {
                                "COL3":"1", 
                                "COL4":"", 
                                "COL5": "", 
                                "COL6":"",  
                                "COL7":"", 
                                "COL8":"", 
                                "COL9":"", 
                                "COL10":"",  
                                "COL11":"",  
                                "COL12":"", 
                                "COL13":"", 
                                "COL14":"",
                                "COL15":"",
                                },
                                {
                                "COL3":"2", 
                                "COL4":"", 
                                "COL5": "", 
                                "COL6":"",  
                                "COL7":"", 
                                "COL8":"", 
                                "COL9":"", 
                                "COL10":"",  
                                "COL11":"",  
                                "COL12":"", 
                                "COL13":"", 
                                "COL14":"",
                                "COL15":"",
                                },
                            ],
                            "JUMLAH" : { },
                            "RATA"   : { }
                        }
                    }
                }
            }
            resolve(datas);
        });
    },

    async storeDataAGM1A(STATION_ID,PERIODE,WAKTU){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_AGM1A(STATION_ID,PERIODE,WAKTU);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataAGM1A(STATION_ID,PERIODE,WAKTU){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_AGM1A == true){
                var datas = await this.dummyDataAGM1A();
                resolve(datas);
            }else{
                var thisData = await this.storeDataAGM1A(STATION_ID,PERIODE,WAKTU);
                var datas = thisData.data; 
                var dataBuild = {};
                for(var i in datas){
                    dataBuild[i] = {"HAL_1": {}, "HAL_2": {} };
                    for(var iHal1 in datas[i][0]["HAL_1"]){
                        dataBuild[i]["HAL_1"][iHal1] = {"RAW" : [], "JUMLAH" : {}, "RATA":{} };
                        dataBuild[i]["HAL_1"][iHal1]["RAW"] = datas[i][0]["HAL_1"][iHal1]["RAW"].sort(function(a, b) {
                            return new Date(a.COL3.toString()).getTime() - new Date(b.COL3).getTime();
                        });
                        var shortData = dataBuild[i]["HAL_1"][iHal1]["RAW"];

                        var Total = {};
                        var Count = {};
                        var Rata  = {};

                        
                        for(var k in shortData){
                            var dataShort = {};
                            for(var m in shortData[k]){
                                dataShort[m] = shortData[k][m];
                                if(m == "COL3" ){
                                    var thisDate = new Date(shortData[k][m]);
                                    dataShort[m] = thisDate.getDate();
                                    continue;
                                }
                                dataShort[m] = shortData[k][m] == null || shortData[k][m] == "null" ? "" : (isNaN(shortData[k][m]) ? shortData[k][m] : parseFloat(Number(shortData[k][m]).toFixed(1)));

                                if(Total[m] == undefined ){
                                    Total[m] = 0;
                                    Count[m] = 0;
                                }
                                Total[m] += shortData[k][m] == null || isNaN(shortData[k][m]) ? 0 : parseFloat(Number(Number(shortData[k][m]).toFixed(1)));
                                Count[m]++;
                                Rata[m] = Number(Total[m] / Count[m]).toFixed(1);
                            }
                            dataBuild[i]["HAL_1"][iHal1]["RAW"][k] = dataShort;
                        }
                        for(var iT in Total){
                            Total[iT] = parseFloat(Total[iT]);
                        }
                        for(var iR in Rata){
                            Rata[iR] = parseFloat(Rata[iR]);
                        }
                        dataBuild[i]["HAL_1"][iHal1]["JUMLAH"] =Total;
                        dataBuild[i]["HAL_1"][iHal1]["RATA"] = Rata;
                    }

                    for(var iHal2 in datas[i][0]["HAL_2"]){
                        dataBuild[i]["HAL_2"][iHal2] = {"RAW" : [], "JUMLAH" : {}, "RATA":{} };
                        dataBuild[i]["HAL_2"][iHal2]["RAW"] = datas[i][0]["HAL_2"][iHal2]["RAW"].sort(function(a, b) {
                            return new Date(a.COL3.toString()).getTime() - new Date(b.COL3).getTime();
                        });
                        var shortData = dataBuild[i]["HAL_2"][iHal2]["RAW"];

                        var Total = {};
                        var Count = {};
                        var Rata  = {};
                        for(var k in shortData){
                            var dataShort = {};
                            for(var m in shortData[k]){
                                dataShort[m] = shortData[k][m];
                                if(m == "COL3" ){
                                    var thisDate = new Date(shortData[k][m]);
                                    dataShort[m] = thisDate.getDate();
                                    continue;
                                }

                                dataShort[m] = shortData[k][m] == null || shortData[k][m] == "null" ? "" : (isNaN(shortData[k][m]) ? shortData[k][m] : parseFloat(Number(shortData[k][m]).toFixed(1)));

                                if(Total[m] == undefined ){
                                    Total[m] = 0;
                                    Count[m] = 0;
                                }
                                Total[m] += shortData[k][m] == null || isNaN(shortData[k][m] ) ? 0 : parseFloat(Number(Number(shortData[k][m]).toFixed(1)));
                                Count[m]++;
                                Rata[m] = Number(Total[m] / Count[m]).toFixed(1);
                            }
                            dataBuild[i]["HAL_2"][iHal2]["RAW"][k] = dataShort;
                        }
                        for(var iT in Total){
                            Total[iT] = parseFloat(Total[iT]);
                        }
                        for(var iR in Rata){
                            Rata[iR] = parseFloat(Rata[iR]);
                        }
                        dataBuild[i]["HAL_2"][iHal2]["JUMLAH"] =Total;
                        dataBuild[i]["HAL_2"][iHal2]["RATA"] = Rata;
                    }
                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}