<template>
  <div style="margin-top:10px;margin-left:10px;margin-right:10px;">
    <div slot="pdf-content" v-for="(itemPeriode, periode) in dataPDF.data" :key="periode">
      <div v-for="(item, indexItems) in itemPeriode" :key="indexItems">
        <div style="text-align:right;width: 1303px;">
          Form A
        </div>
        <div class="title-report-HUJANHELMAN" style="text-transform: uppercase;">
          BADAN METEOROLOGI, KLIMATOLOGI, DAN GEOFISIKA
        </div>

        <table class="title-report-HUJANHELMAN" style="margin-bottom:10px;text-align:left;">
          <tr>
            <td style="text-align:center;" colspan="7">PENAKAR HUJAN OTOMATIS</td>
          </tr>
          <tr>
            <td>Nama Stasiun</td>
            <td>:</td>
            <td style="width:400px;">{{ dataPDF.station.length > 0 ? dataPDF.station[0].station_name : "" }}</td>
            <td style="width:25%;"></td>
            <td>Laporan Bulan</td>
            <td>:</td>
            <td>{{ dataPDF.bulan }}</td>
          </tr>
          <tr>
            <td>Kabupaten</td>
            <td>:</td>
            <td>
              <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].kabupaten_name }}</span>
            </td>
            <td></td>
            <td>Tahun</td>
            <td>:</td>
            <td>{{ dataPDF.tahun }}</td>
          </tr>
          <tr>
            <td>Ketinggian</td>
            <td>:</td>
            <td>
              <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_elevation }} m</span>
            </td>
            <td></td>
            <td>Nama Pengamat</td>
            <td>:</td>
            <td>{{ item.FORM_A.OBSERVER_NAME }}</td>
          </tr>
          <tr>
            <td>No. Stasiun Hujan Obs</td>
            <td>:</td>
            <td>
              <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_wmo_id }}</span>
            </td>
            <td></td>
            <td>Type Penakar</td>
            <td>:</td>
            <td>Hellman</td>
          </tr>
        </table>

        <table class="table-report-HUJANHELMAN">
          <thead>
            <tr>
              <td colspan="10">Jumlah pada masing-masing periode waktu (dalam milimeter)</td>
              <td rowspan="3">Tgl</td>
              <td colspan="24">Jumlah hujan tiap jam (milimeter)</td>
              <td rowspan="3">Jumlah 24 jam</td>
            </tr>
            <tr>
              <td style="border-top:none;">5</td>
              <td style="border-top:none;">10</td>
              <td style="border-top:none;">15</td>
              <td style="border-top:none;">30</td>
              <td style="border-top:none;">45</td>
              <td style="border-top:none;">60</td>
              <td style="border-top:none;">120</td>
              <td style="border-top:none;">3</td>
              <td style="border-top:none;">6</td>
              <td style="border-top:none;">12</td>
              <td style="border-top:none;" rowspan="2">07-08</td>
              <td style="border-top:none;" rowspan="2">08-09</td>
              <td style="border-top:none;" rowspan="2">09-10</td>
              <td style="border-top:none;" rowspan="2">10-11</td>
              <td style="border-top:none;" rowspan="2">11-12</td>
              <td style="border-top:none;" rowspan="2">12-13</td>
              <td style="border-top:none;" rowspan="2">13-14</td>
              <td style="border-top:none;" rowspan="2">14-15</td>
              <td style="border-top:none;" rowspan="2">15-16</td>
              <td style="border-top:none;" rowspan="2">16-17</td>
              <td style="border-top:none;" rowspan="2">17-18</td>
              <td style="border-top:none;" rowspan="2">18-19</td>
              <td style="border-top:none;" rowspan="2">19-20</td>
              <td style="border-top:none;" rowspan="2">20-21</td>
              <td style="border-top:none;" rowspan="2">21-22</td>
              <td style="border-top:none;" rowspan="2">22-23</td>
              <td style="border-top:none;" rowspan="2">23-24</td>
              <td style="border-top:none;" rowspan="2">00-01</td>
              <td style="border-top:none;" rowspan="2">01-02</td>
              <td style="border-top:none;" rowspan="2">02-03</td>
              <td style="border-top:none;" rowspan="2">03-04</td>
              <td style="border-top:none;" rowspan="2">04-05</td>
              <td style="border-top:none;" rowspan="2">05-06</td>
              <td style="border-top:none;border-right:none;" rowspan="2">06-07</td>
            </tr>
            <tr>
              <td style="border-top:none;">menit</td>
              <td style="border-top:none;">menit</td>
              <td style="border-top:none;">menit</td>
              <td style="border-top:none;">menit</td>
              <td style="border-top:none;">menit</td>
              <td style="border-top:none;">menit</td>
              <td style="border-top:none;">menit</td>
              <td style="border-top:none;">jam</td>
              <td style="border-top:none;">jam</td>
              <td style="border-top:none;border-right:none;">jam</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(itemFormA, indexFormARaw) in item.FORM_A.RAW" :key="indexFormARaw">
              <td>{{ fixedValueNumber(itemFormA.PERIODE5M) }}</td>
              <td>{{ fixedValueNumber(itemFormA.PERIODE10M) }}</td>
              <td>{{ fixedValueNumber(itemFormA.PERIODE15M) }}</td>
              <td>{{ fixedValueNumber(itemFormA.PERIODE30M) }}</td>
              <td>{{ fixedValueNumber(itemFormA.PERIODE45M) }}</td>
              <td>{{ fixedValueNumber(itemFormA.PERIODE80M) }}</td>
              <td>{{ fixedValueNumber(itemFormA.PERIODE120M) }}</td>
              <td>{{ fixedValueNumber(itemFormA.PERIODE3J) }}</td>
              <td>{{ fixedValueNumber(itemFormA.PERIODE6J) }}</td>
              <td>{{ fixedValueNumber(itemFormA.PERIODE12J) }}</td>
              <td>{{ itemFormA.TGL }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL01) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL02) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL03) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL04) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL05) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL06) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL07) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL08) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL09) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL10) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL11) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL12) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL13) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL14) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL15) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL16) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL17) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL18) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL19) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL20) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL21) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL22) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL23) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL24) }}</td>
              <td>{{ fixedValueNumber(itemFormA.COL25) }}</td>
            </tr>
            <tr>
              <td colspan="10">Tanggal dan Intensitas Maksimum</td>
              <td></td>
              <td colspan="25"></td>
            </tr>
            <tr>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.DATE.PERIODE5M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.DATE.PERIODE10M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.DATE.PERIODE15M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.DATE.PERIODE30M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.DATE.PERIODE45M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.DATE.PERIODE80M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.DATE.PERIODE120M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.DATE.PERIODE3J) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.DATE.PERIODE6J) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.DATE.PERIODE12J) }}</td>
              <td>Jml</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL01) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL02) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL03) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL04) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL05) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL06) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL07) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL08) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL09) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL10) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL11) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL12) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL13) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL14) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL15) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL16) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL17) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL18) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL19) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL20) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL21) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL22) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL23) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL24) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.JUMLAH.COL25) }}</td>
            </tr>
            <tr>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.VALUES.PERIODE5M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.VALUES.PERIODE10M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.VALUES.PERIODE15M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.VALUES.PERIODE30M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.VALUES.PERIODE45M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.VALUES.PERIODE80M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.VALUES.PERIODE120M) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.VALUES.PERIODE3J) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.VALUES.PERIODE6J) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.INTENCE_MAX.VALUES.PERIODE12J) }}</td>
              <td>Max</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL01) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL02) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL03) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL04) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL05) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL06) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL07) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL08) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL09) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL10) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL11) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL12) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL13) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL14) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL15) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL16) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL17) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL18) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL19) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL20) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL21) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL22) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL23) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL24) }}</td>
              <td>{{ fixedValueNumber(item.FORM_A.MAX_HUJAN.COL25) }}</td>
            </tr>
            <tr>
              <td colspan="36" style="text-align:left;">
                <div>Jam Pemeriksaan Hujan Model Obs (waktu setempat)</div>
                <div>Jam : 07.00 WS</div>
              </td>
            </tr>
          </tbody>
        </table>

        <div style="text-align:right;width: 1303px;">
          Form B
        </div>
        <div class="title-report-HUJANHELMAN" style="text-transform: uppercase;">
          BADAN METEOROLOGI, KLIMATOLOGI, DAN GEOFISIKA
        </div>

        <table class="title-report-HUJANHELMAN" style="margin-bottom:10px;text-align:left;">
          <tr>
            <td style="text-align:center;" colspan="7">PENAKAR HUJAN OTOMATIS</td>
          </tr>
          <tr>
            <td>Nama Stasiun</td>
            <td>:</td>
            <td style="width:400px;">{{ dataPDF.station.length > 0 ? dataPDF.station[0].station_name : "" }}</td>
            <td style="width:25%;"></td>
            <td>Laporan Bulan</td>
            <td>:</td>
            <td>{{ dataPDF.bulan }}</td>
          </tr>
          <tr>
            <td>Kabupaten</td>
            <td>:</td>
            <td>{{ dataPDF.station[0].kabupaten_name }}</td>
            <td></td>
            <td>Tahun</td>
            <td>:</td>
            <td>{{ dataPDF.tahun }}</td>
          </tr>
          <tr>
            <td>Ketinggian</td>
            <td>:</td>
            <td>{{ dataPDF.station[0].station_elevation }}</td>
            <td></td>
            <td>Nama Pengamat</td>
            <td>:</td>
            <td>{{ item.FORM_B.OBSERVER_NAME }}</td>
          </tr>
          <tr>
            <td>No. Stasiun Hujan Obs</td>
            <td>:</td>
            <td></td>
            <td></td>
            <td>Type Penakar</td>
            <td>:</td>
            <td>Hellman</td>
          </tr>
        </table>

        <table class="table-report-HUJANHELMAN">
          <thead>
            <tr>
              <td rowspan="2">Tgl</td>
              <td colspan="24">Tgl</td>
              <td rowspan="2">Jumlah 24 jam</td>
            </tr>
            <tr>
              <td style="border-top:none;">07-08</td>
              <td style="border-top:none;">08-09</td>
              <td style="border-top:none;">09-10</td>
              <td style="border-top:none;">10-11</td>
              <td style="border-top:none;">11-12</td>
              <td style="border-top:none;">12-13</td>
              <td style="border-top:none;">13-14</td>
              <td style="border-top:none;">14-15</td>
              <td style="border-top:none;">15-16</td>
              <td style="border-top:none;">16-17</td>
              <td style="border-top:none;">17-18</td>
              <td style="border-top:none;">18-19</td>
              <td style="border-top:none;">19-20</td>
              <td style="border-top:none;">20-21</td>
              <td style="border-top:none;">21-22</td>
              <td style="border-top:none;">22-23</td>
              <td style="border-top:none;">23-24</td>
              <td style="border-top:none;">00-01</td>
              <td style="border-top:none;">01-02</td>
              <td style="border-top:none;">02-03</td>
              <td style="border-top:none;">03-04</td>
              <td style="border-top:none;">04-05</td>
              <td style="border-top:none;">05-06</td>
              <td style="border-top:none;border-right:none;">06-07</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(itemFormBRaw1, indexFormBRaw1) in item.FORM_B.RAW_HAL1.RAW" :key="indexFormBRaw1">
              <td>{{ itemFormBRaw1.TGL }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL01) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL02) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL03) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL04) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL05) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL06) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL07) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL08) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL09) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL10) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL11) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL12) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL13) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL14) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL15) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL16) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL17) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL18) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL19) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL20) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL21) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL22) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL23) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL24) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw1.COL25) }}</td>
            </tr>
            <tr>
              <td>Jml</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL01) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL02) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL03) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL04) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL05) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL06) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL07) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL08) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL09) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL10) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL11) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL12) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL13) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL14) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL15) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL16) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL17) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL18) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL19) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL20) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL21) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL22) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL23) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL24) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.JUMLAH.COL25) }}</td>
            </tr>
            <tr>
              <td>Max</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL01) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL02) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL03) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL04) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL05) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL06) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL07) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL08) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL09) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL10) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL11) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL12) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL13) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL14) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL15) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL16) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL17) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL18) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL19) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL20) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL21) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL22) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL23) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL24) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL1.MAX_HUJAN.COL25) }}</td>
            </tr>
          </tbody>
        </table>

        <div class="html2pdf__page-break" />

        <table class="table-report-HUJANHELMAN" style="margin-top:20px;">
          <thead>
            <tr>
              <td rowspan="2">Tgl</td>
              <td colspan="3">Faktor Koreksi Selama 24 Jam</td>
              <td colspan="8">Jumlah pada masing-masing periode waktu kali Faktor Koreksi (FK) dalam milimeter (mm)</td>
            </tr>
            <tr>
              <td style="border-top:none;">Jumlah Pengukuran Obs</td>
              <td style="border-top:none;">Jumlah Pembacaan Pias</td>
              <td style="border-top:none;">Pengukuran Obs Pembacaan Pias</td>
              <td style="border-top:none;">5 menit</td>
              <td style="border-top:none;">10 menit</td>
              <td style="border-top:none;">15 menit</td>
              <td style="border-top:none;">30 menit</td>
              <td style="border-top:none;">60 menit</td>
              <td style="border-top:none;">120 menit</td>
              <td style="border-top:none;">6 jam</td>
              <td style="border-top:none;">12 jam</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(itemFormBRaw2, indexFormBRaw2) in item.FORM_B.RAW_HAL2.RAW" :key="indexFormBRaw2">
              <td>{{ itemFormBRaw2.TGL }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw2.COL1) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw2.COL2) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw2.COL3) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw2.COL4) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw2.COL5) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw2.COL6) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw2.COL7) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw2.COL8) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw2.COL9) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw2.COL10) }}</td>
              <td>{{ fixedValueNumber(itemFormBRaw2.COL11) }}</td>
            </tr>
            <tr class="rowpan">
              <td rowspan="2">Jumlah</td>
              <td rowspan="2">{{ fixedValueNumber(item.FORM_B.RAW_HAL2.JUMLAH.COL1) }}</td>
              <td rowspan="2">{{ fixedValueNumber(item.FORM_B.RAW_HAL2.JUMLAH.COL2) }}</td>
              <td rowspan="2">{{ fixedValueNumber(item.FORM_B.RAW_HAL2.JUMLAH.COL3) }}</td>
              <td colspan="8">Tanggal dan Intensitas Maximum</td>
            </tr>
            <tr>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.DATE.COL4) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.DATE.COL5) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.DATE.COL6) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.DATE.COL7) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.DATE.COL8) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.DATE.COL9) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.DATE.COL10) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.DATE.COL11) }}</td>
            </tr>
            <tr>
              <td>Max</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.MAX_HUJAN.COL1) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.MAX_HUJAN.COL2) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.MAX_HUJAN.COL3) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.VALUES.COL4) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.VALUES.COL5) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.VALUES.COL6) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.VALUES.COL7) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.VALUES.COL8) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.VALUES.COL9) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.VALUES.COL10) }}</td>
              <td>{{ fixedValueNumber(item.FORM_B.RAW_HAL2.INTENCE_MAX.VALUES.COL11) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportHUJANHELMAN",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values) {
      var DataResult = parseFloat(Number(values).toFixed(1));
      if (values == null || values == "-") {
        DataResult = "-";
      } else if (values === "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult; //parseFloat(Number(values).toFixed(1));//parseFloat(Number(values).toFixed(1));//DataResult;
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.title-report-HUJANHELMAN {
  width: 1303px;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
}

.HUJANHELMAN td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-HUJANHELMAN td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-HUJANHELMAN > thead > tr > td {
  text-align: center;
  background: #e0e4e7;
  color: #000000;
  border-left: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
  padding: 5px !important;
}

.table-report-HUJANHELMAN tbody > tr > td {
  padding: 2px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
}

.table-report-HUJANHELMAN tbody > tr > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-HUJANHELMAN tr {
  border: none !important;
}

.table-report-HUJANHELMAN thead :not(.table-code) > td {
  padding: 2px;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
  border-top: solid 0.5px #000;
}

.table-report-HUJANHELMAN thead :not(.table-code) > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-HUJANHELMAN {
  font-size: 7px !important;
  margin-bottom: 20px !important;
  width: 1303px;
}

.table-report-HUJANHELMAN tbody > tr:not(".rowpan") {
  background-color: #ffffff;
}

.table-report-HUJANHELMAN tbody > tr:nth-child(even) {
  background-color: #ffffff;
}

.rowpan td {
  border: solid 0.5px #000;
  background-color: #ffffff !important;
}

.table-report-HUJANHELMAN td {
  max-width: 90px;
}
</style>
