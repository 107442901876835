<template>
  <div style="margin:25px;">
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <div v-for="(raw, indexRaw) in item" :key="indexRaw">
        <div v-for="(rawJam, jam) in raw.RAW_JAM" :key="jam">
          <div class="title-report-LYSIMETER" style="text-align:center;">
            <span style="padding:10px;">
              FORMULIR EVAPOTRANSPIRASI <br />
              LYSIMETER
            </span>
          </div>

          <div style="font-size:12px;margin-top:10px;margin-bottom:10px;width:100%;color:#000;">
            <div style="float:left;min-width:70%;">
              <div style="float:left;width:170px;">STASIUN</div>
              <div style="float:left;width:20px;">:</div>
              <div style="float:left;min-width:180px;">{{ dataPDF.station[0] != undefined ? dataPDF.station[0].station_name : "" }}</div>
            </div>
            <div style="float:right;min-width:30%;">
              <div style="float:right;min-width:80px;">{{ jam }} WS</div>
              <div style="float:right;width:20px;">:</div>
              <div style="float:right;width:80px;">JAM</div>
            </div>
            <div style="clear:both"></div>

            <div style="float:left;min-width:70%;">
              <div style="float:left;width:170px;">BULAN</div>
              <div style="float:left;width:20px;">:</div>
              <div style="float:left;min-width:200px;">{{ dataPDF.bulan }}</div>
            </div>
            <div style="float:right;min-width:30%;">
              <div style="float:right;min-width:80px;">{{ dataPDF.station[0].current_latitude }} {{ Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS" }}</div>
              <div style="float:right;width:20px;">:</div>
              <div style="float:right;width:80px;">LINTANG</div>
            </div>
            <div style="clear:both"></div>

            <div style="float:left;min-width:70%;">
              <div style="float:left;width:170px;">TAHUN</div>
              <div style="float:left;width:20px;">:</div>
              <div style="float:left;min-width:200px;">{{ dataPDF.tahun }}</div>
            </div>
            <div style="float:right;min-width:30%;">
              <div style="float:right;min-width:80px;">{{ dataPDF.station[0].current_longitude }} BT</div>
              <div style="float:right;width:20px;">:</div>
              <div style="float:right;width:80px;">BUJUR</div>
            </div>
            <div style="clear:both"></div>

            <div style="float:left;min-width:70%;">
              <div style="float:left;width:170px;">PENAMPANG LYSIMETER</div>
              <div style="float:left;width:20px;">:</div>
              <div style="float:left;min-width:200px;">{{ raw.PENAMPANG_LYSIMETER }}</div>
            </div>
            <div style="float:right;min-width:30%;">
              <div style="float:right;min-width:80px;">{{ dataPDF.station[0].station_elevation }} m</div>
              <div style="float:right;width:20px;">:</div>
              <div style="float:right;width:80px;">ELEVASI</div>
            </div>
            <div style="clear:both"></div>
          </div>

          <div style="margin-top:10px;">
            <table class="table-report-LYSIMETER">
              <thead>
                <tr>
                  <td>Tanggal</td>
                  <td>Air Siraman (S) (liter)</td>
                  <td>Hujan (H) (mm)</td>
                  <td>Air Perkolasi (Pk) (liter)</td>
                  <td>Evapotranpirasi (PE) (mm)</td>
                  <td>Catatan</td>
                </tr>
                <tr>
                  <td style="border-top:none;width:115px;">(1)</td>
                  <td style="border-top:none;width:115px;" v-for="n in 5" :key="n + '_headEmptyLYSIMETER'">({{ n + 1 }})</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(raw, indexRaw) in rawJam.RAW" :key="indexRaw + '_bodyLYSIMETER'">
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(indexRaw + 1) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL2) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL3) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL4) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL5) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL6) }}</td>
                </tr>
                <tr v-for="o in 31 - rawJam.RAW.length" :key="o + '_bodyEmptyLYSIMETER'">
                  <td style="border-top:none;">{{ o + rawJam.RAW.length }}</td>
                  <td v-for="j in 5" :key="j + '_bodyEmptyLYSIMETER'" style="border-top:none;"></td>
                </tr>
                <tr>
                  <td style="border-top:none;text-align:left;">Jumlah</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(rawJam.CALCULATE.JUMLAH.COL2) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(rawJam.CALCULATE.JUMLAH.COL3) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(rawJam.CALCULATE.JUMLAH.COL4) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(rawJam.CALCULATE.JUMLAH.COL5) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(rawJam.CALCULATE.JUMLAH.COL6) }}</td>
                </tr>
                <tr>
                  <td style="border-top:none;text-align:left;">Rata<sup>2</sup></td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL2) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL3) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL4) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL5) }}</td>
                  <td style="border-top:none;text-align:center;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL6) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="html2pdf__page-break" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportLYSIMETER",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult;
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.title-report-LYSIMETER {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
}

.LYSIMETER td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-LYSIMETER td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-LYSIMETER > thead > tr > td {
  text-align: center;
  background: #e0e4e7;
  color: #000000;
  border-left: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
  padding: 5px !important;
}

.table-report-LYSIMETER tbody > tr > td {
  padding: 5px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
}

.table-report-LYSIMETER tbody > tr > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-LYSIMETER tr {
  border: none !important;
}

.table-report-LYSIMETER thead :not(.table-code) > td {
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
}

.table-report-LYSIMETER thead :not(.table-code) > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-LYSIMETER tbody > tr {
  background-color: #fff;
}

.table-report-LYSIMETER tbody > tr:nth-child(even) {
  background-color: #fff;
}

.table-report-LYSIMETER {
  font-size: 8.5px !important;
  font-weight: bold;
  margin-bottom: 20px !important;
  width: 100%;
}

/* .table-report-LYSIMETER td{
  max-width: 90px;
  height: 30px;
} */
</style>
