<template>
  <div style="margin:10px;">
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <div v-for="(dataTime, timeIndex) in item" :key="timeIndex">
        <div class="html2pdf__page-break" v-if="dataTime.index % 3 == 0 && dataTime.index != 0" />
        <div v-if="dataTime.index % 3 == 0">
          <div class="title-report-me48">{{ dataPDF.title }}</div>
          <div class="title-report-label" style="margin-bottom:5px; height:30px">
            <div style="float:left;">TANGGAL : {{ periode }}</div>
            <div style="float:right;margin-right:15px;">WMO ID : {{ dataPDF.wmo_id }}</div>
          </div>
        </div>

        <!-- <section class="pdf-item"> -->
        <table class="table-report header-table" v-if="dataTime.index % 3 == 0">
          <thead>
            <tr>
              <td>1</td>
              <td colspan="3">2</td>
              <td colspan="3">3</td>
              <td colspan="3">4</td>
              <td style="width:100px;">5</td>
              <td colspan="5">6</td>
              <td colspan="3">7</td>
              <td colspan="2">8</td>
              <td style="width:100px;">9</td>
              <td style="width:100px;">10</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Jam</td>
              <td colspan="3">Angin-Penglihatan-Keadaan Cuaca</td>
              <td colspan="3">Tekanan Udara</td>
              <td colspan="2">Suhu Udara</td>
              <td>Suhu Ekstrim</td>
              <td>Endapan</td>
              <td colspan="5">Perawanan</td>
              <td colspan="3">Lapisan Awan</td>
              <td colspan="2">Penyinaran Matahari</td>
              <td>Tanah / Catatan</td>
              <td>Observer</td>
            </tr>
            <tr>
              <td rowspan="4" class="rotation_90">
                <div>Waktu pengamatan setempat</div>
              </td>
              <td>Data Angin (iw)</td>
              <td rowspan="4" class="rotation_90">
                <div>Penglihatan mendatar (km)</div>
              </td>
              <td>Pengenal Data Cuaca (ix)</td>
              <td>Derajat Panas (oC)</td>
              <td>Koreksi QFF (mb)</td>
              <td>Koreksi QFE (mb)</td>
              <td rowspan="2">Bola Kering (oC)</td>
              <td rowspan="2">Titik Embun (oC)</td>
              <td rowspan="2">Maksimum (oC)</td>
              <td>Pengenal Data Hujan (iR)</td>
              <td rowspan="2">Awan Rendah (Jenis)</td>
              <td rowspan="2">Arah Sudut Elevasi (derajat)</td>
              <td rowspan="2">Awan Menenga (Banyak / Jenis)</td>
              <td>Arah (derajat)</td>
              <td rowspan="4" class="rotation_90">
                <div>Banyak Langit yang Tertutup Awan</div>
              </td>
              <td rowspan="4" class="rotation_90">
                <div>Jenis</div>
              </td>
              <td rowspan="4" class="rotation_90">
                <div>Tingginya (m)</div>
              </td>
              <td rowspan="4" class="rotation_90">
                <div>Banyaknya (oktas)</div>
              </td>
              <td rowspan="2">Pengenal Data Penguapan (iE)</td>
              <td rowspan="2">Penguapan 24 Jam Lalu</td>
              <td rowspan="2">Keadaan Tanah</td>
              <td rowspan="2">Paraf</td>
            </tr>
            <tr>
              <td rowspan="2">Arah Angin (derajat)</td>
              <td>Keadaan Cuaca (ww)</td>
              <td>Tekanan Udara</td>
              <td>QFF (inch)</td>
              <td>QFE (inch)</td>
              <td>Hujan Takaran Terakhir</td>
              <td>Tinggi (m)</td>
            </tr>
            <tr>
              <td>Cuaca Yang Lalu 1 (W1)</td>
              <td colspan="3">Perubahan Tekanan Udara</td>
              <td rowspan="2">Bola Basah (oC)</td>
              <td rowspan="2">Lembab Nisbi (%)</td>
              <td rowspan="2">Minimum (oC)</td>
              <td>Hujan 6 Jam Lalu (mm)</td>
              <td>Tinggi Dasar (m)</td>
              <td rowspan="2">Banyaknya (Oktas)</td>
              <td rowspan="2">Awan Tinggi (Banyak / Jenis)</td>
              <td>Arah (derajat)</td>
              <td colspan="2">Radiasi 24 Jam Lalu</td>
              <td rowspan="2">Catatan</td>
              <td rowspan="2">Nama Terang</td>
            </tr>
            <tr>
              <td>Kec. Angin (knot)</td>
              <td>Cuaca Yang Lalu 2 (W2)</td>
              <td>3 Jam (mb)</td>
              <td colspan="2">24 Jam (mb)</td>
              <td>Hujan 24 Jam Lalu (mm)</td>
              <td>Tinggi Puncak (m)</td>
              <td>Tinggi (m)</td>
              <td colspan="2">Lamanya Penyinaran Matahari (Jam)</td>
            </tr>
          </tbody>
        </table>
        <div v-if="dataTime.index % 3 == 0" style="padding:10px 0px 10px 0px;width:1303px;font-size:8.5px;">
          MIMIMJMJ YYGGiw Iiiii iRixhVV Nddff 1SnTTT 2SnTdTdTd 3PoPoPoPo 4PPPP 5appp 6RRRtR 7wwW1W2 8NhClCmCh 333 (1SnTxTxTx) (2SnTnTnTn) (5EEEiE) (55SSS) (55508) (5F24F24F24F24) (56DLDMDH) (57CDaec) (58P24P24P24) (59P24P24P24) 6RRRtR)
          (8NsChshs) (80Chshs)
        </div>

        <table class="table-report" v-bind:class="[dataTime.index % 2 == 0 ? 'report_1' : 'report_2']" style="">
          <tbody>
            <tr class="data-table">
              <td rowspan="4" class="rotation_90">
                <div>{{ timeIndex }}</div>
              </td>
              <td>{{ dataTime.RAW_2.DATA_ANGIN }}</td>
              <td rowspan="4">
                <div>{{ dataTime.RAW_2.PENGLIHATAN_MENDATAR }}</div>
              </td>
              <td>{{ dataTime.RAW_2.PENGLIHATAN_MENDATAR }}</td>
              <td>{{ dataTime.RAW_3.DERAJAT_PANAS }}</td>
              <td>{{ dataTime.RAW_3.KOREKSI_QFF_1 }}</td>
              <td>{{ dataTime.RAW_3.KOREKSI_QFE_2 }}</td>
              <td rowspan="2">{{ dataTime.RAW_4.SU_BOLA_KERING }}</td>
              <td rowspan="2">{{ dataTime.RAW_4.SU_TITIK_EMBUN }}</td>
              <td rowspan="2">{{ dataTime.RAW_4.SE_MAKSIMUM }}</td>
              <td>{{ dataTime.RAW_5.PENGENAL_DATA_HUJAN }}</td>
              <td rowspan="2">{{ AWAN_RENDAH[dataTime.RAW_6.AR_AWAN_RENDAH] == undefined ? dataTime.RAW_6.AR_AWAN_RENDAH : AWAN_RENDAH[dataTime.RAW_6.AR_AWAN_RENDAH] }}</td>
              <td rowspan="2">{{ dataTime.RAW_6.AR_ARAH_SUDUT_ELEVASI }}</td>
              <td rowspan="2">{{ AWAN_MENENGAH[dataTime.RAW_6.AR_AWAN_MENENGAH] == undefined ? dataTime.RAW_6.AR_AWAN_MENENGAH : AWAN_MENENGAH[dataTime.RAW_6.AR_AWAN_MENENGAH] }}</td>
              <td>{{ dataTime.RAW_6.AR_ARAH }}</td>
              <td rowspan="4">
                <div>{{ dataTime.RAW_6.BLTA }}</div>
              </td>
              <td rowspan="4">
                <div>{{ dataTime.RAW_7.JENIS }}</div>
              </td>
              <td rowspan="4">
                <div>{{ dataTime.RAW_7.TINGGI }}</div>
              </td>
              <td rowspan="4">
                <div>{{ dataTime.RAW_7.BANYAK }}</div>
              </td>
              <td rowspan="2">{{ dataTime.RAW_8.DATA_PENGUAPAN }}</td>
              <td rowspan="2">{{ dataTime.RAW_8.PENGUAPAN_24_JAM_LALU }}</td>
              <td rowspan="2">{{ dataTime.RAW_9.KEADAAN_TANAH }}</td>
              <td rowspan="2">{{ dataTime.RAW_10.PARAF }}</td>
            </tr>
            <tr>
              <td rowspan="2">{{ dataTime.RAW_2.ARAH_ANGIN }}</td>
              <td>{{ KEADAAN[dataTime.RAW_2.KEADAAN] == undefined ? dataTime.RAW_2.KEADAAN : KEADAAN[dataTime.RAW_2.KEADAAN] }}</td>
              <td>{{ dataTime.RAW_3.TEKANAN_UDARA }}</td>
              <td>{{ dataTime.RAW_3.QFF_1 }}</td>
              <td>{{ dataTime.RAW_3.QFE_2 }}</td>
              <td>{{ dataTime.RAW_5.HUJAN_TAKARAN_TERAKHIR }}</td>
              <td>{{ AWAN_TINGGI[dataTime.RAW_6.AR_TINGGI] == undefined ? dataTime.RAW_6.AR_TINGGI : AWAN_TINGGI[dataTime.RAW_6.AR_TINGGI] }}</td>
            </tr>
            <tr>
              <td>{{ CUACA_LALU[dataTime.RAW_2.CUACA_LALU_1] == undefined ? dataTime.RAW_2.CUACA_LALU_1 : CUACA_LALU[dataTime.RAW_2.CUACA_LALU_1] }}</td>
              <td colspan="3">{{ dataTime.RAW_3.PERUBAHAN_TEKANAN }}</td>
              <td rowspan="2">{{ dataTime.RAW_4.SU_BOLA_BASAH }}</td>
              <td rowspan="2">{{ dataTime.RAW_4.SU_LEMBAB_NISBI }}</td>
              <td rowspan="2">{{ dataTime.RAW_4.SE_MINIMUM }}</td>
              <td>{{ dataTime.RAW_5.HUJAN_6_JAM_LALU }}</td>
              <td>{{ dataTime.RAW_6.AT_TINGGI_DASAR }}</td>
              <td rowspan="2">{{ dataTime.RAW_6.AT_BANYAK }}</td>
              <td rowspan="2">{{ dataTime.RAW_6.AT_AWAN_TINGGI }}</td>
              <td>{{ dataTime.RAW_6.AT_ARAH }}</td>
              <td colspan="2">{{ dataTime.RAW_8.RADIASI_24_JAM_LALU }}</td>
              <td rowspan="2">{{ dataTime.RAW_9.CATATAN }}</td>
              <td rowspan="2">{{ dataTime.RAW_10.NAMA_TERANG }}</td>
            </tr>
            <tr>
              <td>{{ dataTime.RAW_2.KEC_ANGIN }}</td>
              <td>{{ CUACA_LALU[dataTime.RAW_2.CUACA_LALU_2] == undefined ? dataTime.RAW_2.CUACA_LALU_2 : CUACA_LALU[dataTime.RAW_2.CUACA_LALU_2] }}</td>
              <td>{{ dataTime.RAW_3["3_JAM"] }}</td>
              <td colspan="2">{{ dataTime.RAW_3["24_JAM"] }}</td>
              <td>{{ dataTime.RAW_5.HUJAN_24_JAM_LALU }}</td>
              <td>{{ dataTime.RAW_6.AT_TINGGI_PUNCAK }}</td>
              <td>{{ dataTime.RAW_6.AT_TINGGI }}</td>
              <td colspan="2">{{ dataTime.RAW_8.PENYINARAN_MATAHARI }}</td>
            </tr>
          </tbody>
        </table>
        <div style="padding:10px 0px 10px 0px;width:1303px;font-size:8.5px;">
          {{ dataTime.RAW_11 }}
        </div>
      </div>
      <div class="html2pdf__page-break" v-if="onCheckKey(dataPDF.data, periode) == false" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportME48",
  props: {
    dataPDF: Object,
  },
  methods: {
    onCheckKey(item, key) {
      var LAST = "";
      for (var i in item) {
        LAST = i;
      }
      console.log(LAST);
      if (LAST == key) {
        return true;
      } else {
        return false;
      }
    },
  },
  data: function() {
    return {
      KEADAAN: {
        "64": "Intermittent heavy rai",
        "65": "Continuous heavy rai",
        "66": "Light freezing rai",
        "67": "Moderate to heavy freezing rai",
        "68": "Light rain and sno",
        "69": "Moderate to heavy rain and sno",
        "70": "Intermittent light sno",
        "71": "Continuous light sno",
        "72": "Intermittent moderate sno",
        "73": "Continuous moderate sno",
        "74": "Intermittent heavy sno",
        "75": "Continuous heavy sno",
        "76": "Diamond dus",
        "77": "Snow grain",
        "78": "Snow crystal",
        "79": "Ice pellet",
        "80": "Light rain shower",
        "81": "Moderate to heavy rain shower",
        "82": "Violent rain shower",
        "83": "Light rain and snow shower",
        "84": "Moderate to heavy rain and snow shower",
        "85": "Light snow shower",
        "86": "Moderate to heavy snow shower",
        "87": "Light snow/ice pellet shower",
        "88": "Moderate to heavy snow/ice pellet shower",
        "89": "light hail shower",
        "90": "Moderate to heavy hail shower",
        "91": "Thunderstorm in past hour, currently only light rai",
        "92": "Thunderstorm in past hour, currently only moderate to heavy rai",
        "93": "Thunderstorm in past hour, currently only light snow or rain/snow mi",
        "94": "Thunderstorm in past hour, currently only moderete to heav",
        "95": "Light to moderate thunderstor",
        "96": "Light to moderate thunderstorm with hai",
        "97": "Heavy thunderstor",
        "98": "Thunderstorm combined with duststorm or sandstorm at time of observatio",
        "99": "Thunderstorm, heavy, with hail at time of observatio",
      },
      CUACA_LALU: {
        "0": "Cloudy -",
        "1": "Cloudy",
        "2": "Cloudy +",
        "3": "Sandstorm, duststorm or blowing snow",
        "4": "fog, or thick haze.",
        "5": "Drizzle",
        "6": "Rain",
        "7": "Snow or mixed rain and snow",
        "8": "Showers",
        "9": "Thunderstorms",
      },
      AWAN_RENDAH: {
        "0": "0",
        "1": "Cu",
        "2": "Cu",
        "3": "Cb",
        "4": "Sc",
        "5": "Sc",
        "6": "St",
        "7": "Cu",
        "8": "CuSc",
        "9": "Cb",
        "/": "-",
      },
      AWAN_MENENGAH: {
        "0": "0",
        "1": "As",
        "2": "As",
        "3": "Ac",
        "4": "Ac",
        "5": "Ac",
        "6": "Ac",
        "7": "AC-As",
        "8": "AC",
        "9": "AC",
        "/": "-",
      },
      AWAN_TINGGI: {
        "0": "0",
        "1": "Ci",
        "2": "Ci",
        "3": "Ci",
        "4": "CI",
        "5": "CiCs",
        "6": "CiCs",
        "7": "Cs",
        "8": "Cs",
        "9": "Cc",
        "/": "-",
      },
    };
  },
};
</script>

<style>
.title-report-me48 {
  width: 1303px;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.title-report-label {
  width: 1303px;
  padding: 10px;
  font-weight: bold;
}

.table-report {
  width: 1303px;
  font-size: 8.5px;
  border-left: solid 0.5px #000000;
}

.table-report tr td {
  min-width: 57px !important;
}

td.rotation_90 {
  max-width: 55px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report > thead > tr > td {
  text-align: center;
  min-width: 55px;
}

.table-report > tbody > tr > td {
  padding: 4px !important;
  margin: 0px;
  text-align: center;
  min-width: 55px;
}

.table-report tr {
  border: none !important;
}

.table-report tr td {
  max-height: 55px !important;
  padding: 4px;
  border-right: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
}

.header-table {
  text-align: center;
  background: #e0e4e7;
  color: #000000;
  border-left: solid 0.5px #000 !important;
  text-align: center;
  font-weight: bold;
  padding: 5px !important;
}

.header-table tr td {
  border-right: solid 0.5px #000 !important;
  color: #000000 !important;
}

.report_1 {
  /* background-color: #f4fbff; */
  background-color: #ffffff;
  border-left: solid 0.5px #000 !important;
}

.report_1 tr td {
  border-right: solid 0.5px #000 !important;
}

.report_2 {
  /* background-color: #e7edff; */
  background-color: #ffffff;
  border-left: solid 0.5px #000 !important;
}

.report_2 tr td {
  border-right: solid 0.5px #000 !important;
}

.table-report {
  border-top: solid 0.5px #000;
  border-left: solid 0.5px #000;
}
</style>
