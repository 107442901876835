<template>
  <div style="margin:25px;">
    <!-- {{dataPDF}} -->
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <table class="title-report-AGMIA" style="margin-bottom:5px;">
        <tr>
          <td style="text-align:left;width:200px;">AgM 1-a</td>
          <td style="text-align:center;text-transform: uppercase;" colspan="5">PERAMATAN-PERAMATAN {{ dataPDF.station.length > 0 ? dataPDF.station[0].station_name : "" }} TAHUN {{ dataPDF.tahun }}</td>
          <td style="text-align:right;">Halaman 1</td>
        </tr>
        <tr>
          <td>Garis Lintang</td>
          <td>:</td>
          <td style="width:400px;">
            <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].current_latitude }} {{ Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS" }}</span>
          </td>
          <td style="width:30%;"></td>
          <td rowspan="3">Waktu Peramatan</td>
          <td>I.</td>
          <td>Jam 07.00 WS</td>
        </tr>
        <tr>
          <td>Garis Bujur</td>
          <td>:</td>
          <td>
            <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].current_longitude }}</span>
          </td>
          <td></td>
          <td>II.</td>
          <td>Jam 14.00 WS</td>
        </tr>
        <tr>
          <td>Tinggi di atas permukaan</td>
          <td>:</td>
          <td>
            <span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_elevation }}</span>
          </td>
          <td></td>
          <td>III.</td>
          <td>Jam 18.00 WS</td>
        </tr>
      </table>

      <table class="table-report-AGMIA">
        <thead>
          <tr>
            <td rowspan="3">No Periode</td>
            <td rowspan="3">No Mingguan</td>
            <td rowspan="3">TGL</td>
            <td colspan="9">T e m p e r a t u r</td>
            <td rowspan="2" colspan="3">Lembab Nisbi dalam persen</td>
            <td colspan="9">A n g i n</td>
            <td rowspan="2">Sinar Matahari</td>
            <td rowspan="2">Hujan (dalam mm)</td>
            <td colspan="4">Uji Peramatan</td>
          </tr>
          <tr>
            <td style="border-top:none;">BK</td>
            <td style="border-top:none;">BB</td>
            <td style="border-top:none;">BK</td>
            <td style="border-top:none;">BB</td>
            <td style="border-top:none;">BK</td>
            <td style="border-top:none;">BB</td>
            <td style="border-top:none;">Min Rumput</td>
            <td style="border-top:none;">Min</td>
            <td style="border-top:none;">Max</td>
            <td style="border-top:none;" colspan="3">Kecepatan rata-rata antara waktu</td>
            <td style="border-top:none;" colspan="3">Arah pada waktu peramatan</td>
            <td style="border-top:none;" colspan="3">Kecepatan pada waktu peramatan</td>
            <td style="border-top:none;">BK</td>
            <td style="border-top:none;">Min</td>
            <td style="border-top:none;">BK</td>
            <td style="border-top:none;">Max</td>
          </tr>
          <tr>
            <td style="border-top:none;">I</td>
            <td style="border-top:none;">I</td>
            <td style="border-top:none;">II</td>
            <td style="border-top:none;">II</td>
            <td style="border-top:none;">III</td>
            <td style="border-top:none;">III</td>
            <td style="border-top:none;">I</td>
            <td style="border-top:none;">II</td>
            <td style="border-top:none;">III</td>
            <td style="border-top:none;">I</td>
            <td style="border-top:none;">II</td>
            <td style="border-top:none;">III</td>
            <td style="border-top:none;">III-I</td>
            <td style="border-top:none;">I-II</td>
            <td style="border-top:none;">II-III</td>
            <td style="border-top:none;">I</td>
            <td style="border-top:none;">II</td>
            <td style="border-top:none;">III</td>
            <td style="border-top:none;">I</td>
            <td style="border-top:none;">II</td>
            <td style="border-top:none;">III</td>
            <td style="border-top:none;">I</td>
            <td style="border-top:none;">I</td>
            <td style="border-top:none;">II</td>
            <td style="border-top:none;">II</td>
            <td style="border-top:none;">III</td>
            <td style="border-top:none;">III</td>
          </tr>
          <tr>
            <td v-for="j in 30" :key="j + '_headerEmptyAGMIA'" style="border-top:none;">{{ j }}</td>
          </tr>
        </thead>
        <span style="display:none;">{{ (jml_start = 0) }}</span>
        <tbody v-for="(mingguRaw, indexMinggu) in item.HAL_1" :key="indexMinggu">
          <span style="display:none;">{{ (no = 1) }}</span>
          <tr v-for="(raw, indexRaw) in mingguRaw.RAW" :key="indexRaw" class="rowpan">
            <td v-if="jml_start == 0" v-bind:rowspan="JUMLAH">I</td>
            <td v-if="no == 1" v-bind:rowspan="mingguRaw.RAW.length">{{ indexMinggu }}</td>
            <td v-for="(colRaw, indexCol) in raw" :key="indexCol">{{ colRaw }}</td>
            <span style="display:none;">{{ no++ }}{{ jml_start++ }}{{ JUMLAH++ }}</span>
          </tr>
          <span style="display:none;">{{ (jml_start = 0) }}</span>
          <tr class="rowpan">
            <td colspan="2">Jumlah</td>
            <td v-for="(jmlRaw, indexJml) in mingguRaw.JUMLAH" :key="indexJml">{{ fixedValueNumber(jmlRaw.toFixed(1)) }}</td>
          </tr>
          <tr class="rowpan">
            <td colspan="2">Rata</td>
            <td v-for="(rataRaw, indexRata) in mingguRaw.RATA" :key="indexRata">{{ rataRaw }}</td>
          </tr>
          <span style="display:none;">{{ (JUMLAH = JUMLAH + 2) }}</span>
        </tbody>
      </table>

      <div class="html2pdf__page-break" />

      <table class="title-report-AGMIA" style="margin-bottom:5px;">
        <tr>
          <td colspan="3" style="text-align:center;padding:5px;text-transform: uppercase;">CATATAN HARIAN CUACA DI {{ dataPDF.station.length > 0 ? dataPDF.station[0].station_name : "" }} TAHUN {{ dataPDF.tahun }}</td>
        </tr>
        <tr>
          <td style="width:200px;text-align:left;">AgM 1-a</td>
          <td style="text-align:center;">CATATAN INTENSITAS KEJADIAN-KEJADIAN</td>
          <td style="width:200px;text-align:right;">Halaman 2</td>
        </tr>
      </table>

      <table class="table-report-AGMIA">
        <thead>
          <tr>
            <td>Fenomena Cuaca</td>
            <td>Ringan/Enteng</td>
            <td>Sedang</td>
            <td colspan="3">Lebat (t e b a l)</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;">Hujan</td>
            <td style="border-top:none;text-align: left;">Kurang dari 13 mm/jam</td>
            <td style="border-top:none;text-align: left;">13 mm/jam sampai 38 mm/jam</td>
            <td style="border-top:none;text-align: left;" colspan="3">Lebih dari 38 mm/jam</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;">Salju</td>
            <td style="border-top:none;text-align: left;"></td>
            <td style="border-top:none;text-align: left;"></td>
            <td style="border-top:none;text-align: left;" colspan="3">Tetes-tetes air besar yang jatuh</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;">Embun</td>
            <td style="border-top:none;text-align: left;">Tetes-tetes air kecil</td>
            <td style="border-top:none;text-align: left;">Tetes-tetes air besar</td>
            <td style="border-top:none;text-align: left;" colspan="3"></td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;">Embun Upas</td>
            <td style="border-top:none;text-align: left;">Sedikit memengaruhi tumbuh-2an</td>
            <td style="border-top:none;text-align: left;">Sebagian tumbuh-2an mati</td>
            <td style="border-top:none;text-align: left;" colspan="3">Sebagian besar tumbuh-2an mati</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;">Udara Buruk</td>
            <td style="border-top:none;text-align: left;">Guntur dan kilat kadang-kadang</td>
            <td style="border-top:none;text-align: left;">Sering terjadi guntur dan kilat dengan</td>
            <td style="border-top:none;text-align: left;" colspan="3">Guntur dan kilat terus menerus dengan hujan</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;">(Thunderstorm)</td>
            <td style="border-top:none;text-align: left;"></td>
            <td style="border-top:none;text-align: left;">hujan sedang Kec angin 30-70 km/jam</td>
            <td style="border-top:none;text-align: left;" colspan="3">lebar/keras. Kecepatan angin diatas 70 km/jam</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;" rowspan="3">Rambun</td>
            <td style="border-top:none;text-align: left;">Butir-butir es kecil dan jarang</td>
            <td style="border-top:none;text-align: left;">Jatuhnya dapat menutupi permukaan tanah</td>
            <td style="border-top:none;text-align: left;" colspan="3">Butir-butir es yang besar</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;">sering bercampur dengan hujan</td>
            <td style="border-top:none;text-align: left;">Jika meleleh dapat menghasilkan genangan air</td>
            <td style="border-top:none;text-align: left;" colspan="3">Palawija rusak karenanya</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;"></td>
            <td style="border-top:none;text-align: left;">seperti pada hujan sedang</td>
            <td style="border-top:none;text-align: left;" colspan="3">Pdaun-daun terlepas dari pohonnya</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;"></td>
            <td style="border-top:none;text-align: left;"></td>
            <td style="border-top:none;text-align: left;"></td>
            <td style="border-top:none;text-align: left;" colspan="3">dapat memecahkan kaca</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;">Kabut</td>
            <td style="border-top:none;text-align: left;">Penglihatan kurang dari 2 km</td>
            <td style="border-top:none;text-align: left;">Penglihatan kurang dari 1 km</td>
            <td style="border-top:none;text-align: left;" colspan="3">Penglihatan kurang dari 200 m</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;">Kabut Debu</td>
            <td style="border-top:none;text-align: left;">Penglihatan kurang dari 4 km</td>
            <td style="border-top:none;text-align: left;">Penglihatan kurang dari 2 km</td>
            <td style="border-top:none;text-align: left;" colspan="3">Penglihatan kurang dari 1 km</td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;">Angin Ribut</td>
            <td style="border-top:none;text-align: left;">Kencang : kecepatan 30-50 km/jam</td>
            <td style="border-top:none;text-align: left;">Amat kencang : kecepatan diatas 50 km/jam</td>
            <td style="border-top:none;text-align: left;" colspan="3"></td>
          </tr>
          <tr>
            <td style="border-top:none;text-align: left;">
              Kode Cuaca Pada <br />
              Waktu Peramatan
            </td>
            <td style="border-top:none;text-align: left;">
              0 - Langit tak berawan/sedikit berawan <br />
              1 - Perawanan berganti-2/berubah-2
            </td>
            <td style="border-top:none;text-align: left;">
              2 - Langit berawan seluruhnya <br />
              3 - Badai debu
            </td>
            <td style="border-top:none;text-align: left;">
              4 - kabut/kabut debu yang tebal <br />
              5 - Hujan rintik-rintik
            </td>
            <td style="border-top:none;text-align: left;">
              6 - Hujan <br />
              7 - Salju
            </td>
            <td style="border-top:none;text-align: left;">
              8 - Hujan tiba-tiba <br />
              9 - Udara buruk dg/tanpa hujan
            </td>
          </tr>
        </thead>
      </table>

      <table class="table-report-AGMIA">
        <thead>
          <tr>
            <td rowspan="2">No Periode</td>
            <td rowspan="2">No Minggu</td>
            <td rowspan="2">Tgl</td>
            <td colspan="2">Keadaan Cuaca Pada Waktu Peramatan</td>
            <td colspan="9">Keadaan Cuaca Pada Waktu Peramatan</td>
            <td rowspan="2">Catatan mengenai jam terjadinya dan lamanya kejadian-kejadian serta pengaruhnya (kalau ada) atas tanaman-tanaman</td>
          </tr>
          <tr>
            <td style="border-top:none;">Jam 07.00</td>
            <td style="border-top:none;">Jam 14.00</td>
            <td style="border-top:none;">Hujan</td>
            <td style="border-top:none;">Pembekuan (embun upas)</td>
            <td style="border-top:none;">Embun</td>
            <td style="border-top:none;">Kabut</td>
            <td style="border-top:none;">Udara Buruk Tanpa Rambun</td>
            <td style="border-top:none;">Rambun tiba-tiba sebut diameter batu Rambun</td>
            <td style="border-top:none;">Kabut Debu (Haze)</td>
            <td style="border-top:none;">Badai Debu</td>
            <td style="border-top:none;border-right:none;">Angin Ribut (Kencang)</td>
          </tr>
          <tr>
            <td v-for="j in 15" :key="j + '_headerEmptyOPPENGUAPAN'" style="border-top:none;">{{ j }}</td>
          </tr>
        </thead>

        <span style="display:none;">{{ (jml_start = 0) }}</span>
        <tbody v-for="(mingguRaw, indexMinggu) in item.HAL_2" :key="indexMinggu">
          <span style="display:none;">{{ (no = 1) }}</span>
          <tr v-for="(raw, indexRaw) in mingguRaw.RAW" :key="indexRaw" class="rowpan">
            <td v-if="jml_start == 0" v-bind:rowspan="JUMLAH_2">I</td>
            <td v-if="no == 1" v-bind:rowspan="mingguRaw.RAW.length">{{ indexMinggu }}</td>
            <td v-for="(colRaw, indexCol) in raw" :key="indexCol" class="blang" v-bind:class="[indexRaw % 2 == 0 ? 'tdganjil' : 'tdgenap']">{{ colRaw }}</td>
            <span style="display:none;">{{ no++ }}{{ jml_start++ }}{{ JUMLAH_2++ }}</span>
          </tr>
          <span style="display:none;">{{ (jml_start = 0) }}</span>
          <tr class="rowpan">
            <td colspan="2" style="border:solid 0.5px #000;">Jumlah</td>
            <td v-for="(jmlRaw, indexJml) in mingguRaw.JUMLAH" :key="indexJml">{{ fixedValueNumber(jmlRaw.toFixed(1)) }}</td>
          </tr>
          <tr class="rowpan">
            <td colspan="2" style="border:solid 0.5px #000;">Rata</td>
            <td v-for="(rataRaw, indexRata) in mingguRaw.RATA" :key="indexRata">{{ rataRaw }}</td>
          </tr>
          <span style="display:none;">{{ (JUMLAH_2 = JUMLAH_2 + 2) }}</span>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportAGMIA",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult;
    },
  },
  data: function() {
    return {
      JUMLAH: 100,
      JUMLAH_2: 100,
    };
  },
};
</script>

<style>
.title-report-AGMIA {
  width: 1303px;
  padding: 5px;
  font-weight: bold !important;
  font-size: 12px !important;
}

.AGMIA td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-AGMIA td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 10px;
}

.table-report-AGMIA > thead > tr > td {
  text-align: center;
  background: #e0e4e7;
  color: #000000;
  border-left: solid 0.5px #000 !important;
  text-align: center;
  font-weight: bold;
}

.table-report-AGMIA tbody > tr > td {
  padding: 2px !important;
  margin: 0px;
  text-align: center;
  /* border-left: solid 0.5px #000; */
  /* border-bottom: solid 0.5px #000; */
}

.table-report-AGMIA tbody > tr > td:last-of-type {
  /* border: solid 0.5px #000; */
}

.table-report-AGMIA tr {
  border: none !important;
}

.table-report-AGMIA thead :not(.table-code) > td {
  padding: 2px;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
  border-top: solid 0.5px #000;
}

.table-report-AGMIA thead :not(.table-code) > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-AGMIA {
  font-size: 7px !important;
  margin-bottom: 10px !important;
  width: 1303px;
}

.tdgenap {
  background-color: #ffffff !important;
}

.tdganjil {
  background-color: #ffffff !important;
}

.rowpan td {
  /* border-left: solid 0.5px #000 !important; */
  background-color: #ffffff;
}

.table-report-AGMIA td {
  max-width: 90px;
}
</style>
