import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_OPPENGUAPAN = false;
export default {
    async dummyDataOPPENGUAPAN(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01": {
                    "RAW_1" : [
                        {
                            "TGL" : "1",
                            "COL2":"1", 
                            "COL3":"1", 
                            "COL4":"1", 
                            "COL5":"1", 
                        }
                    ],
                    "RAW_2":[
                        {
                            "TGL" : "1",
                            "COL12":"1", 
                            "COL13":"1", 
                        }
                    ]
                }
            }

            var dataBuild = {};
            for(var i in datas){
                
                dataBuild[i] = {};
                dataBuild[i]["BANYAK_HUJAN"] = 0;
                dataBuild[i]["HUJAN_SEBULAN"] = 0;
                dataBuild[i]["PENGUAPAN_SEBULAN"] = 0;
                

                for(var k in datas[i]){
                    dataBuild[i][k] = {
                        "RAW" : [], 
                        "JUMLAH" : {} , 
                        "RATA" : {},
                    }

                    dataBuild[i][k]["RAW"] = datas[i][k].sort(function(a, b) {
                        return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                    });
                    
                    var NO_RAW1 = 1;
                    var POS_RAW1 = 1;

                    var RAW_DATA = {};
                    RAW_DATA[k] = [];
                    for(var icount = 1; icount <= 31; icount++){
                        if(k == "RAW_1"){
                            RAW_DATA[k].push({
                                "TGL"       : icount,
                                "COL2"      : "", 
                                "COL3"      : "",
                                "COL4"      : "",
                                "COL5"      : "",
                            });
                        }
                        if(k == "RAW_2"){
                            RAW_DATA[k].push({
                                "TGL"       : icount,
                                "COL12"     : "1", 
                                "COL13"     : "1", 
                            });
                        }
                    }
                    
                    for(var j in dataBuild[i][k]["RAW"]){
                        var infoRaw = dataBuild[i][k]["RAW"][j];
                        var dataDate = new Date(infoRaw["TGL"]);

                        if(dataBuild[i][k]["JUMLAH"][POS_RAW1] == undefined){
                            dataBuild[i][k]["JUMLAH"][POS_RAW1] = {};
                        }

                        

                        for(var m in infoRaw){

                            if(RAW_DATA[k][Number(dataDate.getDate())-1] != undefined ){
                                RAW_DATA[k][Number(dataDate.getDate())-1][m] = infoRaw[m] == null ||  infoRaw[m] == "null" ? "-" : infoRaw[m];// shortData[k][m] == null || isNaN(Number(shortData[k][m]).toFixed(2))  ? "-" : Number().toFixed(2);
                            }

                            if( m == "TGL"){
                                continue
                            } 
                            if(dataBuild[i][k]["JUMLAH"][POS_RAW1][m] == undefined){
                                dataBuild[i][k]["JUMLAH"][POS_RAW1][m]  = 0;
                            }
                            dataBuild[i][k]["JUMLAH"][POS_RAW1][m] += Number(infoRaw[m]);
                        }
                        if(NO_RAW1 % 15 == 0){
                            POS_RAW1++;
                        }
                        NO_RAW1++;
                    }
                    dataBuild[i][k]["RAW"] = RAW_DATA;
                }

            }
            datas = dataBuild;
            resolve(datas);
        });
    },

    async storeDataOPPENGUAPAN(STATION_ID,PERIODE,WAKTU){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_OPPENGUAPAN(STATION_ID,PERIODE,WAKTU);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataOPPENGUAPAN(STATION_ID,PERIODE,WAKTU){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_OPPENGUAPAN == true){
                var datas = await this.dummyDataOPPENGUAPAN();
                resolve(datas);
            }else{
                var thisData = await this.storeDataOPPENGUAPAN(STATION_ID,PERIODE,WAKTU);
                var datas = thisData.data;
                var dataBuild = {};
                for(var i in datas){
                    
                    dataBuild[i] = {};
                    dataBuild[i]["BANYAK_HUJAN"] = 0;
                    dataBuild[i]["HUJAN_SEBULAN"] = 0;
                    dataBuild[i]["PENGUAPAN_SEBULAN"] = 0;

                    for(var k in datas[i][0]){
                        dataBuild[i][k] = {
                            "RAW" : [], 
                            "JUMLAH" : {} , 
                            "RATA" : {}
                        }

                        dataBuild[i][k]["RAW"] = datas[i][0][k].sort(function(a, b) {
                            return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                        });
                        
                        var NO_RAW1 = 1;
                        var POS_RAW1 = 1;
                        var RAW_DATA = {};
                        RAW_DATA[k] = [];
                        for(var icount = 1; icount <= 31; icount++){
                            if(k == "RAW_1"){
                                RAW_DATA[k].push({
                                    "TGL"       : icount,
                                    "COL2"      : "", 
                                    "COL3"      : "",
                                    "COL4"      : "",
                                    "COL5"      : "",
                                });
                            }
                            if(k == "RAW_2"){
                                RAW_DATA[k].push({
                                    "TGL"       : icount,
                                    "COL12"     : "1", 
                                    "COL13"     : "1", 
                                });
                            }
                        }

                        for(var j in dataBuild[i][k]["RAW"]){
                            var infoRaw = dataBuild[i][k]["RAW"][j];
                            var dataDate = new Date(infoRaw["TGL"]);

                            if(dataBuild[i][k]["JUMLAH"][POS_RAW1] == undefined){
                                dataBuild[i][k]["JUMLAH"][POS_RAW1] = {};
                            }

                            if(k == "RAW_1"){
                                if(Number(infoRaw["COL3"]) > 0){
                                    dataBuild[i]["BANYAK_HUJAN"] = dataBuild[i]["BANYAK_HUJAN"] +1;
                                }   
                                dataBuild[i]["HUJAN_SEBULAN"] += Number(infoRaw["COL3"]);
                                dataBuild[i]["PENGUAPAN_SEBULAN"] += Number(infoRaw["COL4"]);
                            }

                            for(var m in infoRaw){
                                if( m == "TGL"){
                                    var thisDate = new Date(infoRaw[m]);
                                    dataBuild[i][k]["RAW"][j]["TGL"] = thisDate.getDate();
                                    continue;
                                } 
                                if(RAW_DATA[k][Number(dataDate.getDate())-1] != undefined ){
                                    RAW_DATA[k][Number(dataDate.getDate())-1][m] = infoRaw[m] == null ||  infoRaw[m] == "null" ? "-" : infoRaw[m];// shortData[k][m] == null || isNaN(Number(shortData[k][m]).toFixed(2))  ? "-" : Number().toFixed(2);
                                }
                                if(dataBuild[i][k]["JUMLAH"][POS_RAW1][m] == undefined){
                                    dataBuild[i][k]["JUMLAH"][POS_RAW1][m]  = 0;
                                }
                                dataBuild[i][k]["JUMLAH"][POS_RAW1][m] += Number(infoRaw[m]);
                            }
                            if(NO_RAW1 % 15 == 0){
                                POS_RAW1++;
                            }
                            NO_RAW1++;
                        }
                        dataBuild[i][k]["RAW"] = RAW_DATA[k];
                    }

                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}