import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_PSYCHROMETERASSMAN = false;
export default {
    async dummyDataPSYCHROMETERASSMAN(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01" : {
                   "07:00" :[
                        {
                        "TGL"       : "2021-01-01",
                        "COL1"      : 1, 
                        "COL2"      : 2,
                        "COL3"      : 3,
                        "COL4"      : 4,
                        "COL5"      : 5,
                        "COL6"      : 6,
                        "COL7"      : 7,
                        "COL8"      : 8,
                        "COL9"      : 9,
                        "COL10"     : 10,
                        "COL11"     : 11,
                        "COL12"     : 12,
                        "COL13"     : 13,
                        "COL14"     : 14,
                        "COL15"     : 15,
                        "COL16"     : 16,
                        "COL17"     : 17,
                        "COL18"     : 18,
                        "COL19"     : 19,
                        "COL20"     : 20,
                        "COL21"     : 21
                        },
                        {
                        "TGL"       : "2021-01-02",
                        "COL1"      : 5, 
                        "COL2"      : 2,
                        "COL3"      : 3,
                        "COL4"      : 4,
                        "COL5"      : 5,
                        "COL6"      : 6,
                        "COL7"      : 7,
                        "COL8"      : 8,
                        "COL9"      : 9,
                        "COL10"     : 10,
                        "COL11"     : 11,
                        "COL12"     : 12,
                        "COL13"     : 13,
                        "COL14"     : 14,
                        "COL15"     : 15,
                        "COL16"     : 16,
                        "COL17"     : 17,
                        "COL18"     : 18,
                        "COL19"     : 19,
                        "COL20"     : 20,
                        "COL21"     : 21
                        },
                    ],
                   "13:00" : [
                        {
                        "TGL"       : "2021-01-01",
                        "COL1"      : 1, 
                        "COL2"      : 2,
                        "COL3"      : 3,
                        "COL4"      : 4,
                        "COL5"      : 5,
                        "COL6"      : 6,
                        "COL7"      : 7,
                        "COL8"      : 8,
                        "COL9"      : 9,
                        "COL10"     : 10,
                        "COL11"     : 11,
                        "COL12"     : 12,
                        "COL13"     : 13,
                        "COL14"     : 14,
                        "COL15"     : 15,
                        "COL16"     : 16,
                        "COL17"     : 17,
                        "COL18"     : 18,
                        "COL19"     : 19,
                        "COL20"     : 20,
                        "COL21"     : 21
                        },
                        {
                        "TGL"       : "2021-01-02",
                        "COL1"      : 1, 
                        "COL2"      : 2,
                        "COL3"      : 3,
                        "COL4"      : 4,
                        "COL5"      : 5,
                        "COL6"      : 6,
                        "COL7"      : 7,
                        "COL8"      : 8,
                        "COL9"      : 9,
                        "COL10"     : 10,
                        "COL11"     : 11,
                        "COL12"     : 12,
                        "COL13"     : 13,
                        "COL14"     : 14,
                        "COL15"     : 15,
                        "COL16"     : 16,
                        "COL17"     : 17,
                        "COL18"     : 18,
                        "COL19"     : 19,
                        "COL20"     : 20,
                        "COL21"     : 21
                        },
                    ],
                    "18:00" : [
                         {
                         "TGL"       : "2021-01-01",
                         "COL1"      : 1, 
                         "COL2"      : 2,
                         "COL3"      : 3,
                         "COL4"      : 4,
                         "COL5"      : 5,
                         "COL6"      : 6,
                         "COL7"      : 7,
                         "COL8"      : 8,
                         "COL9"      : 9,
                         "COL10"     : 10,
                         "COL11"     : 11,
                         "COL12"     : 12,
                         "COL13"     : 13,
                         "COL14"     : 14,
                         "COL15"     : 15,
                         "COL16"     : 16,
                         "COL17"     : 17,
                         "COL18"     : 18,
                         "COL19"     : 19,
                         "COL20"     : 20,
                         "COL21"     : 21
                         },
                         {
                         "TGL"       : "2021-01-02",
                         "COL1"      : 1, 
                         "COL2"      : 2,
                         "COL3"      : 3,
                         "COL4"      : 4,
                         "COL5"      : 5,
                         "COL6"      : 6,
                         "COL7"      : 7,
                         "COL8"      : 8,
                         "COL9"      : 9,
                         "COL10"     : 10,
                         "COL11"     : 11,
                         "COL12"     : 12,
                         "COL13"     : 13,
                         "COL14"     : 14,
                         "COL15"     : 15,
                         "COL16"     : 16,
                         "COL17"     : 17,
                         "COL18"     : 18,
                         "COL19"     : 19,
                         "COL20"     : 20,
                         "COL21"     : 21
                         },
                    ]
               }
            }

            var dataBuild = {};
            for(var i in datas){
                dataBuild[i] = {};
                var rawPeriode = datas[i];
                for(var j in rawPeriode){
                    dataBuild[i][j] = {"RAW" : [], "CALCULATE" : {}}
                    var time = rawPeriode[j];

                    dataBuild[i][j]["RAW"] = time.sort(function(a, b) {
                        return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                    });
                    
                    var Total = {};
                    var Count = {};
                    var Rata  = {};
                    var RAW_DATA = [];
                    for(var icount = 1; icount <= 31; icount++){
                        RAW_DATA.push({
                            "TGL"       : icount,
                            "COL1"      : 1, 
                            "COL2"      : 2,
                            "COL3"      : 3,
                            "COL4"      : 4,
                            "COL5"      : 5,
                            "COL6"      : 6,
                            "COL7"      : 7,
                            "COL8"      : 8,
                            "COL9"      : 9,
                            "COL10"     : 10,
                            "COL11"     : 11,
                            "COL12"     : 12,
                            "COL13"     : 13,
                            "COL14"     : 14,
                            "COL15"     : 15,
                            "COL16"     : 16,
                            "COL17"     : 17,
                            "COL18"     : 18,
                            "COL19"     : 19,
                            "COL20"     : 20,
                            "COL21"     : 21
                        });
                    }

                    for(var indexTime in time){
                        var dataDate = new Date(time[indexTime]["TGL"]);

                        for(var m in time[indexTime]){
                            if(m == "TGL"){
                                continue;
                            }

                            if(RAW_DATA[Number(dataDate.getDate())-1] != undefined ){
                                RAW_DATA[Number(dataDate.getDate())-1][m] =   Number(time[indexTime][m]).toFixed(2);// shortData[k][m] == null || isNaN(Number(shortData[k][m]).toFixed(2))  ? "-" : Number().toFixed(2);
                            }

                            if(Total[m] == undefined ){
                                Total[m] = 0;
                                Count[m] = 0;
                            }
                            Total[m] += time[indexTime][m];
                            Count[m]++;
                            Rata[m] = Total[m] / Count[m];
                        }
                    }
                    dataBuild[i][j]["RAW"] = RAW_DATA;
                    dataBuild[i][j]["CALCULATE"] = {JUMLAH : Total, RATA : Rata};
                }
            }
            datas = dataBuild;
            resolve(datas);
        });
    },

    async storeDataPSYCHROMETERASSMAN(STATION_ID,PERIODE,WAKTU){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_PSYCHROMETERASSMAN(STATION_ID,PERIODE,WAKTU);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataPSYCHROMETERASSMAN(STATION_ID,PERIODE,WAKTU){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_PSYCHROMETERASSMAN == true){
                var datas = await this.dummyDataPSYCHROMETERASSMAN();
                resolve(datas);
            }else{
                var thisData = await this.storeDataPSYCHROMETERASSMAN(STATION_ID,PERIODE,WAKTU);
                var datas = thisData.data;
                var dataBuild = {};
                for(var i in datas){
                    dataBuild[i] = {};
                    var rawPeriode = datas[i];
                    for(var j in rawPeriode){
                        dataBuild[i][j] = {"RAW" : [], "CALCULATE" : {}}
                        var time = rawPeriode[j];

                        dataBuild[i][j]["RAW"] = time.sort(function(a, b) {
                            return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                        });
                        
                        var Total = {};
                        var Count = {};
                        var Rata  = {};
                        var RAW_DATA = [];
                        for(var icount = 1; icount <= 31; icount++){
                            RAW_DATA.push({
                                "TGL"       : icount,
                                "COL1"      : "", 
                                "COL2"      : "",
                                "COL3"      : "",
                                "COL4"      : "",
                                "COL5"      : "",
                                "COL6"      : "",
                                "COL7"      : "",
                                "COL8"      : "",
                                "COL9"      : "",
                                "COL10"     : "",
                                "COL11"     : "",
                                "COL12"     : "",
                                "COL13"     : "",
                                "COL14"     : "",
                                "COL15"     : "",
                                "COL16"     : "",
                                "COL17"     : "",
                                "COL18"     : "",
                                "COL19"     : "",
                                "COL20"     : "",
                                "COL21"     : ""
                            });
                        }

                        for(var indexTime in time){
                            var dataDate = new Date(time[indexTime]["TGL"]);
                            for(var m in time[indexTime]){
                                if(m == "TGL"){
                                    continue;
                                }

                                
                                if(RAW_DATA[Number(dataDate.getDate())-1] != undefined ){
                                    RAW_DATA[Number(dataDate.getDate())-1][m] =   Number(time[indexTime][m]).toFixed(2);// shortData[k][m] == null || isNaN(Number(shortData[k][m]).toFixed(2))  ? "-" : Number().toFixed(2);
                                }

                                
                                if(time[indexTime][m] == null || time[indexTime][m] == "null"){
                                    dataBuild[i][j]["RAW"][indexTime][m] = "-";
                                    if(RAW_DATA[Number(dataDate.getDate())-1] != undefined ){
                                        RAW_DATA[Number(dataDate.getDate())-1][m] =  "-";// shortData[k][m] == null || isNaN(Number(shortData[k][m]).toFixed(2))  ? "-" : Number().toFixed(2);
                                    }

                                    continue;
                                }

                                if(Total[m] == undefined ){
                                    Total[m] = 0;
                                    Count[m] = 0;
                                }
                                Total[m] += Number(time[indexTime][m]);
                                Count[m]++;
                                Rata[m] = (Number(Total[m]) / Number(Count[m])).toFixed(0);
                            }
                        }
                        dataBuild[i][j]["RAW"] = RAW_DATA;
                        dataBuild[i][j]["CALCULATE"] = {JUMLAH : Total, RATA : Rata};
                    }
                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}