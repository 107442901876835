<template>
  <div style="margin:25px;">
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <div v-for="(rawJam, jam) in item" :key="jam">
        <div class="title-report-PSYCHROMETERASSMAN">
          {{ dataPDF.title }}
          <div v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_name }}</div>
        </div>

        <div>
          <div style="font-size:12px;margin-top:20px;margin-bottom:20px;color:#000;">
            <div style="float:left;min-width:50%;">
              <div style="float:left;width:150px;">BULAN</div>
              <div style="float:left;width:20px;">:</div>
              <div style="float:left;min-width:200px;">{{ dataPDF.bulan }}</div>
            </div>
            <div style="float:right;min-width:50%;">
              <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_latitude }}  {{ (Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS")}}</div>
              <div style="float:right;width:20px;">:</div>
              <div style="float:right;width:120px;">LINTANG</div>
            </div>
            <div style="clear:both"></div>

            <div style="float:left;min-width:50%;">
              <div style="float:left;width:150px;">TAHUN</div>
              <div style="float:left;width:20px;">:</div>
              <div style="float:left;min-width:200px;">{{ dataPDF.tahun }}</div>
            </div>
            <div style="float:right;min-width:50%;">
              <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_longitude }} BT</div>
              <div style="float:right;width:20px;">:</div>
              <div style="float:right;width:120px;">BUJUR</div>
            </div>
            <div style="clear:both"></div>

            <div style="float:left;min-width:50%;">
              <div style="float:left;width:150px;">JAM PENGAMATAN</div>
              <div style="float:left;width:20px;">:</div>
              <div style="float:left;min-width:200px;">{{ jam }}</div>
            </div>
            <div style="float:right;min-width:50%;">
              <div style="float:right;min-width:100px;">{{ dataPDF.station[0].station_elevation }} m</div>
              <div style="float:right;width:20px;">:</div>
              <div style="float:right;width:120px;">ELEVASI</div>
            </div>
            <div style="clear:both"></div>
          </div>
        </div>

        <table class="table-report-PSYCHROMETERASSMAN" style="margin-top:10px">
          <thead>
            <tr>
              <td rowspan="2">TGL</td>
              <td colspan="3">5 CM</td>
              <td colspan="3">10 CM</td>
              <td colspan="3">20 CM</td>
              <td colspan="3">50 CM</td>
              <td colspan="3">100 CM</td>
              <td colspan="3">150 CM</td>
              <td colspan="3">200 CM</td>
            </tr>
            <tr>
              <td style="border-top:none;">BK</td>
              <td style="border-top:none;">BB</td>
              <td style="border-top:none;">RH</td>
              <td style="border-top:none;">BK</td>
              <td style="border-top:none;">BB</td>
              <td style="border-top:none;">RH</td>
              <td style="border-top:none;">BK</td>
              <td style="border-top:none;">BB</td>
              <td style="border-top:none;">RH</td>
              <td style="border-top:none;">BK</td>
              <td style="border-top:none;">BB</td>
              <td style="border-top:none;">RH</td>
              <td style="border-top:none;">BK</td>
              <td style="border-top:none;">BB</td>
              <td style="border-top:none;">RH</td>
              <td style="border-top:none;">BK</td>
              <td style="border-top:none;">BB</td>
              <td style="border-top:none;">RH</td>
              <td style="border-top:none;">BK</td>
              <td style="border-top:none;">BB</td>
              <td style="border-top:none;">RH</td>
            </tr>
            <tr>
              <td style="border-top:none;width:80px;"></td>
              <td v-for="n in 21" :key="n + '_headPSYCHROMETERASSMAN'" style="border-top:none;width:80px;">{{ n }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rawJamInfo, indexRawJam) in rawJam.RAW" :key="indexRawJam + '_bodyPSYCHROMETERASSMAN'">
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(indexRawJam + 1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL4) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL5) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL6) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL7) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL8) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL9) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL10) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL11) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL12) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL13) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL14) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL15) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL16) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL17) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL18) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL19) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL20) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL21) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJamInfo.COL22) }}</td>
            </tr>
            <tr v-for="i in 31 - rawJam.RAW.length" :key="i + '_bodyMonthPSYCHROMETERASSMAN'">
              <td style="border-top:none;">{{ i + rawJam.RAW.length }}</td>
              <td style="border-top:none;" v-for="j in 21" :key="j + 'colPSYCHROMETERASSMAN'"></td>
            </tr>
            <tr>
              <td style="border-top:none;">Rata<sup>2</sup></td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL4) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL5) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL6) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL7) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL8) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL9) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL10) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL11) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL12) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL13) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL14) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL15) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL16) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL17) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL18) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL19) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL20) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL21) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(rawJam.CALCULATE.RATA.COL22) }}</td>
            </tr>
          </tbody>
        </table>

        <div class="html2pdf__page-break" v-if="onCheckKey(item, jam) == false" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportPSYCHROMETERASSMAN",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult;
    },
    onCheckKey(item, key) {
      var LAST = "";
      for (var i in item) {
        LAST = i;
      }
      console.log(LAST);
      if (LAST == key) {
        return true;
      } else {
        return false;
      }
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.title-report-PSYCHROMETERASSMAN {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
}

.PSYCHROMETERASSMAN td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-PSYCHROMETERASSMAN td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-PSYCHROMETERASSMAN > thead > tr > td {
  text-align: center;
  background: #e0e4e7;
  color: #000000;
  border-left: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
}

.table-report-PSYCHROMETERASSMAN tbody > tr > td {
  padding: 5px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
}

.table-report-PSYCHROMETERASSMAN tbody > tr > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-PSYCHROMETERASSMAN tr {
  border: none !important;
}

.table-report-PSYCHROMETERASSMAN thead :not(.table-code) > td {
  padding: 5px;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
  border-top: solid 0.5px #000;
}

.table-report-PSYCHROMETERASSMAN thead :not(.table-code) > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-PSYCHROMETERASSMAN tbody > tr {
  background-color: #ffffff;
}

.table-report-PSYCHROMETERASSMAN tbody > tr:nth-child(even) {
  background-color: #ffffff;
}

.table-report-PSYCHROMETERASSMAN {
  font-size: 8.5px !important;
  margin-bottom: 20px !important;
}

/* .table-report-PSYCHROMETERASSMAN td{
  max-width: 90px;
  height: 30px;
} */
/* 
.table-report-PSYCHROMETERASSMAN{
  width: 100%;
} */
</style>
