import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_ACS = false;
export default {
    async dummyDataACS(){
        return  new Promise((resolve) => {
            var datas = {
                "FORM_B" :{
                    "B_VALUE" : { 
                        "TOTAL_OBS" : 0,
                        "TIME_OBS" : 0,
                        "RAW" : [
                            {
                                "COL_UTC"        : "00", 
                                "COL_LTE_200"    : 2,
                                "COL_LTE_400"    : 3,
                                "COL_LTE_600"    : 3,
                                "COL_LTE_800"    : 3,
                                "COL_LTE_1500"   : 3,
                                "COL_LTE_3000"   : 3,
                                "COL_LTE_5000"   : 3,
                                "COL_LTE_8000"   : 3,
                            }
                        ],
                        "RATA" : {
                            "COL_LTE_200"    : 2,
                            "COL_LTE_400"    : 3,
                            "COL_LTE_600"    : 3,
                            "COL_LTE_800"    : 3,
                            "COL_LTE_1500"   : 3,
                            "COL_LTE_3000"   : 3,
                            "COL_LTE_5000"   : 3,
                            "COL_LTE_8000"   : 3,
                        },
                        "JUMLAH" : {
                            "COL_LTE_200"    : 2,
                            "COL_LTE_400"    : 3,
                            "COL_LTE_600"    : 3,
                            "COL_LTE_800"    : 3,
                            "COL_LTE_1500"   : 3,
                            "COL_LTE_3000"   : 3,
                            "COL_LTE_5000"   : 3,
                            "COL_LTE_8000"   : 3,
                        },
                        "REMARKS" : ""
                    },
                    "B_PERCENT" : { 
                        "TOTAL_OBS" : 0,
                        "TIME_OBS" : 0,
                        "RAW" : [
                            {
                                "COL_UTC"        : "00", 
                                "COL_LTE_200"    : 2,
                                "COL_LTE_400"    : 3,
                                "COL_LTE_600"    : 3,
                                "COL_LTE_800"    : 3,
                                "COL_LTE_1500"   : 3,
                                "COL_LTE_3000"   : 3,
                                "COL_LTE_5000"   : 3,
                                "COL_LTE_8000"   : 3,
                            }
                        ],
                        "RATA" : {
                            "COL_LTE_200"    : 2,
                            "COL_LTE_400"    : 3,
                            "COL_LTE_600"    : 3,
                            "COL_LTE_800"    : 3,
                            "COL_LTE_1500"   : 3,
                            "COL_LTE_3000"   : 3,
                            "COL_LTE_5000"   : 3,
                            "COL_LTE_8000"   : 3,
                        },
                        "JUMLAH" : {
                            "COL_LTE_200"    : 2,
                            "COL_LTE_400"    : 3,
                            "COL_LTE_600"    : 3,
                            "COL_LTE_800"    : 3,
                            "COL_LTE_1500"   : 3,
                            "COL_LTE_3000"   : 3,
                            "COL_LTE_5000"   : 3,
                            "COL_LTE_8000"   : 3,
                        },
                        "REMARKS" : ""
                    },
                },
                "FORM_C" :{
                    "C_VALUE" : { 
                        "TOTAL_OBS" : 0,
                        "TIME_OBS" : 0,
                        "RAW"   : [
                            {
                                "COL_UTC" : "00",
                                "COL_LTE_150" : 1,
                                "COL_LTE_200" : 1,
                                "COL_LTE_300" : 1,
                                "COL_LTE_500" : 1,
                                "COL_LTE_1000": 1,
                                "COL_LTE_1500": 1
                            }
                        ],
                        "RATA" : {
                            "COL_LTE_150" : 1,
                            "COL_LTE_200" : 1,
                            "COL_LTE_300" : 1,
                            "COL_LTE_500" : 1,
                            "COL_LTE_1000": 1,
                            "COL_LTE_1500": 1
                        },
                        "JUMLAH" : {
                            "COL_LTE_150" : 1,
                            "COL_LTE_200" : 1,
                            "COL_LTE_300" : 1,
                            "COL_LTE_500" : 1,
                            "COL_LTE_1000": 1,
                            "COL_LTE_1500": 1
                        },
                        "REMARKS" : ""
                    },
                    "C_PERCENT" : { 
                        "TOTAL_OBS" : 0,
                        "TIME_OBS" : 0,
                        "RAW"   : [
                            {
                                "COL_UTC" : "00",
                                "COL_LTE_150" : 1,
                                "COL_LTE_200" : 1,
                                "COL_LTE_300" : 1,
                                "COL_LTE_500" : 1,
                                "COL_LTE_1000": 1,
                                "COL_LTE_1500": 1
                            }
                        ],
                        "RATA" : {
                            "COL_LTE_150" : 1,
                            "COL_LTE_200" : 1,
                            "COL_LTE_300" : 1,
                            "COL_LTE_500" : 1,
                            "COL_LTE_1000": 1,
                            "COL_LTE_1500": 1
                        },
                        "JUMLAH" : {
                            "COL_LTE_150" : 1,
                            "COL_LTE_200" : 1,
                            "COL_LTE_300" : 1,
                            "COL_LTE_500" : 1,
                            "COL_LTE_1000": 1,
                            "COL_LTE_1500": 1
                        },
                        "REMARKS" : ""
                    },
                },
                "FORM_D" :{
                    "D_VALUE" : { 
                        "TOTAL_OBS" : 0,
                        "TIME_OBS" : 0,
                        "RAW"   : [
                            {
                                "WIND_DIR" : "",
                                "COL_1_5" : 1,
                                "COL_6_10" : 1,
                                "COL_11_15" : 1,
                                "COL_16_20" : 1,
                                "COL_21_25": 1,
                                "COL_26_30": 1,
                                "COL_31_35": 1,
                                "COL_36_40": 1,
                                "COL_41_45": 1,
                                "COL_GTE_45": 1,
                                "COL_TOTAL": 1
                            }
                        ],
                        "RATA" : {
                            "COL_1_5" : 1,
                            "COL_6_10" : 1,
                            "COL_11_15" : 1,
                            "COL_16_20" : 1,
                            "COL_21_25": 1,
                            "COL_26_30": 1,
                            "COL_31_35": 1,
                            "COL_36_40": 1,
                            "COL_41_45": 1,
                            "COL_GTE_45": 1,
                            "COL_TOTAL": 1
                        },
                        "JUMLAH" : {
                            "COL_1_5" : 1,
                            "COL_6_10" : 1,
                            "COL_11_15" : 1,
                            "COL_16_20" : 1,
                            "COL_21_25": 1,
                            "COL_26_30": 1,
                            "COL_31_35": 1,
                            "COL_36_40": 1,
                            "COL_41_45": 1,
                            "COL_GTE_45": 1,
                            "COL_TOTAL": 1
                        },
                    }, 
                    "D_PERCENT" : { 
                        "TOTAL_OBS" : 0,
                        "TIME_OBS" : 0,
                        "RAW"   : [
                            {
                                "WIND_DIR" : "",
                                "COL_1_5" : 1,
                                "COL_6_10" : 1,
                                "COL_11_15" : 1,
                                "COL_16_20" : 1,
                                "COL_21_25": 1,
                                "COL_26_30": 1,
                                "COL_31_35": 1,
                                "COL_36_40": 1,
                                "COL_41_45": 1,
                                "COL_GTE_45": 1,
                                "COL_TOTAL": 1
                            }
                        ],
                        "RATA" : {
                            "COL_1_5" : 1,
                            "COL_6_10" : 1,
                            "COL_11_15" : 1,
                            "COL_16_20" : 1,
                            "COL_21_25": 1,
                            "COL_26_30": 1,
                            "COL_31_35": 1,
                            "COL_36_40": 1,
                            "COL_41_45": 1,
                            "COL_GTE_45": 1,
                            "COL_TOTAL": 1
                        },
                        "JUMLAH" : {
                            "COL_1_5" : 1,
                            "COL_6_10" : 1,
                            "COL_11_15" : 1,
                            "COL_16_20" : 1,
                            "COL_21_25": 1,
                            "COL_26_30": 1,
                            "COL_31_35": 1,
                            "COL_36_40": 1,
                            "COL_41_45": 1,
                            "COL_GTE_45": 1,
                            "COL_TOTAL": 1
                        },
                    },
                },
                "FORM_E" :{
                    "E_VALUE" : { 
                        "TOTAL_OBS" : 0,
                        "TIME_OBS" : 0,
                        "RAW"   : [
                            {
                                "COL_UTC" : "00",
                                "COL_5_0" : 1,
                                "COL_0_5" : 1,
                                "COL_5_10" : 1,
                                "COL_10_15" : 1,
                                "COL_15_20": 1,
                                "COL_20_25": 1,
                                "COL_25_30": 1,
                                "COL_30_35": 1,
                                "COL_GTE_35": 1,
                                "COL_TOTAL": 1
                            }
                        ],
                        "RATA" : {
                            "COL_5_0" : 1,
                            "COL_0_5" : 1,
                            "COL_5_10" : 1,
                            "COL_10_15" : 1,
                            "COL_15_20": 1,
                            "COL_20_25": 1,
                            "COL_25_30": 1,
                            "COL_30_35": 1,
                            "COL_GTE_35": 1,
                            "COL_TOTAL": 1
                        },
                        "JUMLAH" : { 
                            "COL_5_0" : 1,
                            "COL_0_5" : 1,
                            "COL_5_10" : 1,
                            "COL_10_15" : 1,
                            "COL_15_20": 1,
                            "COL_20_25": 1,
                            "COL_25_30": 1,
                            "COL_30_35": 1,
                            "COL_GTE_35": 1,
                            "COL_TOTAL": 1
                        },
                        "REMARKS" : ""
                    }, 
                    "E_PERCENT" : { 
                        "TOTAL_OBS" : 0,
                        "TIME_OBS" : 0,
                        "RAW"   : [
                            {
                                "COL_UTC" : "00",
                                "COL_5_0" : 1,
                                "COL_0_5" : 1,
                                "COL_5_10" : 1,
                                "COL_10_15" : 1,
                                "COL_15_20": 1,
                                "COL_20_25": 1,
                                "COL_25_30": 1,
                                "COL_30_35": 1,
                                "COL_GTE_35": 1,
                                "COL_TOTAL": 1
                            }
                        ],
                        "RATA" : {
                            "COL_5_0" : 1,
                            "COL_0_5" : 1,
                            "COL_5_10" : 1,
                            "COL_10_15" : 1,
                            "COL_15_20": 1,
                            "COL_20_25": 1,
                            "COL_25_30": 1,
                            "COL_30_35": 1,
                            "COL_GTE_35": 1,
                            "COL_TOTAL": 1
                        },
                        "JUMLAH" : { 
                            "COL_5_0" : 1,
                            "COL_0_5" : 1,
                            "COL_5_10" : 1,
                            "COL_10_15" : 1,
                            "COL_15_20": 1,
                            "COL_20_25": 1,
                            "COL_25_30": 1,
                            "COL_30_35": 1,
                            "COL_GTE_35": 1,
                            "COL_TOTAL": 1
                        },
                        "REMARKS" : ""
                    },
                }
            }

            var dataBuild = {}
            for(var iForm in datas){
                dataBuild[iForm] = {};
                for(var iVal in datas[iForm]){
                    dataBuild[iForm][iVal] = {};
                    var RAW = [];
                    for(var iRaw in datas[iForm][iVal]){
                        dataBuild[iForm][iVal][iRaw] = datas[iForm][iVal][iRaw];

                        if(iRaw == "RAW"){
                            dataBuild[iForm][iVal][iRaw]  = {};
                            for(var keyRaw in datas[iForm][iVal][iRaw]){
                                if(datas[iForm][iVal][iRaw][keyRaw]["COL_UTC"] != undefined){
                                    dataBuild[iForm][iVal][iRaw][datas[iForm][iVal][iRaw][keyRaw]["COL_UTC"]] = datas[iForm][iVal][iRaw][keyRaw];
                                }
                                
                                if(datas[iForm][iVal][iRaw][keyRaw]["WIND_DIR"] != undefined){
                                    dataBuild[iForm][iVal][iRaw][datas[iForm][iVal][iRaw][keyRaw]["WIND_DIR"]] = datas[iForm][iVal][iRaw][keyRaw];
                                }
                            }
                        }
                    }
                }
            }
            datas = dataBuild;
            resolve(datas);
        });
    },

    async storeDataACS(STATION_ID,MONTH,START_YEARS,END_YEARS){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_ACS(STATION_ID,MONTH,START_YEARS,END_YEARS);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataACS(STATION_ID,MONTH,START_YEARS,END_YEARS){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_ACS == true){
                var datas = await this.dummyDataACS();
                resolve(datas);
            }else{
                var thisData = await this.storeDataACS(STATION_ID,MONTH,START_YEARS,END_YEARS);
                var datas = thisData.data;
                var dataBuild = {}
                for(var iForm in datas){
                    dataBuild[iForm] = {};
                    for(var iVal in datas[iForm]){
                        dataBuild[iForm][iVal] = {};
                        var RAW = [];
                        for(var iRaw in datas[iForm][iVal]){
                            dataBuild[iForm][iVal][iRaw] = datas[iForm][iVal][iRaw];

                            if(iRaw == "RAW"){
                                dataBuild[iForm][iVal][iRaw]  = {};
                                for(var keyRaw in datas[iForm][iVal][iRaw]){
                                    if(datas[iForm][iVal][iRaw][keyRaw]["COL_UTC"] != undefined){
                                        dataBuild[iForm][iVal][iRaw][datas[iForm][iVal][iRaw][keyRaw]["COL_UTC"]] = datas[iForm][iVal][iRaw][keyRaw];
                                    }
                                    
                                    if(datas[iForm][iVal][iRaw][keyRaw]["WIND_DIR"] != undefined){
                                        dataBuild[iForm][iVal][iRaw][datas[iForm][iVal][iRaw][keyRaw]["WIND_DIR"]] = datas[iForm][iVal][iRaw][keyRaw];
                                    }
                                }
                            }
                        }
                    }
                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}