import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_ME_45 = false;
export default {
    async dummyDataME_45(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01-01" :[
                    {
                        "GMT" : "20",
                        "TDTDTD" : "255",
                        "N" : "8",
                        "DD" : "00",
                        "FF" : "00",
                        "VV" : "59",
                        "WW" : "",
                        "W1W2" : "",
                        "QFF" : "0094",
                        "TTTTTT" : "260",
                        "NH" : "2",
                        "CL" : "5",
                        "H" : "5",
                        "CM" : "7",
                        "CH" : "||",
                        "NS" : "2",
                        "C" : "6",
                        "HSHS" : "23",
                        "NS_1" : "6",
                        "C_1" : "4",
                        "HSHS_1" : "6",
                        "O" : "",
                        "C_2" : "",
                        "HSHS_2" : "",
                        "CDAEC" : "",
                        "UU" : "97",
                        "QFE" : "0064",
                        "TWTWTW" : "256",
                        "RRR" : "993",
                        "TR" : "4",
                        "TXTXTX" : "",
                        "TNTNTN" : "253",
                        "EEE" : "036",
                        "F24F24F24F24" : "0598",
                        "SSS" : "009",
                        "E" : "1",
                        "DLDMDH" : "009",
                        "P24P24P24" : "512",
                        "IW" : "4",
                        "IX" : "2",
                        "IR" : "1",
                        "IE" : "1",
                    },
                    {
                        "GMT" : "20",
                        "TDTDTD" : "255",
                        "N" : "8",
                        "DD" : "00",
                        "FF" : "00",
                        "VV" : "59",
                        "WW" : "",
                        "W1W2" : "",
                        "QFF" : "0094",
                        "TTTTTT" : "260",
                        "NH" : "2",
                        "CL" : "5",
                        "H" : "5",
                        "CM" : "7",
                        "CH" : "||",
                        "NS" : "2",
                        "C" : "6",
                        "HSHS" : "23",
                        "NS_1" : "6",
                        "C_1" : "4",
                        "HSHS_1" : "6",
                        "O" : "",
                        "C_2" : "",
                        "HSHS_2" : "",
                        "CDAEC" : "",
                        "UU" : "97",
                        "QFE" : "0064",
                        "TWTWTW" : "256",
                        "RRR" : "993",
                        "TR" : "4",
                        "TXTXTX" : "",
                        "TNTNTN" : "253",
                        "EEE" : "036",
                        "F24F24F24F24" : "0598",
                        "SSS" : "009",
                        "E" : "1",
                        "DLDMDH" : "009",
                        "P24P24P24" : "512",
                        "IW" : "4",
                        "IX" : "2",
                        "IR" : "1",
                        "IE" : "1",
                    },
                    {
                        "GMT" : "20",
                        "TDTDTD" : "255",
                        "N" : "8",
                        "DD" : "00",
                        "FF" : "00",
                        "VV" : "59",
                        "WW" : "",
                        "W1W2" : "",
                        "QFF" : "0094",
                        "TTTTTT" : "260",
                        "NH" : "2",
                        "CL" : "5",
                        "H" : "5",
                        "CM" : "7",
                        "CH" : "||",
                        "NS" : "2",
                        "C" : "6",
                        "HSHS" : "23",
                        "NS_1" : "6",
                        "C_1" : "4",
                        "HSHS_1" : "6",
                        "O" : "",
                        "C_2" : "",
                        "HSHS_2" : "",
                        "CDAEC" : "",
                        "UU" : "97",
                        "QFE" : "0064",
                        "TWTWTW" : "256",
                        "RRR" : "993",
                        "TR" : "4",
                        "TXTXTX" : "",
                        "TNTNTN" : "253",
                        "EEE" : "036",
                        "F24F24F24F24" : "0598",
                        "SSS" : "009",
                        "E" : "1",
                        "DLDMDH" : "009",
                        "P24P24P24" : "512",
                        "IW" : "4",
                        "IX" : "2",
                        "IR" : "1",
                        "IE" : "1",
                    },
                    {
                        "GMT" : "20",
                        "TDTDTD" : "255",
                        "N" : "8",
                        "DD" : "00",
                        "FF" : "00",
                        "VV" : "59",
                        "WW" : "",
                        "W1W2" : "",
                        "QFF" : "0094",
                        "TTTTTT" : "260",
                        "NH" : "2",
                        "CL" : "5",
                        "H" : "5",
                        "CM" : "7",
                        "CH" : "||",
                        "NS" : "2",
                        "C" : "6",
                        "HSHS" : "23",
                        "NS_1" : "6",
                        "C_1" : "4",
                        "HSHS_1" : "6",
                        "O" : "",
                        "C_2" : "",
                        "HSHS_2" : "",
                        "CDAEC" : "",
                        "UU" : "97",
                        "QFE" : "0064",
                        "TWTWTW" : "256",
                        "RRR" : "993",
                        "TR" : "4",
                        "TXTXTX" : "",
                        "TNTNTN" : "253",
                        "EEE" : "036",
                        "F24F24F24F24" : "0598",
                        "SSS" : "009",
                        "E" : "1",
                        "DLDMDH" : "009",
                        "P24P24P24" : "512",
                        "IW" : "4",
                        "IX" : "2",
                        "IR" : "1",
                        "IE" : "1",
                    }
                ]
            }
            var no=0;
            
            var dataResult = {};
            for(var i in datas){
                dataResult[i] = {label : "" , raw :[], indexNumber :  no};
                var newDate = new Date(i);
                var day = "00".substring(newDate.getDate().toString().length)+newDate.getDate();
                var month = DataHelper.getMonthCode(newDate.getMonth());
                var years = newDate.getFullYear().toString().substring(2);
                
                for(var j in datas[i]){
                    var rawData = datas[i][j];
                    var data = {};
                    for(var k in rawData){
                        var NoSplit = ["CH"];
                        data[k] = NoSplit.indexOf(k) == -1 ?  rawData[k].toString().split('') : [rawData[k]];
                    }
                    dataResult[i]["raw"].push(data);
                }

                var label = (day+month+years).split('');

                dataResult[i]["label"] = label;//{label :label, raw : dataPeriode};
                no++;
            }
            datas = dataResult;
            resolve(datas);
        });
    },

    async storeDataME_45(WMO_ID,START_DATE,END_DATE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_45(WMO_ID,START_DATE,END_DATE);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataME_45(WMO_ID,START_DATE,END_DATE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_ME_45 == true){
                var datas = await this.dummyDataME_45();
                resolve(datas);
            }else{
                var thisData = await this.storeDataME_45(WMO_ID,START_DATE,END_DATE);
                var datas = thisData.data;
            
                var dataResult = {};
                for(var i in datas){
                    var no=0;
                    var NoOfData = 0;
                    var DataNext = 0;
                    var newDate = new Date(i);
                    var day = "00".substring(newDate.getDate().toString().length)+newDate.getDate();
                    var month = DataHelper.getMonthCode(newDate.getMonth());
                    var years = newDate.getFullYear().toString().substring(2);
                
                    var label = (day+month+years).split('');
                    dataResult[i] = {};//{label : "" , raw :[], indexNumber :  no};//{};
                    dataResult[i][DataNext] = {label : label , raw :[], indexNumber :  no};

                    for(var j in datas[i]){
                        NoOfData++;
                        if(NoOfData % 42 == 0){
                            DataNext++;
                            dataResult[i][DataNext] = {label : label , raw :[], indexNumber :  no};
                        }
                        
                        var rawData = datas[i][j];
                        var data = {};
                        for(var k in rawData){
                            var NoSplit = ["CH"];
                            if(k == "O"){
                                rawData[k] = "0";
                            }   
                            data[k] = NoSplit.indexOf(k) == -1 ?  (rawData[k] == null ? [] : Number(rawData[k]).toFixed(1).toString().split(".").join("").toString().split('')) : [rawData[k]];
                        }
                        dataResult[i][DataNext]["raw"].push(data);
                    }
                    var label = (day+month+years).split('');
                    // dataResult[i][DataNext]["label"] = label;//{label :label, raw : dataPeriode};
                    no++;
                    
                }
                console.log(dataResult);
                datas = dataResult;
                resolve(datas);
            }
        });
    }
}