import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_FKIKLIM71 = false;
export default {
    async dummyDataFKIKLIM71(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01" /*tanggal periode data*/  : {
                   "PENGAMAT" : "Mario",
                   "PARAF"  : "",
                   "RAW_1" :[
                        {
                        "TGL"   : "2021-01",
                        "COL1"  : 1, 
                        "COL2"  : 2,
                        "COL3"  : 3,
                        "COL4"  : 4,
                        "COL5"  : 5,
                        "COL6"  : 6,
                        "COL7"  : 7,
                        "COL8"  : 8,
                        "COL9"  : 9
                        },
                        {
                            "TGL"   : "2021-03",
                            "COL1"  : 1, 
                            "COL2"  : 2,
                            "COL3"  : 3,
                            "COL4"  : 4,
                            "COL5"  : 5,
                            "COL6"  : 6,
                            "COL7"  : 7,
                            "COL8"  : 8,
                            "COL9"  : 9
                        },
                        {
                            "TGL"   : "2021-02",
                            "COL1"  : 1, 
                            "COL2"  : 2,
                            "COL3"  : 3,
                            "COL4"  : 4,
                            "COL5"  : 5,
                            "COL6"  : 6,
                            "COL7"  : 7,
                            "COL8"  : 8,
                            "COL9"  : 9
                        },
                    ],
                   "RAW_2" : [
                        {
                            "TGL"       : "2021-01",
                            "COL10"     : 1,
                            "COL11"     : 2,
                            "COL12"     : 3,
                            "COL13"     : 4,
                            "COL14"     : 5,
                            "COL15"     : 6,
                            "COL16"     : 7,
                            "COL17"     : 8,
                            "COL18"     : 8
                        },
                        {
                            "TGL"       : "2021-10",
                            "COL10"     : 1,
                            "COL11"     : 2,
                            "COL12"     : 3,
                            "COL13"     : 4,
                            "COL14"     : 5,
                            "COL15"     : 6,
                            "COL16"     : 7,
                            "COL17"     : 8,
                            "COL18"     : 8
                        },
                        {
                            "TGL"       : "2021-02",
                            "COL10"     : 1,
                            "COL11"     : 2,
                            "COL12"     : 3,
                            "COL13"     : 4,
                            "COL14"     : 5,
                            "COL15"     : 6,
                            "COL16"     : 7,
                            "COL17"     : 8,
                            "COL18"     : 8
                        },
                    ]
               }
            }

            var dataBuild = {};
            for(var i in datas){
                dataBuild[i] = {};
                var rawPeriode = datas[i];
   
                for(var j in rawPeriode){
                    if(["PENGAMAT","PARAF"].indexOf(j) != -1){
                        dataBuild[i][j] = rawPeriode[j];
                        continue;
                    }
                    dataBuild[i][j] = rawPeriode[j].sort(function(a, b) {
                        return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                    });
                    var infoDara = rawPeriode[j];
                    var Total = {};
                    var Count = {};
                    var Rata  = {};
                    var HariHujan = 0;

                    var RAW_DATA = {};
                    RAW_DATA[j] = [];
                    for(var icount = 1; icount <= 31; icount++){
                        if(j == "RAW_1"){
                            RAW_DATA[j].push({
                                "TGL"    : "00"+icount.toString().substring(icount.length,2),
                                "COL1"   : "", 
                                "COL2"   : "",
                                "COL3"   : "",
                                "COL4"   : "",
                                "COL5"   : "",
                                "COL6"   : "",
                                "COL7"   : "",
                                "COL8"   : "",
                                "COL9"   : ""
                            }); 
                        }  
                        if(j == "RAW_2"){
                            RAW_DATA[j].push({
                                "TGL"    : "00"+icount.toString().substring(icount.length,2),
                                "COL10"  : "",
                                "COL11"  : "",
                                "COL12"  : "",
                                "COL13"  : "",
                                "COL14"  : "",
                                "COL15"  : "",
                                "COL16"  : "",
                                "COL17"  : "",
                                "COL18"  : ""
                            }); 
                        }
                    }


                    for(var k in infoDara){
                        var infoDara = rawPeriode[k];
                        var newDate = new Date(infoDara.TGL);

                        for(var m in infoDara[k]){
                            if(RAW_DATA[j][Number(newDate.getDate())] != undefined ){
                                RAW_DATA[j][Number(newDate.getDate())][m] = infoDara[k][m];
                            }

                            if(m == "TGL"){
                                continue;
                            }
                            if(Total[m] == undefined ){
                                Total[m] = 0;
                                Count[m] = 0;
                            }
                            Total[m] += infoDara[k][m];
                            Count[m]++;
                            Rata[m] = Total[m] / Count[m];
                            if(m == "COL7"){
                                if(infoDara[k][m] >= 1){
                                    HariHujan++;
                                }
                            }
                        }
                    }
                    dataBuild[i][j] = RAW_DATA[j];
                    dataBuild[i]["TOTAL_"+j] = {JUMLAH : Total, RATA : Rata};
                    if(j == "RAW_1"){
                        dataBuild[i]["TOTAL_"+j]["HARI_HUJAN"] = HariHujan;
                    }
                }
            }
            datas = dataBuild;
            resolve(datas);
        });
    },

    async storeDataFKIKLIM71(STATION_ID,PERIODE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_FKIKLIM71(STATION_ID,PERIODE);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataFKIKLIM71(STATION_ID,PERIODE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_FKIKLIM71 == true){
                var datas = await this.dummyDataFKIKLIM71();
                resolve(datas);
            }else{
                var thisData = await this.storeDataFKIKLIM71(STATION_ID,PERIODE);
                var datas = thisData.data;
                var dataBuild = {};
                for(var i in datas){
                    dataBuild[i] = {};
                    var rawPeriode = datas[i];
    
                    for(var j in rawPeriode){
                        if(["PENGAMAT","PARAF"].indexOf(j) != -1){
                            dataBuild[i][j] = rawPeriode[j];
                            continue;
                        }
                        dataBuild[i][j] = rawPeriode[j].sort(function(a, b) {
                            return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                        });
                        var infoDara = rawPeriode[j];
                        var Total = {};
                        var Count = {};
                        var Rata  = {};
                        var HariHujan = 0;

                        var RAW_DATA = {};
                        RAW_DATA[j] = [];
                        for(var icount = 1; icount <= 31; icount++){
                            if(j == "RAW_1"){
                                RAW_DATA[j].push({
                                    "TGL"    : "00"+icount.toString().substring(icount.length,2),
                                    "COL1"   : "", 
                                    "COL2"   : "",
                                    "COL3"   : "",
                                    "COL4"   : "",
                                    "COL5"   : "",
                                    "COL6"   : "",
                                    "COL7"   : "",
                                    "COL8"   : "",
                                    "COL9"   : ""
                                }); 
                            }  

                            if(j == "RAW_2"){
                                RAW_DATA[j].push({
                                    "TGL"    : "00"+icount.toString().substring(icount.length,2),
                                    "COL10"  : "",
                                    "COL11"  : "",
                                    "COL12"  : "",
                                    "COL13"  : "",
                                    "COL14"  : "",
                                    "COL15"  : "",
                                    "COL16"  : "",
                                    "COL17"  : "",
                                    "COL18"  : ""
                                }); 
                            }
                        }

                        for(var k in infoDara){
                            var thisInfoDara = infoDara[k];
                            var newDate = new Date(thisInfoDara.TGL);

                            for(var m in infoDara[k]){

                                if(RAW_DATA[j][Number(newDate.getDate())-1] != undefined ){
                                    RAW_DATA[j][Number(newDate.getDate())-1][m] = infoDara[k][m] == null || infoDara[k][m] == "null" ? "" : infoDara[k][m];
                                }

                                if(m == "TGL"){
                                    continue;
                                }
                                if(Total[m] == undefined ){
                                    Total[m] = 0;
                                    Count[m] = 0;
                                }
                                if(infoDara[k][m] != null && infoDara[k][m] !="null"){
                                    Total[m] += Number(infoDara[k][m]);
                                    Count[m]++;
                                    Rata[m] = (Total[m] / Count[m]).toFixed(1);
                                    Total[m] = Number(Total[m].toFixed(1))
                                    if(m == "COL7"){
                                        if(infoDara[k][m] >= 1){
                                            HariHujan++;
                                        }
                                    }
                                }else{
                                    dataBuild[i][j][k][m] = "-";
                                }
                            }
                        }
                        dataBuild[i][j] = RAW_DATA[j];
                        dataBuild[i]["TOTAL_"+j] = {JUMLAH : Total, RATA : Rata};
                        if(j == "RAW_1"){
                            dataBuild[i]["TOTAL_"+j]["HARI_HUJAN"] = HariHujan;
                        }
                    }
                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}