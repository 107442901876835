import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_LYSIMETER = false;
export default {
    async dummyDataLYSIMETER(){
        return new Promise((resolve) => {
            var datas = {
                "2021-01" : [{
                    "PENAMPANG_LYSIMETER" : "penampang #1", 
			        "RAW_JAM" :  {
                        "07:00" :[
                                {
                                "TGL"       : "2021-01-01",
                                "COL1"      : 1, 
                                "COL2"      : 2,
                                "COL3"      : 3,
                                "COL4"      : 4,
                                "COL5"      : 5,
                                },
                                {
                                "TGL"       : "2021-01-02",
                                "COL1"      : 5, 
                                "COL2"      : 2,
                                "COL3"      : 3,
                                "COL4"      : 4,
                                "COL5"      : 5,
                                },
                            ],
                        "13:00" : [
                                {
                                "TGL"       : "2021-01-01",
                                "COL1"      : 1, 
                                "COL2"      : 2,
                                "COL3"      : 3,
                                "COL4"      : 4,
                                "COL5"      : 5,
                                },
                                {
                                "TGL"       : "2021-01-02",
                                "COL1"      : 1, 
                                "COL2"      : 2,
                                "COL3"      : 3,
                                "COL4"      : 4,
                                "COL5"      : 5,
                                },
                            ],
                        "18:00" : [
                                {
                                "TGL"       : "2021-01-01",
                                "COL1"      : 1, 
                                "COL2"      : 2,
                                "COL3"      : 3,
                                "COL4"      : 4,
                                "COL5"      : 5,
                                },
                                {
                                "TGL"       : "2021-01-02",
                                "COL1"      : 1, 
                                "COL2"      : 2,
                                "COL3"      : 3,
                                "COL4"      : 4,
                                "COL5"      : 5,
                                },
                            ]
                    }
                }]
            }

            var dataBuild = {};
            for(var i in datas){
                dataBuild[i] = [];
                for(var n in datas[i]){
                    var data                    = {};
                    data["RAW_JAM"]             = {};
                    data["PENAMPANG_LYSIMETER"] = datas[i][n]["PENAMPANG_LYSIMETER"];
                    for(var j in  datas[i][n]["RAW_JAM"]){
                        data["RAW_JAM"][j] = {};
                        data["RAW_JAM"][j]["RAW"] = [];
                        data["RAW_JAM"][j]["CALCULATE"] = {};

                        var shortData = datas[i][n]["RAW_JAM"][j].sort(function(a, b) {
                            return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                        });

                        var Total = {};
                        var Count = {};
                        var Rata  = {};


                        for(var k in shortData){
                            var keyConcat = {};
                            for(var m in shortData[k]){
                                keyConcat[m] = shortData[k][m] == null ? "" : Number(shortData[k][m]).toFixed(2);
                                if(m == "TGL" || m == "COL6"){
                                    continue;
                                }

                                if(Total[m] == undefined ){
                                    Total[m] = 0;
                                    Count[m] = 0;
                                }
                                Total[m] += shortData[k][m] == null ? 0 : Number(Number(shortData[k][m]).toFixed(2));
                                Count[m]++;
                                Rata[m] = Total[m] / Count[m];
                            }
                            data["RAW_JAM"][j]["RAW"].push(keyConcat);
                        }
                        data["RAW_JAM"][j]["CALCULATE"] = {JUMLAH : Total, RATA : Rata};
                    }
                    dataBuild[i].push(data);
                }
            }
            datas = dataBuild;
            resolve(datas);
        });
    },

    async storeDataLYSIMETER(STATION_ID,PERIODE,WAKTU,TANAH){
        return new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_LYSIMETER(STATION_ID,PERIODE,WAKTU,TANAH);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataLYSIMETER(STATION_ID,PERIODE,WAKTU,TANAH){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_LYSIMETER == true){
                var datas = await this.dummyDataLYSIMETER();
                resolve(datas);
            }else{
                var thisData = await this.storeDataLYSIMETER(STATION_ID,PERIODE,WAKTU,TANAH);
                var datas = thisData.data;
                var dataBuild = {};
                for(var i in datas){
                    dataBuild[i] = [];
                    for(var n in datas[i]){
                        var data                    = {};
                        data["RAW_JAM"]             = {};
                        data["PENAMPANG_LYSIMETER"] = datas[i][n]["PENAMPANG_LYSIMETER"];
                        for(var j in  datas[i][n]["RAW_JAM"]){
                            data["RAW_JAM"][j] = {};
                            data["RAW_JAM"][j]["RAW"] = [];
                            data["RAW_JAM"][j]["CALCULATE"] = {};
                            console.log(data);

                            var shortData = datas[i][n]["RAW_JAM"][j].sort(function(a, b) {
                                return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                            });

                            var Total = {};
                            var Count = {};
                            var Rata  = {};

                            var RAW_DATA = [];
                            for(var icount = 1; icount <= 31; icount++){
                                RAW_DATA.push({
                                    "TGL"       : icount,
                                    "COL2"      : "", 
                                    "COL3"      : "",
                                    "COL4"      : "",
                                    "COL5"      : "",
                                    "COL6"      : "",
                                });
                            }
                            
                            for(var k in shortData){
                                var keyConcat = {};
                                var dataDate = new Date(shortData[k]["TGL"]);
                                for(var m in shortData[k]){
                                    keyConcat[m] = shortData[k][m] == null || isNaN(Number(shortData[k][m]).toFixed(2))  ? "" : Number(shortData[k][m]).toFixed(2);
                                    
                                    if(m == "TGL" || m == "COL6"){
                                        continue;
                                    }
    
                                    if(RAW_DATA[Number(dataDate.getDate())-1] != undefined ){
                                        var TEXT = ["COL6"];
                                        RAW_DATA[Number(dataDate.getDate())-1][m] =  shortData[k][m] == null ||  shortData[k][m] == "null" ? "-" : (TEXT.indexOf(m) == -1 ? Number(shortData[k][m]).toFixed(1) : shortData[k][m] );// shortData[k][m] == null || isNaN(Number(shortData[k][m]).toFixed(2))  ? "-" : Number().toFixed(2);
                                    }

                                    if(Total[m] == undefined ){
                                        Total[m] = 0;
                                        Count[m] = 0;
                                    }
                                    Total[m] += shortData[k][m] == null || isNaN(shortData[k][m] ) ? 0 : Number(Number(shortData[k][m]).toFixed(2));
                                    Count[m]++;
                                    Rata[m] = Number(Total[m] / Count[m]).toFixed(2);
                                }
                                // data["RAW_JAM"][j]["RAW"].push(keyConcat);
                            }
                            data["RAW_JAM"][j]["RAW"] = RAW_DATA;
                            data["RAW_JAM"][j]["CALCULATE"] = {JUMLAH : Total, RATA : Rata};
                        }
                        dataBuild[i].push(data);
                    }
                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}