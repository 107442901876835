import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_DATA_STATION = false;
export default {
    async dummyDataStation(){
        return  new Promise((resolve) => {
            var datas = [
                {"station_id" : "1" , "station_name" : "Stasiun Meteorologi Maimun Saleh"},
                {"station_id" : "10" , "station_name" : "Stasiun Geofisika Deli Serdang"},
                {"station_id" : "100" , "station_name" : "Stasiun Meteorologi Mutiara Sis-Al Jufri"},
                {"station_id" : "101" , "station_name" : "Stasiun Geofisika Palu"},
            ]
            resolve(datas);
        });
    },

    async storeDataStation(PARAM){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_STATION_API(PARAM);
            var data = axios.get(url, Helper.getConfig());
            resolve(data);
        });
    },

    async callDataStation(PARAM){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_DATA_STATION == true){
                var datas = await this.dummyDataStation();
                resolve(datas);
            }else{
                var datas = await this.storeDataStation(PARAM);
                resolve(datas.data);
            }
        });
    },

    async storeDataStationFilter(PARAM){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_FILTER_STATION_API(PARAM);
            var data = axios.get(url, Helper.getConfig());
            resolve(data);
        });
    },

    async callDataStationFilter(PARAM){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_DATA_STATION == true){
                var datas = await this.dummyDataStation();
                resolve(datas);
            }else{
                var datas = await this.storeDataStationFilter(PARAM);
                resolve(datas.data);
            }
        });
    },

    async storeDataStationAll(PARAM){
        var BmkgEntry = {
            "FKIKLIM71":"is_fklim",
            "IKLIM_MIKRO":"is_iklim_mikro",
            "AGM_IA":"is_agm1a",
            "AGM_IB":"is_agm1b",
            "LYSIMETER":"is_lysimeter",
            "GUNBELLANI":"is_gunbellani",
            "OP_PENGUAPAN":"is_op_penguapan",
            "PICHE_PENGUAPAN":"is_piche_penguapan",
            "PSYCHROMETER_ASSMAN":"is_psychrometer_assman",
            "SUHU_TANAH":"is_suhu_tanah",
            "AKTINOGRAPH":"is_aktinograph",
            "BAROGRAPH":"is_barograph",
            "FENOLOGI":"is_fenologi",
            "FORM_HUJAN":"is_form_hujan",
            "PERAWANAN":"is_perawanan",
            "HUJAN_HELMAN":"is_hujan_helman",
            "KELEMBAPAN_TANAH":"is_kelembapan_tanah",
            "LAMA_PENYINARAN":"is_lama_penyinaran",
            "THERMOHIGOGRAPH":"is_thermohigograph",
            "METAR_REPORT":"is_metar_speci",
            "PIBAL":"is_pibal",
            "CLIMAT":"is_me48",
            "WXREV":"is_me48",
            "ACS":"is_me48",
            "ME_45":"is_me48",
            "ME_48":"is_me48",
            "KIMIA_AIR_HUJAN" : "is_kimia_air_hujan",
            "KIMIA_AIR_HUJAN_NO_WMO" : "is_kimia_air_hujan",
            "SPM":"is_spm",
            "Aerosol":"is_aerosol",
            "SO2NO2":"is_so2_no2",
            "EANET": "is_kimia_air_hujan"
        }
        var params = "&"+BmkgEntry[PARAM]+"=1"

        return  new Promise((resolve) => {
            var url = DataHelper.URL_STATION_ALL_API(params);
            var data = axios.get(url, Helper.getConfig());
            resolve(data);
        });
    },

    async callDataStationALL(PARAM){
        return  new Promise(async (resolve) => {
            var datas = await this.storeDataStationAll(PARAM);
            resolve(datas.data);
        });
    },

}