import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_GUNBELLANI = false;
export default {
    async dummyDataGUNBELLANI(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01": [
                    {
                    "TGL"    : "2021-01",
                    "COL1"   : 1, 
                    "COL2"   : 2,
                    "COL3"   : 3,
                    "COL4"   : 4,
                    "COL5"   : 5,
                    },
                    {
                    "TGL"    : "2021-03",
                    "COL1"   : 3, 
                    "COL2"   : 2,
                    "COL3"   : 3,
                    "COL4"   : 4,
                    "COL5"   : 5,
                    },
                    {
                    "TGL"    : "2021-02",
                    "COL1"   : 2, 
                    "COL2"   : 2,
                    "COL3"   : 3,
                    "COL4"   : 4,
                    "COL5"   : 5,
                    },
                ]
            }

            var dataBuild = {};
            for(var i in datas){
                dataBuild[i] = {"RAW" : [], "CALCULATE" : {} };
                var rawPeriode = datas[i];
                dataBuild[i]["RAW"] = rawPeriode.sort(function(a, b) {
                    return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                });
                
                var Total = {};
                var Count = {};
                var Rata  = {};
                for(var j in rawPeriode){
                    var infoDara = rawPeriode[j];
                    for(var m in infoDara){
                        if(m == "TGL"){
                            continue;
                        }
                        if(Total[m] == undefined ){
                            Total[m] = 0;
                            Count[m] = 0;
                        }
                        Total[m] += infoDara[m];
                        Count[m]++;
                        Rata[m] = Total[m] / Count[m];
                    }
                }
                dataBuild[i]["CALCULATE"] = {JUMLAH : Total, RATA : Rata};
            }
            datas = dataBuild;
            resolve(datas);
        });
    },

    async storeDataGUNBELLANI(STATION_ID,PERIODE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_GUNBELLANI(STATION_ID,PERIODE);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataGUNBELLANI(STATION_ID,PERIODE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_GUNBELLANI == true){
                var datas = await this.dummyDataGUNBELLANI();
                resolve(datas);
            }else{
                var thisData = await this.storeDataGUNBELLANI(STATION_ID,PERIODE);
                var datas = thisData.data;
                    
                var dataBuild = {};
                for(var i in datas){
                    dataBuild[i] = {"RAW" : [], "CALCULATE" : {} };
                    var rawPeriode = datas[i];
                    dataBuild[i]["RAW"] = rawPeriode.sort(function(a, b) {
                        return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                    });
                    
                    var Total = {};
                    var Count = {};
                    var Rata  = {};

                    var RAW_DATA = [];
                    for(var icount = 1; icount <= 31; icount++){
                        RAW_DATA.push({
                            "TGL"    : "00"+icount.toString().substring(icount.length,2),
                            "COL1"   : "", 
                            "COL2"   : "",
                            "COL3"   : "",
                            "COL4"   : "",
                            "COL5"   : ""
                        });
                    }

                    for(var j in rawPeriode){
                        var infoDara = rawPeriode[j];
                        var newDate = new Date(infoDara.TGL);


                        for(var m in infoDara){
                            if(RAW_DATA[Number(newDate.getDate())-1] != undefined ){
                                RAW_DATA[Number(newDate.getDate())-1][m] = infoDara[m];
                            }

                            if(m == "TGL"){
                                continue;
                            }
                            if(Total[m] == undefined ){
                                Total[m] = 0;
                                Count[m] = 0;
                            }
                            
                            if(infoDara[m] != null && infoDara[m] !="null"){
                                Total[m] += Number(infoDara[m]);
                                Count[m]++;
                                Rata[m] = Number(Total[m] / Count[m]).toFixed(1);
                                Total[m] = Number(Total[m].toFixed(1));
                            }else{
                                dataBuild[i]["RAW"][j][m] = "-";
                            }
                        }
                    }
                    dataBuild[i]["RAW"] = RAW_DATA;
                    dataBuild[i]["CALCULATE"] = {JUMLAH : Total, RATA : Rata};
                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}