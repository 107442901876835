<template>
  <div style="margin:10px;">
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <div slot="pdf-content">
        <div class="title-report-IKLIMMIKRO">
          {{ dataPDF.title }}
          <div v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_name }}</div>
        </div>
        <div>
          <div style="width:1300px;color:#000;">
            <div style="font-size:12px;margin-top:10px;margin-bottom:10px;">
              <div style="float:left;min-width:50%;">
                <div style="float:left;width:120px;">BULAN</div>
                <div style="float:left;width:20px;">:</div>
                <div style="float:left;min-width:200px;">{{ dataPDF.bulan }}</div>
              </div>
              <div style="float:right;min-width:50%;">
                <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_latitude }} {{ Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS" }}</div>
                <div style="float:right;width:20px;">:</div>
                <div style="float:right;width:120px;">LINTANG</div>
              </div>
              <div style="clear:both"></div>

              <div style="float:left;min-width:50%;">
                <div style="float:left;width:120px;">TAHUN</div>
                <div style="float:left;width:20px;">:</div>
                <div style="float:left;min-width:200px;">{{ dataPDF.tahun }}</div>
              </div>
              <div style="float:right;min-width:50%;">
                <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_longitude }} BT</div>
                <div style="float:right;width:20px;">:</div>
                <div style="float:right;width:120px;">BUJUR</div>
              </div>
              <div style="clear:both"></div>

              <div style="float:left;min-width:50%;">
                <div style="float:left;width:120px;"></div>
                <div style="float:left;width:20px;"></div>
                <div style="float:left;min-width:200px;"></div>
              </div>
              <div style="float:right;min-width:50%;">
                <div style="float:right;min-width:100px;">{{ dataPDF.station[0].station_elevation }} m</div>
                <div style="float:right;width:20px;">:</div>
                <div style="float:right;width:120px;">ELEVASI</div>
              </div>
              <div style="clear:both"></div>
            </div>
          </div>
        </div>
        <div>
          <table class="table-report-IKLIMMIKRO" style="width:1300px; ">
            <thead>
              <tr>
                <td rowspan="3">TGL</td>
                <td colspan="10">Tinggi Sangkar 4 Meter</td>
                <td colspan="10">Tinggi Sangkar 7 Meter</td>
                <td colspan="10">Tinggi Sangkar 10 Meter</td>
              </tr>
              <tr>
                <td colspan="6" style="border-top:none;">TEMPERATUR (<sup>o</sup>C)</td>
                <td colspan="4" style="border-top:none;">KELEMBAPAN NISBI (%)</td>
                <td colspan="6" style="border-top:none;">TEMPERATUR (<sup>o</sup>C)</td>
                <td colspan="4" style="border-top:none;">KELEMBAPAN NISBI (%)</td>
                <td colspan="6" style="border-top:none;">TEMPERATUR (<sup>o</sup>C)</td>
                <td colspan="4" style="border-top:none;">KELEMBAPAN NISBI (%)</td>
              </tr>
              <tr>
                <td style="border-top:none;">07.00</td>
                <td style="border-top:none;">13.00</td>
                <td style="border-top:none;">18.00</td>
                <td style="border-top:none;">RATA<sup>2</sup></td>
                <td style="border-top:none;">MAX</td>
                <td style="border-top:none;">MIN</td>
                <td style="border-top:none;">07.00</td>
                <td style="border-top:none;">13.00</td>
                <td style="border-top:none;">18.00</td>
                <td style="border-top:none;">RATA<sup>2</sup></td>
                <td style="border-top:none;">07.00</td>
                <td style="border-top:none;">13.00</td>
                <td style="border-top:none;">18.00</td>
                <td style="border-top:none;">RATA<sup>2</sup></td>
                <td style="border-top:none;">MAX</td>
                <td style="border-top:none;">MIN</td>
                <td style="border-top:none;">07.00</td>
                <td style="border-top:none;">13.00</td>
                <td style="border-top:none;">18.00</td>
                <td style="border-top:none;">RATA<sup>2</sup></td>
                <td style="border-top:none;">07.00</td>
                <td style="border-top:none;">13.00</td>
                <td style="border-top:none;">18.00</td>
                <td style="border-top:none;">RATA<sup>2</sup></td>
                <td style="border-top:none;">MAX</td>
                <td style="border-top:none;">MIN</td>
                <td style="border-top:none;">07.00</td>
                <td style="border-top:none;">13.00</td>
                <td style="border-top:none;">18.00</td>
                <td style="border-top:none;">RATA<sup>2</sup></td>
              </tr>
              <tr>
                <td style="border-top:none;width:100px !important;"></td>
                <td v-for="n in 30" :key="n" style="border-top:none;width:200px !important;">{{ n }}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(raw, indexRaw) in item.RAW_1" :key="indexRaw">
                <td style="border-top:none;text-align:right;">{{ indexRaw + 1 }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL1"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL2"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL3"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL4"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL5"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL6"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL7"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL8"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL9"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL10"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL11"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL12"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL13"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL14"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL15"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL16"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL17"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL18"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL19"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL20"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL21"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL22"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL23"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL24"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL25"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL26"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL27"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL28"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL29"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL30"]) }}</td>
              </tr>
              <tr v-for="n in 31 - item.RAW_1.length" :key="n">
                <td style="border-top:none;">{{ n + item.RAW_1.length }}</td>
                <td v-for="o in 30" :key="o" style="border-top:none;"></td>
              </tr>
              <tr>
                <td style="border-top:none;">Jml</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL1"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL2"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL3"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL4"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL5"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL6"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL7"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL8"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL9"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL10"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL11"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL12"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL13"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL14"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL15"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL16"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL17"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL18"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL19"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL20"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL21"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL22"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL23"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL24"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL25"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL26"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL27"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL28"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL29"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH["COL30"]) }}</td>
              </tr>
              <tr>
                <td style="border-top:none;">Rata</td>

                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL1"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL2"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL3"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL4"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL5"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL6"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL7"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL8"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL9"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL10"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL11"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL12"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL13"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL14"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL15"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL16"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL17"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL18"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL19"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL20"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL21"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL22"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL23"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL24"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL25"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL26"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL27"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL28"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL29"]) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA["COL30"]) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="html2pdf__page-break" />

      <div slot="pdf-content">
        <div class="title-report-IKLIMMIKRO">
          {{ dataPDF.title }}
          <div v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_name }}</div>
        </div>

        <div slot="pdf-content" style="width:1300px;">
          <div style="font-size:12px;margin-top:10px;margin-bottom:10px;">
            <div style="float:left;min-width:50%;">
              <div style="float:left;width:120px;">BULAN</div>
              <div style="float:left;width:20px;">:</div>
              <div style="float:left;min-width:200px;">{{ dataPDF.bulan }}</div>
            </div>
            <div style="float:right;min-width:50%;">
              <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_latitude }} {{ Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS" }}</div>
              <div style="float:right;width:20px;">:</div>
              <div style="float:right;width:120px;">LINTANG</div>
            </div>
            <div style="clear:both"></div>

            <div style="float:left;min-width:50%;">
              <div style="float:left;width:120px;">TAHUN</div>
              <div style="float:left;width:20px;">:</div>
              <div style="float:left;min-width:200px;">{{ dataPDF.tahun }}</div>
            </div>
            <div style="float:right;min-width:50%;">
              <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_longitude }}</div>
              <div style="float:right;width:20px;">:</div>
              <div style="float:right;width:120px;">BUJUR</div>
            </div>
            <div style="clear:both"></div>

            <div style="float:left;min-width:50%;">
              <div style="float:left;width:120px;"></div>
              <div style="float:left;width:20px;"></div>
              <div style="float:left;min-width:200px;"></div>
            </div>
            <div style="float:right;min-width:50%;">
              <div style="float:right;min-width:100px;">{{ dataPDF.station[0].station_elevation }} m</div>
              <div style="float:right;width:20px;">:</div>
              <div style="float:right;width:120px;">DPL</div>
            </div>
            <div style="clear:both"></div>
          </div>
        </div>

        <div slot="pdf-content" style="margin-top:10px;">
          <table class="table-report-IKLIMMIKRO" style="width:1300px;">
            <thead>
              <tr>
                <td rowspan="3">TGL</td>
                <td colspan="3">Tinggi Sangkar 4 Meter</td>
                <td colspan="3">Tinggi Sangkar 7 Meter</td>
                <td colspan="3">Tinggi Sangkar 10 Meter</td>
              </tr>
              <tr>
                <td colspan="3" style="border-top:none;">Kecepatan Angin Rata<sup>2</sup></td>
                <td colspan="3" style="border-top:none;">Kecepatan Angin Rata<sup>2</sup></td>
                <td colspan="3" style="border-top:none;">Kecepatan Angin Rata<sup>2</sup></td>
              </tr>
              <tr>
                <td style="border-top:none;">07.00 - 13.00</td>
                <td style="border-top:none;">13.00 - 18.00</td>
                <td style="border-top:none;">18.00 - 07.00</td>
                <td style="border-top:none;">07.00 - 13.00</td>
                <td style="border-top:none;">13.00 - 18.00</td>
                <td style="border-top:none;">18.00 - 07.00</td>
                <td style="border-top:none;">07.00 - 13.00</td>
                <td style="border-top:none;">13.00 - 18.00</td>
                <td style="border-top:none;">18.00 - 07.00</td>
              </tr>
              <tr>
                <td style="border-top:none;"></td>
                <td v-for="n in 9" :key="n" style="border-top:none; border-bottom: solid 0.5px #000 !important;">{{ n }}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(raw, indexRaw) in item.RAW_2" :key="indexRaw">
                <td style="border-top:none;text-align:right;">{{ indexRaw + 1 }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL1"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL2"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL3"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL4"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL5"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL6"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL7"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL8"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw["COL9"], 2) }}</td>
              </tr>
              <tr v-for="n in 31 - item.RAW_2.length" :key="n">
                <td style="border-top:none;">{{ n + item.RAW_2.length }}</td>
                <td v-for="o in 9" :key="o" style="border-top:none;"></td>
              </tr>
              <tr>
                <td style="border-top:none;">Jml</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH["COL1"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH["COL2"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH["COL3"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH["COL4"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH["COL5"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH["COL6"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH["COL7"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH["COL8"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH["COL9"], 2) }}</td>
              </tr>
              <tr>
                <td style="border-top:none;">Rata</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA["COL1"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA["COL2"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA["COL3"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA["COL4"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA["COL5"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA["COL6"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA["COL7"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA["COL8"], 2) }}</td>
                <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA["COL9"], 2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportIKLIMMIKRO",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values, DIGIT_DECIMAL = 1) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(DIGIT_DECIMAL));
      }
      return DataResult;
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.title-report-IKLIMMIKRO {
  width: 1300px;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
  text-transform: uppercase;
  color: #000;
}

.IKLIMMIKRO td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-IKLIMMIKRO td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-IKLIMMIKRO > thead > tr > td {
  background: #e0e4e7;
  color: #000;
  border-left: solid 0.5px rgb(8, 8, 8) !important;
  /* border-top: solid 0.5px #000 !important; */
  border-bottom: solid 0.5px #000 !important;
  text-align: center;
  font-weight: bold;
}

.table-report-IKLIMMIKRO tbody > tr > td {
  padding: 2px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
  color: #000;
}

/* .table-report-IKLIMMIKRO  tbody > tr > td:last-of-type {
 border-right:solid 0.5px #000;
} */

.table-report-IKLIMMIKRO tr {
  border: none !important;
}

/* .table-report-IKLIMMIKRO thead :not(.table-code) > td{
  padding: 2px;
  border-left:solid 0.5px #000;
  border-bottom:solid 0.5px #000;
  border-top:solid 0.5px #000;
}

.table-report-IKLIMMIKRO thead :not(.table-code) > td:last-of-type{
  border-right:solid 0.5px #000;
} */

.table-report-IKLIMMIKRO tbody > tr {
  background-color: #fff;
}

.table-report-IKLIMMIKRO tbody > tr:nth-child(even) {
  background-color: #fff;
}

.table-report-IKLIMMIKRO {
  font-size: 7.7px !important;
  margin-bottom: 20px !important;
  border-right: solid 0.5px #000 !important;
  border-top: solid 0.5px #000 !important;
}

/* .table-report-IKLIMMIKRO td{
  max-width: 90px;
} */
</style>
