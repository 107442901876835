<template>
  <div style="margin:25px;">
    <div slot="pdf-content" v-for="(item_data, periode) in dataPDF.data" :key="periode">
      <div v-for="(item, item_periode) in item_data" :key="item_periode">
        <div class="html2pdf__page-break" v-if="item.indexNumber != 0" />

        <section class="pdf-item">
          <div class="title-report-me54">{{ dataPDF.title }}</div>
          <table class="title-table-me45">
            <thead>
              <tr class="table-code">
                <td v-for="n in 11" :key="n">{{ n }}</td>
              </tr>
              <tr>
                <td>I</td>
                <td>I</td>
                <td>I</td>
                <td>I</td>
                <td colspan="2">HARI</td>
                <td colspan="3">BLN</td>
                <td colspan="2">TAHUN</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-for="(wmo, indexWmo) in dataPDF.wmo_id" :key="indexWmo + '_WMO'">
                  {{ wmo }}
                </td>
                <td v-for="(month, indexMonth) in item.label" :key="indexMonth">
                  {{ month }}
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table-report-me45">
            <thead>
              <tr class="table-code">
                <td v-for="n in 83" :key="n + 11">{{ n + 11 }}</td>
              </tr>
              <tr>
                <td colspan="2">GMT</td>
                <td colspan="3">TdTdTd</td>
                <td>N</td>
                <td colspan="2">dd</td>
                <td colspan="2">ff</td>
                <td colspan="2">VV</td>
                <td colspan="2">WW</td>
                <td colspan="2">W1W2</td>
                <td colspan="4">QFF</td>
                <td colspan="3">TtTtTt</td>
                <td>Nh</td>
                <td>CL</td>
                <td>h</td>
                <td>CM</td>
                <td>CH</td>
                <td>Ns</td>
                <td>C</td>
                <td colspan="2">hshs</td>
                <td>Ns</td>
                <td>C</td>
                <td colspan="2">hshs</td>
                <td>0</td>
                <td>C</td>
                <td colspan="2">hshs</td>
                <td colspan="3">CDaec</td>
                <td colspan="2">UU</td>
                <td colspan="4">QFE</td>
                <td colspan="3">TwTwTw</td>
                <td colspan="3">RRR</td>
                <td>tR</td>
                <td colspan="3">TxTxTx</td>
                <td colspan="3">TnTnTn</td>
                <td colspan="3">EEE</td>
                <td colspan="4">F24F24F24F24</td>
                <td colspan="3">S S S</td>
                <td>E</td>
                <td colspan="3">DLDMDH</td>
                <td colspan="3">P24P24P24</td>
                <td>iW</td>
                <td>iX</td>
                <td>iR</td>
                <td>iE</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dataRaw, indexRaw) in item.raw" :key="indexRaw">
                <td v-for="n in 2" :key="n + 'GMT'">
                  {{ dataRaw.GMT[n - 1 - (2 - dataRaw.GMT.length)] }}
                </td>
                <td v-for="n in 3" :key="n + 'TDTDTD'">
                  {{ dataRaw.TDTDTD[n - 1 - (3 - dataRaw.TDTDTD.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'N'">
                  {{ dataRaw.N[n - 1 - (1 - dataRaw.N.length)] }}
                </td>
                <td v-for="n in 2" :key="n + 'DD'">
                  {{ dataRaw.DD[n - 1 - (2 - dataRaw.DD.length)] }}
                </td>
                <td v-for="n in 2" :key="n + 'FF'">
                  {{ dataRaw.FF[n - 1 - (2 - dataRaw.FF.length)] }}
                </td>
                <td v-for="n in 2" :key="n + 'VV'">
                  {{ dataRaw.VV[n - 1 - (2 - dataRaw.VV.length)] }}
                </td>
                <td v-for="n in 2" :key="n + 'WW'">
                  {{ dataRaw.WW[n - 1 - (2 - dataRaw.WW.length)] }}
                </td>
                <td v-for="n in 2" :key="n + 'W1W2'">
                  {{ dataRaw.W1W2[n - 1 - (2 - dataRaw.W1W2.length)] }}
                </td>
                <td v-for="n in 4" :key="n + 'QFF'">
                  {{ dataRaw.QFF[n - 1 - (4 - dataRaw.QFF.length)] }}
                </td>
                <td v-for="n in 3" :key="n + 'TTTTTT'">
                  {{ dataRaw.TTTTTT[n - 1 - (3 - dataRaw.TTTTTT.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'NH'">
                  {{ dataRaw.NH[n - 1 - (1 - dataRaw.NH.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'CL'">
                  {{ dataRaw.CL[n - 1 - (1 - dataRaw.CL.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'H'">
                  {{ dataRaw.H[n - 1 - (1 - dataRaw.H.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'CM'">
                  {{ dataRaw.CM[n - 1 - (1 - dataRaw.CM.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'CH'">
                  {{ dataRaw.CH[n - 1 - (1 - dataRaw.CH.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'NS'">
                  {{ dataRaw.NS[n - 1 - (1 - dataRaw.NS.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'C'">
                  {{ dataRaw.C[n - 1 - (1 - dataRaw.C.length)] }}
                </td>
                <td v-for="n in 2" :key="n + 'HSHS'">
                  {{ dataRaw.HSHS[n - 1 - (2 - dataRaw.HSHS.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'NS_1'">
                  {{ dataRaw.NS_1[n - 1 - (1 - dataRaw.NS_1.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'C_1'">
                  {{ dataRaw.C_1[n - 1 - (1 - dataRaw.C_1.length)] }}
                </td>
                <td v-for="n in 2" :key="n + 'HSHS_1'">
                  {{ dataRaw.HSHS_1[n - 1 - (2 - dataRaw.HSHS_1.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'O'">
                  {{ dataRaw.O[n - 1 - (1 - dataRaw.O.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'C_2'">
                  {{ dataRaw.C_2[n - 1 - (1 - dataRaw.C_2.length)] }}
                </td>
                <td v-for="n in 2" :key="n + 'HSHS_2'">
                  {{ dataRaw.HSHS_2[n - 1 - (2 - dataRaw.HSHS_2.length)] }}
                </td>
                <td v-for="n in 3" :key="n + 'CDAEC'">
                  {{ dataRaw.CDAEC[n - 1 - (3 - dataRaw.CDAEC.length)] }}
                </td>
                <td v-for="n in 2" :key="n + 'UU'">
                  {{ dataRaw.UU[n - 1 - (2 - dataRaw.UU.length)] }}
                </td>
                <td v-for="n in 4" :key="n + 'QFE'">
                  {{ dataRaw.QFE[n - 1 - (4 - dataRaw.QFE.length)] }}
                </td>
                <td v-for="n in 3" :key="n + 'TWTWTW'">
                  {{ dataRaw.TWTWTW[n - 1 - (3 - dataRaw.TWTWTW.length)] }}
                </td>
                <td v-for="n in 3" :key="n + 'RRR'">
                  {{ dataRaw.RRR[n - 1 - (3 - dataRaw.RRR.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'IR'">
                  {{ dataRaw.IR[n - 1 - (3 - dataRaw.IR.length)] }}
                </td>
                <td v-for="n in 3" :key="n + 'TXTXTX'">
                  {{ dataRaw.TXTXTX[n - 1 - (3 - dataRaw.TXTXTX.length)] }}
                </td>
                <td v-for="n in 3" :key="n + 'TNTNTN'">
                  {{ dataRaw.TNTNTN[n - 1 - (3 - dataRaw.TNTNTN.length)] }}
                </td>
                <td v-for="n in 3" :key="n + 'EEE'">
                  {{ dataRaw.EEE[n - 1 - (3 - dataRaw.EEE.length)] }}
                </td>
                <td v-for="n in 4" :key="n + 'F24F24F24F24'">
                  {{ dataRaw.F24F24F24F24[n - 1 - (4 - dataRaw.F24F24F24F24.length)] }}
                </td>
                <td v-for="n in 3" :key="n + 'SSS'">
                  {{ dataRaw.SSS[n - 1 - (3 - dataRaw.SSS.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'E'">
                  {{ dataRaw.E[n - 1 - (1 - dataRaw.E.length)] }}
                </td>
                <td v-for="n in 3" :key="n + 'DLDMDH'">
                  {{ dataRaw.DLDMDH[n - 1 - (3 - dataRaw.DLDMDH.length)] }}
                </td>
                <td v-for="n in 3" :key="n + 'P24P24P24'">
                  {{ dataRaw.P24P24P24[n - 1 - (3 - dataRaw.P24P24P24.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'IW'">
                  {{ dataRaw.IW[n - 1 - (1 - dataRaw.IW.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'IX'">
                  {{ dataRaw.IX[n - 1 - (1 - dataRaw.IX.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'IR'">
                  {{ dataRaw.IR[n - 1 - (1 - dataRaw.IR.length)] }}
                </td>
                <td v-for="n in 1" :key="n + 'IE'">
                  {{ dataRaw.IR[n - 1 - (1 - dataRaw.IE.length)] }}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <div class="html2pdf__page-break" v-if="onCheckKey(item_data, item_periode) == false" />
      </div>
      <!-- <div class="html2pdf__page-break"/> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportME45",
  props: {
    dataPDF: Object,
  },
  methods: {
    onCheckKey(item, key) {
      var LAST = "";
      for (var i in item) {
        LAST = i;
      }
      console.log(LAST);
      if (LAST == key) {
        return true;
      } else {
        return false;
      }
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.title-report-me54 {
  width: 1303px;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
}

.table-report-me45 td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-me45 td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-me45 {
  border-right: solid 0.5px #000 !important;
}

.table-report-me45 thead > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-me45 > thead > tr > td {
  text-align: center;
  /* background: #56a1fc; */
  background: #e0e4e7;
  color: #000000;
  border-left: solid 0.5px #000 !important;
  border-top: solid 0.5px #000 !important;
  text-align: center;
  font-weight: bold;
  padding: 2px !important;
}

.table-report-me45 tbody > tr > td {
  margin: 0px;
  text-align: center;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
}

.table-report-me45 tbody > tr > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-me45 tr {
  border: none !important;
}

.table-report-me45 thead :not(.table-code) > td {
  padding: 2px;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
  border-top: solid 0.5px #000;
}

.table-report-me45 thead :not(.table-code) > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-me45 {
  font-size: 6.5px !important;
  margin-bottom: 20px !important;
  width: 1303px;
}

.table-report-me45 td {
  max-width: 80px;
  height: 15px;
}

.title-table-me45 {
  text-align: center;
  background: #e0e4e7;
  color: #000000 !important;
  border-left: solid 0.5px #000 !important;
  border-top: solid 0.5px #000 !important;
  border-bottom: solid 0.5px #000 !important;
  text-align: center;
  font-weight: bold;
  padding: 5px !important;
  margin-bottom: 20px;
}

.table-report-me45 tbody > tr:not(".rowpan") {
  /* background-color: #f4fbff; */
  background-color: #fff;
}

.table-report-me45 tbody > tr:nth-child(even) {
  /* background-color: #e7edff; */
  background-color: #fff;
}

.title-table-me45 {
  border-right: solid 0.5px #000 !important;
  border-bottom: solid 0.5px #000 !important;
}

.title-table-me45 thead tr td {
  border-left: solid 0.5px #000 !important;
  border-bottom: solid 0.5px #000 !important;
  color: #000000 !important;
  padding: 5px;
  font-size: 6.5px !important;
}

.title-table-me45 tbody tr td {
  border-left: solid 0.5px #000 !important;
  color: #000000 !important;
  padding: 5px;
  font-size: 6.5px !important;
}
</style>
