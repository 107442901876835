<template>
  <div style="margin-left:25px;margin-top:10px;margin-right:25px;margin-bottom:25px;" class="conten-report">
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <div v-for="(observer, indexObserver) in item" :key="indexObserver">
        <div class="title-report-GUNBELLANI" style="width:1303px; font-size:16px !important;color:#000;">
          {{ dataPDF.title }}
        </div>
        <table style="width:1303px;color:#000;">
          <tr>
            <td style="width:50%">
              <div style="border:solid 1px #000;margin:10px;">
                <div style="padding:2px;float:right;">POS HUJAN <label style="width:60px;"></label></div>
                <div style="padding:2px;">PEMERIKSAAN HUJAN</div>
                <div style="clear:both;"></div>
                <div style="padding:2px;float:right;">NO : <label style="width:100px;"></label></div>
                <div style="padding:2px;">
                  TAHUN : <label style="width:100px;">{{ dataPDF.tahun }}</label>
                </div>
                <div style="padding:2px;">
                  BULAN : <label style="width:100px;">{{ dataPDF.bulan }}</label>
                </div>
                <div style="padding:2px;">
                  TEMPAT PEMERIKSAAN : <label v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_name }}</label>
                </div>
                <div style="padding:2px;">
                  KECAMATAN :<label style="width:100px;"
                    ><span v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].kecamatan_name }}</span></label
                  >
                </div>
                <div style="padding:2px;">
                  KABUPATEN :<label style="width:100px;"
                    ><label v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].kabupaten_name }}</label></label
                  >
                </div>

                <div style="width:100%;text-align:center;margin-top:10px;">
                  PEMERIKSAAN HUJAN TIAP PAGI JAM 07:00 WAKTU SETEMPAT
                </div>
                <table style="width:100%" class="table-report-FORMHUJAN">
                  <thead>
                    <tr>
                      <td style="border-left:none;">TANGGAL PEMERIKSAAN</td>
                      <td>HUJAN DALAM mm</td>
                      <td>TANGGAL PEMERIKSAAN</td>
                      <td>HUJAN DALAM mm</td>
                      <td>ANGGAL PEMERIKSAAN</td>
                      <td style="border-right:none;">HUJAN DALAM mm</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="n in 10" :key="n + 'N'" style="border-bottom:none;">
                      <td style="border-left:none;border-bottom:none;">{{ n }}</td>
                      <td style="border-bottom:none;">{{ fixedValueNumber(observer.OBSERVER_RAW.RAW[n - 1]["VALUE"]) }}</td>
                      <td style="border-bottom:none;">{{ n + 10 }}</td>
                      <td style="border-bottom:none;">{{ fixedValueNumber(observer.OBSERVER_RAW.RAW[n + 10 - 1]["VALUE"]) }}</td>
                      <td style="border-bottom:none;">{{ n + 20 }}</td>
                      <td style="border-right:none;border-bottom:none;">{{ fixedValueNumber(observer.OBSERVER_RAW.RAW[n + 20 - 1]["VALUE"]) }}</td>
                    </tr>
                    <tr>
                      <td style="border-left:none;"></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>31</td>
                      <td style="border-right:none;">{{ fixedValueNumber(observer.OBSERVER_RAW.RAW[30]["VALUE"]) }}</td>
                    </tr>
                    <tr>
                      <td style="border-left:none;">JUMLAH</td>
                      <td>{{ fixedValueNumber(observer.OBSERVER_RAW.CALCULATION.COUNT_COL_1) }}</td>
                      <td>JUMLAH</td>
                      <td>{{ fixedValueNumber(observer.OBSERVER_RAW.CALCULATION.COUNT_COL_2) }}</td>
                      <td>JUMLAH</td>
                      <td style="border-right:none;">{{ observer.OBSERVER_RAW.CALCULATION.COUNT_COL_3 + observer.OBSERVER_RAW.CALCULATION.COUNT_COL_4 }}</td>
                    </tr>
                  </tbody>
                </table>
                <table style="width:100%">
                  <tr>
                    <td style="width:50%;">
                      <div>JUMLAH</div>
                      <div>HUJAN SEBULAN : <label style="width:100px;"></label> mm</div>
                      <br /><br /><br /><br /><br />
                      <div>BANYAKNYA HARI HUJAN SEBULAN : <label style="width:60px;"></label> HARI</div>
                    </td>
                    <td style="width:50%;">
                      <div style="margin-bottom:10px;">
                        BANYAKNYA HUJAN YANG DIUKUR <br />
                        DITULIS PADA HARI WAKTU MENGUKUT
                      </div>
                      <div style="margin-bottom:10px;">
                        SETELAH DIISI LENGKAP SEGERA <br />
                        D DIKIRIM DENGAN POS
                      </div>
                      <div style="margin-bottom:10px;">
                        BESARNYA CURAH HUJAN DIISI <br />
                        Pecahan lebih besar atau sama dengan 0,5 dibulatkan <br />
                        ke atas dan lebih kecil dari 0,5 dibulatkan kebawah. <br />
                        Hujan lebih kecil dari 0,5 ditulis 0 <br />
                        Tidak ada hujan ditulis "-" <br />
                        Penakar hujan rusak dituis "x"
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
            <td style="width:50%; vertical-align:top;">
              <div style="border:solid 1px #000;margin:10px;">
                <table style="height:725px; width:100%;">
                  <tbody>
                    <tr>
                      <td style="border-right:solid 1px #000;width:50%; padding:10px;">
                        <div style="text-align:center; padding:20PX;">BERITAHUKAN SEGERA</div>
                        <div style="float:left;">
                          JIKA :
                        </div>
                        <div style="float:left;margin-left:10px;">
                          PENAKAR HUJAN BOCOR/RUSAK <br />
                          PENAKAR HUJAN DIPINDAHKAN <br />
                          GELAS PENGUKUR PECAH <br />
                          KEHABISAN KARTU HUJAN<br />
                          ADA GEMPA, BANJIR, DLL
                        </div>
                        <div style="clear:both;"></div>
                        <div style="margin-top:250px;min-height:100px;">
                          DIKIRIM OLEH
                        </div>
                        <div style="min-height:100px;">
                          ALAMAT :
                        </div>
                        <div style="min-height:50px;">
                          CONTOH H5
                        </div>
                      </td>
                      <td style="width:50%;">
                        <div style="text-align:center;padding:20px;">
                          KEPADA
                        </div>
                        <div style="text-align:center;padding:20px;">
                          BADAN METEOROLOGI KLIMATOLOGI DAN GEOFISIKA
                        </div>
                        <div style="text-align:center;padding:20px;">
                          U.b
                        </div>
                        <div style="padding:20px;">
                          KEPALA STASIUN
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </table>

        <div class="html2pdf__page-break" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportFORMHUJAN",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values === "") {
        DataResult = "";
      } else if (Number(values) == "0" || Number(values) == 0) {
        DataResult = 0;
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult;
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.conten-report label {
  font-size: 10px !important;
}

.conten-report {
  font-size: 10px !important;
}
.title-report-FORMHUJAN {
  width: 1303px;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
}

.FORMHUJAN td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-FORMHUJAN td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-FORMHUJAN > thead > tr > td {
  text-align: center;
  background: #e0e4e7;
  color: #000000;
  border-left: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
  padding: 5px !important;
}

.table-report-FORMHUJAN tbody > tr > td {
  padding: 2px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
  color: #000;
}

.table-report-FORMHUJAN tbody > tr > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-FORMHUJAN tr {
  border: none !important;
}

.table-report-FORMHUJAN thead :not(.table-code) > td {
  padding: 2px;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
  border-top: solid 0.5px #000;
}

.table-report-FORMHUJAN thead :not(.table-code) > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-FORMHUJAN tbody > tr:not(".rowpan") {
  background-color: #ffffff;
}

.table-report-FORMHUJAN tbody > tr:nth-child(even) {
  background-color: #ffffff;
}

.table-report-FORMHUJAN {
  font-size: 8px !important;
  margin-bottom: 10px !important;
}

.table-report-FORMHUJAN td {
  max-width: 90px;
  height: 30px;
}
</style>
