<template>
  <div style="margin:10px;">
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <div class="title-report-IKLIMMIKRO">
        {{ dataPDF.title }}
        <div v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_name }}</div>
      </div>
      <div style="width:1300px;">
        <div style="font-size:12px;margin-top:10px;margin-bottom:10px;color:#000;">
          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;">BULAN</div>
            <div style="float:left;width:20px;">:</div>
            <div style="float:left;min-width:200px;">{{ dataPDF.bulan }}</div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_latitude }}  {{ (Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS")}}</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">LINTANG</div>
          </div>
          <div style="clear:both"></div>

          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;">TAHUN</div>
            <div style="float:left;width:20px;">:</div>
            <div style="float:left;min-width:200px;">{{ dataPDF.tahun }}</div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_longitude }} BT</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">BUJUR</div>
          </div>
          <div style="clear:both"></div>

          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;"></div>
            <div style="float:left;width:20px;"></div>
            <div style="float:left;min-width:200px;"></div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].station_elevation }} m</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">ELEVASI</div>
          </div>
          <div style="clear:both"></div>
        </div>
      </div>

      <div style="margin-top:10px;">
        <table class="table-report-IKLIMMIKRO" style="width:1300px;">
          <thead>
            <tr>
              <td rowspan="3">TGL</td>
              <td colspan="21">SUHU TANAH GUNDUL</td>
              <td colspan="21">SUHU TANAH BERUMPUT</td>
            </tr>
            <tr>
              <td colspan="7" style="border-top:none;">7.30 ( WS )</td>
              <td colspan="7" style="border-top:none;">13.30 ( WS )</td>
              <td colspan="7" style="border-top:none;">17.30 ( WS )</td>
              <td colspan="7" style="border-top:none;">7.30 ( WS )</td>
              <td colspan="7" style="border-top:none;">13.30 ( WS )</td>
              <td colspan="7" style="border-top:none;">17.30 ( WS )</td>
            </tr>
            <tr>
              <td style="border-top:none;">0 cm</td>
              <td style="border-top:none;">2 cm</td>
              <td style="border-top:none;">5 cm</td>
              <td style="border-top:none;">10 cm</td>
              <td style="border-top:none;">20 cm</td>
              <td style="border-top:none;">50 cm</td>
              <td style="border-top:none;">100 cm</td>

              <td style="border-top:none;">0 cm</td>
              <td style="border-top:none;">2 cm</td>
              <td style="border-top:none;">5 cm</td>
              <td style="border-top:none;">10 cm</td>
              <td style="border-top:none;">20 cm</td>
              <td style="border-top:none;">50 cm</td>
              <td style="border-top:none;">100 cm</td>

              <td style="border-top:none;">0 cm</td>
              <td style="border-top:none;">2 cm</td>
              <td style="border-top:none;">5 cm</td>
              <td style="border-top:none;">10 cm</td>
              <td style="border-top:none;">20 cm</td>
              <td style="border-top:none;">50 cm</td>
              <td style="border-top:none;">100 cm</td>

              <td style="border-top:none;">0 cm</td>
              <td style="border-top:none;">2 cm</td>
              <td style="border-top:none;">5 cm</td>
              <td style="border-top:none;">10 cm</td>
              <td style="border-top:none;">20 cm</td>
              <td style="border-top:none;">50 cm</td>
              <td style="border-top:none;">100 cm</td>

              <td style="border-top:none;">0 cm</td>
              <td style="border-top:none;">2 cm</td>
              <td style="border-top:none;">5 cm</td>
              <td style="border-top:none;">10 cm</td>
              <td style="border-top:none;">20 cm</td>
              <td style="border-top:none;">50 cm</td>
              <td style="border-top:none;">100 cm</td>

              <td style="border-top:none;">0 cm</td>
              <td style="border-top:none;">2 cm</td>
              <td style="border-top:none;">5 cm</td>
              <td style="border-top:none;">10 cm</td>
              <td style="border-top:none;">20 cm</td>
              <td style="border-top:none;">50 cm</td>
              <td style="border-top:none;">100 cm</td>
            </tr>
            <tr>
              <td style="border-top:none;"></td>
              <td style="border-top:none;width:30px; " v-for="n in 42" :key="n">{{ n }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(raw, indexRaw) in item.RAW" :key="indexRaw">
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(indexRaw + 1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL4) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL5) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL6) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL7) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL8) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL9) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL10) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL11) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL12) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL13) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL14) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL15) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL16) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL17) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL18) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL19) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL20) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL21) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL22) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL23) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL24) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL25) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL26) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL27) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL28) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL29) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL30) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL31) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL32) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL33) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL34) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL35) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL36) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL37) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL38) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL39) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL40) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL41) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL42) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL43) }}</td>
            </tr>
            <tr v-for="o in 31 - item.RAW.length" :key="o + item.RAW.length">
              <td style="border-top:none;">{{ o + item.RAW.length }}</td>
              <td v-for="j in 42" :key="j" style="border-top:none;"></td>
            </tr>
            <tr>
              <td style="border-top:none;">Rata<sup>2</sup></td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL4) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL5) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL6) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL7) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL8) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL9) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL10) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL11) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL12) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL13) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL14) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL15) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL16) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL17) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL18) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL19) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL20) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL21) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL22) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL23) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL24) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL25) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL26) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL27) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL28) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL29) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL30) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL31) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL32) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL33) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL34) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL35) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL36) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL37) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL38) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL39) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL40) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL41) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL42) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL43) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportSUHUTANAH",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult;
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.title-report-SUHUTANAH {
  width: 1303px;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
}

.SUHUTANAH td.rotation_90 {
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-SUHUTANAH td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-SUHUTANAH > thead > tr > td {
  text-align: center;
  background: #e0e4e7;
  color: #000000;
  border: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
}

.table-report-SUHUTANAH tbody > tr > td {
  padding: 3px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
}

.table-report-SUHUTANAH tbody > tr > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-SUHUTANAH tr {
  border: none !important;
}

.table-report-SUHUTANAH thead :not(.table-code) > td {
  padding: 3.5px;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
  border-top: solid 0.5px #000;
}

.table-report-SUHUTANAH thead :not(.table-code) > td:last-of-type {
  border-right: solid 0.5px #000;
}

.table-report-SUHUTANAH {
  font-size: 8.5px !important;
  font-weight: bold;
  margin-bottom: 20px !important;
}

/* .table-report-SUHUTANAH td{
  max-width: 90px;
  height: 30px;
} */
</style>
