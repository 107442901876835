import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_IKLIMMIKRO = false;
export default {
    async dummyDataIKLIMMIKRO(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01"  : {
                   "RAW_1" :[
                        {
                        "TGL"   : "2021-01",
                        "COL1"  : 1, 
                        "COL2"  : 2,
                        "COL3"  : 3,
                        "COL4"  : 4,
                        "COL5"  : 5,
                        "COL6"  : 6,
                        "COL7"  : 7,
                        "COL8"  : 8,
                        "COL9"  : 9,
                        "COL10"  : 10,
                        "COL11"  : 11,
                        "COL12"  : 12,
                        "COL13"  : 13,
                        "COL14"  : 14,
                        "COL15"  : 15,
                        "COL16"  : 16,
                        "COL17"  : 17,
                        "COL18"  : 18,
                        "COL19"  : 19,
                        "COL20"  : 20,
                        "COL21"  : 21,
                        "COL22"  : 22,
                        "COL23"  : 23,
                        "COL24"  : 24,
                        "COL25"  : 25,
                        "COL26"  : 26,
                        "COL27"  : 27,
                        "COL28"  : 28,
                        "COL29"  : 29,
                        "COL30"  : 30,
                        },
                        {
                        "TGL"   : "2021-03",
                        "COL1"  : 3, 
                        "COL2"  : 2,
                        "COL3"  : 3,
                        "COL4"  : 4,
                        "COL5"  : 5,
                        "COL6"  : 6,
                        "COL7"  : 7,
                        "COL8"  : 8,
                        "COL9"  : 9,
                        "COL10"  : 10,
                        "COL11"  : 11,
                        "COL12"  : 12,
                        "COL13"  : 13,
                        "COL14"  : 14,
                        "COL15"  : 15,
                        "COL16"  : 16,
                        "COL17"  : 17,
                        "COL18"  : 18,
                        "COL19"  : 19,
                        "COL20"  : 20,
                        "COL21"  : 21,
                        "COL22"  : 22,
                        "COL23"  : 23,
                        "COL24"  : 24,
                        "COL25"  : 25,
                        "COL26"  : 26,
                        "COL27"  : 27,
                        "COL28"  : 28,
                        "COL29"  : 29,
                        "COL30"  : 30,
                        },
                        {
                        "TGL"   : "2021-02",
                        "COL1"  : 2, 
                        "COL2"  : 2,
                        "COL3"  : 3,
                        "COL4"  : 4,
                        "COL5"  : 5,
                        "COL6"  : 6,
                        "COL7"  : 7,
                        "COL8"  : 8,
                        "COL9"  : 9,
                        "COL10"  : 10,
                        "COL11"  : 11,
                        "COL12"  : 12,
                        "COL13"  : 13,
                        "COL14"  : 14,
                        "COL15"  : 15,
                        "COL16"  : 16,
                        "COL17"  : 17,
                        "COL18"  : 18,
                        "COL19"  : 19,
                        "COL20"  : 20,
                        "COL21"  : 21,
                        "COL22"  : 22,
                        "COL23"  : 23,
                        "COL24"  : 24,
                        "COL25"  : 25,
                        "COL26"  : 26,
                        "COL27"  : 27,
                        "COL28"  : 28,
                        "COL29"  : 29,
                        "COL30"  : 30,
                        },
                    ],
                   "RAW_2" : [
                        {
                            "TGL"       : "2021-01",
                            "COL1"     : 1,
                            "COL2"     : 2,
                            "COL3"     : 3,
                            "COL4"     : 4,
                            "COL5"     : 5,
                            "COL6"     : 6,
                            "COL7"     : 7,
                            "COL8"     : 8,
                            "COL9"     : 9
                        },
                        {
                            "TGL"       : "2021-03",
                            "COL1"     : 3,
                            "COL2"     : 2,
                            "COL3"     : 3,
                            "COL4"     : 4,
                            "COL5"     : 5,
                            "COL6"     : 6,
                            "COL7"     : 7,
                            "COL8"     : 8,
                            "COL9"     : 9
                        },
                        {
                            "TGL"      : "2021-02",
                            "COL1"     : 2,
                            "COL2"     : 2,
                            "COL3"     : 3,
                            "COL4"     : 4,
                            "COL5"     : 5,
                            "COL6"     : 6,
                            "COL7"     : 7,
                            "COL8"     : 8,
                            "COL9"     : 9
                        },
                    ]
               }
            }

            var dataBuild = {};
            for(var i in datas){
                dataBuild[i] = {};
                var rawPeriode = datas[i];
                for(var j in rawPeriode){
                    dataBuild[i][j] = rawPeriode[j].sort(function(a, b) {
                        return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                    });
                    var infoDara = rawPeriode[j];
                    var Total = {};
                    var Count = {};
                    var Rata  = {};

                    var RAW_DATA = {};
                    RAW_DATA[j] = [];
                    for(var icount = 1; icount <= 31; icount++){
                        if(j == "RAW_1"){
                            RAW_DATA[j].push({
                                "TGL"   : "2021-02",
                                "COL1"  : 2, 
                                "COL2"  : 2,
                                "COL3"  : 3,
                                "COL4"  : 4,
                                "COL5"  : 5,
                                "COL6"  : 6,
                                "COL7"  : 7,
                                "COL8"  : 8,
                                "COL9"  : 9,
                                "COL10"  : 10,
                                "COL11"  : 11,
                                "COL12"  : 12,
                                "COL13"  : 13,
                                "COL14"  : 14,
                                "COL15"  : 15,
                                "COL16"  : 16,
                                "COL17"  : 17,
                                "COL18"  : 18,
                                "COL19"  : 19,
                                "COL20"  : 20,
                                "COL21"  : 21,
                                "COL22"  : 22,
                                "COL23"  : 23,
                                "COL24"  : 24,
                                "COL25"  : 25,
                                "COL26"  : 26,
                                "COL27"  : 27,
                                "COL28"  : 28,
                                "COL29"  : 29,
                                "COL30"  : 30,
                            });
                        }
                        if(j == "RAW_2"){
                            RAW_DATA[j].push({
                                "TGL"      : "2021-02",
                                "COL1"     : 2,
                                "COL2"     : 2,
                                "COL3"     : 3,
                                "COL4"     : 4,
                                "COL5"     : 5,
                                "COL6"     : 6,
                                "COL7"     : 7,
                                "COL8"     : 8,
                                "COL9"     : 9
                            });
                        }
                    }
                    for(var k in infoDara){
                        var dataDate = new Date(infoDara[k]["TGL"]);
                        for(var m in infoDara[k]){
                            if(m == "TGL"){
                                continue;
                            }
                            if(RAW_DATA[j][Number(dataDate.getDate())-1] != undefined ){
                                RAW_DATA[j][Number(dataDate.getDate())-1][m] = infoDara[k][m];
                            }
                            if(Total[m] == undefined ){
                                Total[m] = 0;
                                Count[m] = 0;
                            }
                            Total[m] += infoDara[k][m];
                            Count[m]++;
                            Rata[m] = Total[m] / Count[m];
                        }
                    }
                    dataBuild[i][j] = RAW_DATA[j];
                    dataBuild[i]["TOTAL_"+j] = {JUMLAH : Total, RATA : Rata};
                }
            }
            datas = dataBuild;
            resolve(datas);
        });
    },

    async storeDataIKLIMMIKRO(STATION_ID,PERIODE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_IKLIM_MIKRO(STATION_ID,PERIODE);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataIKLIMMIKRO(STATION_ID,PERIODE){
        return new Promise(async (resolve) => {
            if(SHOW_DUMMY_IKLIMMIKRO == true){
                var datas = await this.dummyDataIKLIMMIKRO();
                resolve(datas);
            }else{
                var thisData = await this.storeDataIKLIMMIKRO(STATION_ID,PERIODE);
                var datas = thisData.data;
                var dataBuild = {};
                for(var i in datas){
                    dataBuild[i] = {};
                    var rawPeriode = datas[i];
                    for(var j in rawPeriode){
                        dataBuild[i][j] = rawPeriode[j].sort(function(a, b) {
                            return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                        });
                        var infoDara = rawPeriode[j];
                        var Total = {};
                        var Count = {};
                        var Rata  = {};

                        var RAW_DATA = {};
                        RAW_DATA[j] = [];
                        for(var icount = 1; icount <= 31; icount++){
                            if(j == "RAW_1"){
                                RAW_DATA[j].push({
                                    "TGL"   : icount,
                                    "COL1"  : "", 
                                    "COL2"  : "",
                                    "COL3"  : "",
                                    "COL4"  : "",
                                    "COL5"  : "",
                                    "COL6"  : "",
                                    "COL7"  : "",
                                    "COL8"  : "",
                                    "COL9"  : "",
                                    "COL10"  :"",
                                    "COL11"  :"",
                                    "COL12"  :"",
                                    "COL13"  :"",
                                    "COL14"  :"",
                                    "COL15"  :"",
                                    "COL16"  :"",
                                    "COL17"  :"",
                                    "COL18"  : "",
                                    "COL19"  : "",
                                    "COL20"  : "",
                                    "COL21"  : "",
                                    "COL22"  : "",
                                    "COL23"  : "",
                                    "COL24"  : "",
                                    "COL25"  : "",
                                    "COL26"  : "",
                                    "COL27"  : "",
                                    "COL28"  : "",
                                    "COL29"  : "",
                                    "COL30"  : "",
                                });
                            }
                            if(j == "RAW_2"){
                                RAW_DATA[j].push({
                                    "TGL"      : icount,
                                    "COL1"     : "",
                                    "COL2"     : "",
                                    "COL3"     : "",
                                    "COL4"     : "",
                                    "COL5"     : "",
                                    "COL6"     : "",
                                    "COL7"     : "",
                                    "COL8"     : "",
                                    "COL9"     : ""
                                });
                            }
                        }
                        
                        for(var k in infoDara){
                            var dataDate = new Date(infoDara[k]["TGL"]);
                            for(var m in infoDara[k]){
                                if(m == "TGL"){
                                    continue;
                                }
                                if(RAW_DATA[j][Number(dataDate.getDate())-1] != undefined ){
                                    RAW_DATA[j][Number(dataDate.getDate())-1][m] = infoDara[k][m] == "null" ||  infoDara[k][m] == null ? "-" : infoDara[k][m];
                                }

                                if(Total[m] == undefined ){
                                    Total[m] = 0;
                                    Count[m] = 0;
                                }
                                
                                if(infoDara[k][m] != null && infoDara[k][m] !="null"){
                                    dataBuild[i][j][k][m] = Number(infoDara[k][m]).toFixed(2)
                                    Total[m] += Number(infoDara[k][m]);
                                    Count[m]++;
                                    Rata[m] = Number(Total[m] / Count[m]).toFixed(2);
                                    Total[m] = Number(Total[m].toFixed(2));
                                }else{
                                    dataBuild[i][j][k][m] = "-";
                                }
                            }
                        }
                        dataBuild[i][j] = RAW_DATA[j];
                        dataBuild[i]["TOTAL_"+j] = {JUMLAH : Total, RATA : Rata};
                    }
                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}