import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY = false;
export default {
    async dummyData(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01-01" :{
                    "00.00" : {
                        "RAW_2" : {
                            "DATA_ANGIN" 		        : 0,
                            "ARAH_ANGIN" 		        : 0,
                            "KEC_ANGIN"   		        : 0,
                            "PENGLIHATAN_MENDATAR" 	    : 0,
                            "PENGENAL_DATA"		        : "-",
                            "KEADAAN"		            : "-",
                            "CUACA_LALU_1"		        : "-",
                            "CUACA_LALU_2"		        : "-"
                        },
                        "RAW_3" : {
                            "DERAJAT_PANAS"		        : 0,
                            "KOREKSI_QFF_1"		        : 0,
                            "KOREKSI_QFE_2"		        : 0,
                            "TEKANAN_UDARA"		        : 0,
                            "QFF_1"			            : 0,
                            "QFE_2"			            : 0,
                            "PERUBAHAN_TEKANAN"	        : 0,
                            "3_JAM"			            : 0,
                            "24_JAM"		            : 0
                        },
                        "RAW_4" : {
                            "SU_BOLA_KERING" 	        : 0,
                            "SU_TITIK_EMBUN"	        : 0,
                            "SU_BOLA_BASAH"		        : 0,
                            "SU_LEMBAB_NISBI"	        : 0,
                            "SE_MAKSIMUM"		        : 0,
                            "SE_MINIMUM"		        : 0
                        },
                        "RAW_5" : {
                            "PENGENAL_DATA_HUJAN" 		: "-",
                            "HUJAN_TAKARAN_TERAKHIR"	: 0,
                            "HUJAN_6_JAM_LALU"		    : 0,
                            "HUJAN_24_JAM_LALU"		    : 0,
                        },
                        "RAW_6" : {
                            "AR_AWAN_RENDAH"		    : "-",
                            "AR_ARAH_SUDUT_ELEVASI"		: 0,
                            "AR_AWAN_MENENGAH"		    : "0",
                            "AR_ARAH"			        : 0,
                            "AR_TINGGI"			        : 0,
                            "BLTA"				        : 0,
                            "AT_TINGGI_DASAR"		    : 0,
                            "AT_TINGGI_PUNCAK"		    : 0,
                            "AT_BANYAK"			        : 0,
                            "AT_AWAN_TINGGI"			: "-",
                            "AT_ARAH"			        : 0,
                            "AT_TINGGI"			        : 0
                        },
                        "RAW_7" : {
                            "JENIS" 			        : "-",
                            "TINGGI"			        : 0,
                            "BANYAK"			        : 0
                        },
                        "RAW_8" : {
                            "DATA_PENGUAPAN"		    : "-",
                            "PENGUAPAN_24_JAM_LALU"		: 0,
                            "RADIASI_24_JAM_LALU"		: 0,
                            "PENYINARAN_MATAHARI"		: 0
                        },
                        "RAW_9" : {
                            "KEADAAN_TANAH" 		    : "",
                            "CATATAN"			        : "",
                        },
                        "RAW_10" : {
                            "PARAF" 			        : "",
                            "NAMA_TERANG"			    : ""
                        },
                    },
                    "01.00" : {
                        "RAW_2" : {
                            "DATA_ANGIN" 		        : 0,
                            "ARAH_ANGIN" 		        : 0,
                            "KEC_ANGIN"   		        : 0,
                            "PENGLIHATAN_MENDATAR" 	    : 0,
                            "PENGENAL_DATA"		        : "-",
                            "KEADAAN"		            : "-",
                            "CUACA_LALU_1"		        : "-",
                            "CUACA_LALU_2"		        : "-"
                        },
                        "RAW_3" : {
                            "DERAJAT_PANAS"		        : 0,
                            "KOREKSI_QFF_1"		        : 0,
                            "KOREKSI_QFF_2"		        : 0,
                            "TEKANAN_UDARA"		        : 0,
                            "QFF_1"			            : 0,
                            "QFF_2"			            : 0,
                            "PERUBAHAN_TEKANAN"	        : 0,
                            "3_JAM"			            : 0,
                            "24_JAM"		            : 0
                        },
                        "RAW_4" : {
                            "SU_BOLA_KERING" 	        : 0,
                            "SU_TITIK_EMBUN"	        : 0,
                            "SU_BOLA_BASAH"		        : 0,
                            "SU_LEMBAB_NISBI"	        : 0,
                            "SE_MAKSIMUM"		        : 0,
                            "SE_MINIMUM"		        : 0
                        },
                        "RAW_5" : {
                            "PENGENAL_DATA_HUJAN" 		: "-",
                            "HUJAN_TAKARAN_TERAKHIR"	: 0,
                            "HUJAN_6_JAM_LALU"		    : 0,
                            "HUJAN_24_JAM_LALU"		    : 0,
                        },
                        "RAW_6" : {
                            "AR_AWAN_RENDAH"		    : "-",
                            "AR_ARAH_SUDUT_ELEVASI"		: 0,
                            "AR_AWAN_MENENGAH"		    : "0",
                            "AR_ARAH"			        : 0,
                            "AR_TINGGI"			        : 0,
                            "BLTA"				        : 0,
                            "AT_TINGGI_DASAR"		    : 0,
                            "AT_TINGGI_PUNCAK"		    : 0,
                            "AT_BANYAK"			        : 0,
                            "AT_AWAN_TINGGI"			: "-",
                            "AT_ARAH"			        : 0,
                            "AT_TINGGI"			        : 0
                        },
                        "RAW_7" : {
                            "JENIS" 			        : "-",
                            "TINGGI"			        : 0,
                            "BANYAK"			        : 0
                        },
                        "RAW_8" : {
                            "DATA_PENGUAPAN"		    : "-",
                            "PENGUAPAN_24_JAM_LALU"		: 0,
                            "RADIASI_24_JAM_LALU"		: 0,
                            "PENYINARAN_MATAHARI"		: 0
                        },
                        "RAW_9" : {
                            "KEADAAN_TANAH" 		    : "",
                            "CATATAN"			        : "",
                        },
                        "RAW_10" : {
                            "PARAF" 			        : "",
                            "NAMA_TERANG"			    : ""
                        },
                    },
                    "02.00" : {
                        "RAW_2" : {
                            "DATA_ANGIN" 		        : 0,
                            "ARAH_ANGIN" 		        : 0,
                            "KEC_ANGIN"   		        : 0,
                            "PENGLIHATAN_MENDATAR" 	    : 0,
                            "PENGENAL_DATA"		        : "-",
                            "KEADAAN"		            : "-",
                            "CUACA_LALU_1"		        : "-",
                            "CUACA_LALU_2"		        : "-"
                        },
                        "RAW_3" : {
                            "DERAJAT_PANAS"		        : 0,
                            "KOREKSI_QFF_1"		        : 0,
                            "KOREKSI_QFF_2"		        : 0,
                            "TEKANAN_UDARA"		        : 0,
                            "QFF_1"			            : 0,
                            "QFF_2"			            : 0,
                            "PERUBAHAN_TEKANAN"	        : 0,
                            "3_JAM"			            : 0,
                            "24_JAM"		            : 0
                        },
                        "RAW_4" : {
                            "SU_BOLA_KERING" 	        : 0,
                            "SU_TITIK_EMBUN"	        : 0,
                            "SU_BOLA_BASAH"		        : 0,
                            "SU_LEMBAB_NISBI"	        : 0,
                            "SE_MAKSIMUM"		        : 0,
                            "SE_MINIMUM"		        : 0
                        },
                        "RAW_5" : {
                            "PENGENAL_DATA_HUJAN" 		: "-",
                            "HUJAN_TAKARAN_TERAKHIR"	: 0,
                            "HUJAN_6_JAM_LALU"		    : 0,
                            "HUJAN_24_JAM_LALU"		    : 0,
                        },
                        "RAW_6" : {
                            "AR_AWAN_RENDAH"		    : "-",
                            "AR_ARAH_SUDUT_ELEVASI"		: 0,
                            "AR_AWAN_MENENGAH"		    : "0",
                            "AR_ARAH"			        : 0,
                            "AR_TINGGI"			        : 0,
                            "BLTA"				        : 0,
                            "AT_TINGGI_DASAR"		    : 0,
                            "AT_TINGGI_PUNCAK"		    : 0,
                            "AT_BANYAK"			        : 0,
                            "AT_AWAN_TINGGI"			: "-",
                            "AT_ARAH"			        : 0,
                            "AT_TINGGI"			        : 0
                        },
                        "RAW_7" : {
                            "JENIS" 			        : "-",
                            "TINGGI"			        : 0,
                            "BANYAK"			        : 0
                        },
                        "RAW_8" : {
                            "DATA_PENGUAPAN"		    : "-",
                            "PENGUAPAN_24_JAM_LALU"		: 0,
                            "RADIASI_24_JAM_LALU"		: 0,
                            "PENYINARAN_MATAHARI"		: 0
                        },
                        "RAW_9" : {
                            "KEADAAN_TANAH" 		    : "",
                            "CATATAN"			        : "",
                        },
                        "RAW_10" : {
                            "PARAF" 			        : "",
                            "NAMA_TERANG"			    : ""
                        },
                    },
                    "03.00" : {
                        "RAW_2" : {
                            "DATA_ANGIN" 		        : 0,
                            "ARAH_ANGIN" 		        : 0,
                            "KEC_ANGIN"   		        : 0,
                            "PENGLIHATAN_MENDATAR" 	    : 0,
                            "PENGENAL_DATA"		        : "-",
                            "KEADAAN"		            : "-",
                            "CUACA_LALU_1"		        : "-",
                            "CUACA_LALU_2"		        : "-"
                        },
                        "RAW_3" : {
                            "DERAJAT_PANAS"		        : 0,
                            "KOREKSI_QFF_1"		        : 0,
                            "KOREKSI_QFF_2"		        : 0,
                            "TEKANAN_UDARA"		        : 0,
                            "QFF_1"			            : 0,
                            "QFF_2"			            : 0,
                            "PERUBAHAN_TEKANAN"	        : 0,
                            "3_JAM"			            : 0,
                            "24_JAM"		            : 0
                        },
                        "RAW_4" : {
                            "SU_BOLA_KERING" 	        : 0,
                            "SU_TITIK_EMBUN"	        : 0,
                            "SU_BOLA_BASAH"		        : 0,
                            "SU_LEMBAB_NISBI"	        : 0,
                            "SE_MAKSIMUM"		        : 0,
                            "SE_MINIMUM"		        : 0
                        },
                        "RAW_5" : {
                            "PENGENAL_DATA_HUJAN" 		: "-",
                            "HUJAN_TAKARAN_TERAKHIR"	: 0,
                            "HUJAN_6_JAM_LALU"		    : 0,
                            "HUJAN_24_JAM_LALU"		    : 0,
                        },
                        "RAW_6" : {
                            "AR_AWAN_RENDAH"		    : "-",
                            "AR_ARAH_SUDUT_ELEVASI"		: 0,
                            "AR_AWAN_MENENGAH"		    : "0",
                            "AR_ARAH"			        : 0,
                            "AR_TINGGI"			        : 0,
                            "BLTA"				        : 0,
                            "AT_TINGGI_DASAR"		    : 0,
                            "AT_TINGGI_PUNCAK"		    : 0,
                            "AT_BANYAK"			        : 0,
                            "AT_AWAN_TINGGI"			: "-",
                            "AT_ARAH"			        : 0,
                            "AT_TINGGI"			        : 0
                        },
                        "RAW_7" : {
                            "JENIS" 			        : "-",
                            "TINGGI"			        : 0,
                            "BANYAK"			        : 0
                        },
                        "RAW_8" : {
                            "DATA_PENGUAPAN"		    : "-",
                            "PENGUAPAN_24_JAM_LALU"		: 0,
                            "RADIASI_24_JAM_LALU"		: 0,
                            "PENYINARAN_MATAHARI"		: 0
                        },
                        "RAW_9" : {
                            "KEADAAN_TANAH" 		    : "",
                            "CATATAN"			        : "",
                        },
                        "RAW_10" : {
                            "PARAF" 			        : "",
                            "NAMA_TERANG"			    : ""
                        },
                    },
                    "04.00" : {
                        "RAW_2" : {
                            "DATA_ANGIN" 		        : 0,
                            "ARAH_ANGIN" 		        : 0,
                            "KEC_ANGIN"   		        : 0,
                            "PENGLIHATAN_MENDATAR" 	    : 0,
                            "PENGENAL_DATA"		        : "-",
                            "KEADAAN"		            : "-",
                            "CUACA_LALU_1"		        : "-",
                            "CUACA_LALU_2"		        : "-"
                        },
                        "RAW_3" : {
                            "DERAJAT_PANAS"		        : 0,
                            "KOREKSI_QFF_1"		        : 0,
                            "KOREKSI_QFF_2"		        : 0,
                            "TEKANAN_UDARA"		        : 0,
                            "QFF_1"			            : 0,
                            "QFF_2"			            : 0,
                            "PERUBAHAN_TEKANAN"	        : 0,
                            "3_JAM"			            : 0,
                            "24_JAM"		            : 0
                        },
                        "RAW_4" : {
                            "SU_BOLA_KERING" 	        : 0,
                            "SU_TITIK_EMBUN"	        : 0,
                            "SU_BOLA_BASAH"		        : 0,
                            "SU_LEMBAB_NISBI"	        : 0,
                            "SE_MAKSIMUM"		        : 0,
                            "SE_MINIMUM"		        : 0
                        },
                        "RAW_5" : {
                            "PENGENAL_DATA_HUJAN" 		: "-",
                            "HUJAN_TAKARAN_TERAKHIR"	: 0,
                            "HUJAN_6_JAM_LALU"		    : 0,
                            "HUJAN_24_JAM_LALU"		    : 0,
                        },
                        "RAW_6" : {
                            "AR_AWAN_RENDAH"		    : "-",
                            "AR_ARAH_SUDUT_ELEVASI"		: 0,
                            "AR_AWAN_MENENGAH"		    : "0",
                            "AR_ARAH"			        : 0,
                            "AR_TINGGI"			        : 0,
                            "BLTA"				        : 0,
                            "AT_TINGGI_DASAR"		    : 0,
                            "AT_TINGGI_PUNCAK"		    : 0,
                            "AT_BANYAK"			        : 0,
                            "AT_AWAN_TINGGI"			: "-",
                            "AT_ARAH"			        : 0,
                            "AT_TINGGI"			        : 0
                        },
                        "RAW_7" : {
                            "JENIS" 			        : "-",
                            "TINGGI"			        : 0,
                            "BANYAK"			        : 0
                        },
                        "RAW_8" : {
                            "DATA_PENGUAPAN"		    : "-",
                            "PENGUAPAN_24_JAM_LALU"		: 0,
                            "RADIASI_24_JAM_LALU"		: 0,
                            "PENYINARAN_MATAHARI"		: 0
                        },
                        "RAW_9" : {
                            "KEADAAN_TANAH" 		    : "",
                            "CATATAN"			        : "",
                        },
                        "RAW_10" : {
                            "PARAF" 			        : "",
                            "NAMA_TERANG"			    : ""
                        },
                    },
                    "05.00" : {
                        "RAW_2" : {
                            "DATA_ANGIN" 		        : 0,
                            "ARAH_ANGIN" 		        : 0,
                            "KEC_ANGIN"   		        : 0,
                            "PENGLIHATAN_MENDATAR" 	    : 0,
                            "PENGENAL_DATA"		        : "-",
                            "KEADAAN"		            : "-",
                            "CUACA_LALU_1"		        : "-",
                            "CUACA_LALU_2"		        : "-"
                        },
                        "RAW_3" : {
                            "DERAJAT_PANAS"		        : 0,
                            "KOREKSI_QFF_1"		        : 0,
                            "KOREKSI_QFF_2"		        : 0,
                            "TEKANAN_UDARA"		        : 0,
                            "QFF_1"			            : 0,
                            "QFF_2"			            : 0,
                            "PERUBAHAN_TEKANAN"	        : 0,
                            "3_JAM"			            : 0,
                            "24_JAM"		            : 0
                        },
                        "RAW_4" : {
                            "SU_BOLA_KERING" 	        : 0,
                            "SU_TITIK_EMBUN"	        : 0,
                            "SU_BOLA_BASAH"		        : 0,
                            "SU_LEMBAB_NISBI"	        : 0,
                            "SE_MAKSIMUM"		        : 0,
                            "SE_MINIMUM"		        : 0
                        },
                        "RAW_5" : {
                            "PENGENAL_DATA_HUJAN" 		: "-",
                            "HUJAN_TAKARAN_TERAKHIR"	: 0,
                            "HUJAN_6_JAM_LALU"		    : 0,
                            "HUJAN_24_JAM_LALU"		    : 0,
                        },
                        "RAW_6" : {
                            "AR_AWAN_RENDAH"		    : "-",
                            "AR_ARAH_SUDUT_ELEVASI"		: 0,
                            "AR_AWAN_MENENGAH"		    : "0",
                            "AR_ARAH"			        : 0,
                            "AR_TINGGI"			        : 0,
                            "BLTA"				        : 0,
                            "AT_TINGGI_DASAR"		    : 0,
                            "AT_TINGGI_PUNCAK"		    : 0,
                            "AT_BANYAK"			        : 0,
                            "AT_AWAN_TINGGI"			: "-",
                            "AT_ARAH"			        : 0,
                            "AT_TINGGI"			        : 0
                        },
                        "RAW_7" : {
                            "JENIS" 			        : "-",
                            "TINGGI"			        : 0,
                            "BANYAK"			        : 0
                        },
                        "RAW_8" : {
                            "DATA_PENGUAPAN"		    : "-",
                            "PENGUAPAN_24_JAM_LALU"		: 0,
                            "RADIASI_24_JAM_LALU"		: 0,
                            "PENYINARAN_MATAHARI"		: 0
                        },
                        "RAW_9" : {
                            "KEADAAN_TANAH" 		    : "",
                            "CATATAN"			        : "",
                        },
                        "RAW_10" : {
                            "PARAF" 			        : "",
                            "NAMA_TERANG"			    : ""
                        },
                    },
                    "06.00" : {
                        "RAW_2" : {
                            "DATA_ANGIN" 		        : 0,
                            "ARAH_ANGIN" 		        : 0,
                            "KEC_ANGIN"   		        : 0,
                            "PENGLIHATAN_MENDATAR" 	    : 0,
                            "PENGENAL_DATA"		        : "-",
                            "KEADAAN"		            : "-",
                            "CUACA_LALU_1"		        : "-",
                            "CUACA_LALU_2"		        : "-"
                        },
                        "RAW_3" : {
                            "DERAJAT_PANAS"		        : 0,
                            "KOREKSI_QFF_1"		        : 0,
                            "KOREKSI_QFF_2"		        : 0,
                            "TEKANAN_UDARA"		        : 0,
                            "QFF_1"			            : 0,
                            "QFF_2"			            : 0,
                            "PERUBAHAN_TEKANAN"	        : 0,
                            "3_JAM"			            : 0,
                            "24_JAM"		            : 0
                        },
                        "RAW_4" : {
                            "SU_BOLA_KERING" 	        : 0,
                            "SU_TITIK_EMBUN"	        : 0,
                            "SU_BOLA_BASAH"		        : 0,
                            "SU_LEMBAB_NISBI"	        : 0,
                            "SE_MAKSIMUM"		        : 0,
                            "SE_MINIMUM"		        : 0
                        },
                        "RAW_5" : {
                            "PENGENAL_DATA_HUJAN" 		: "-",
                            "HUJAN_TAKARAN_TERAKHIR"	: 0,
                            "HUJAN_6_JAM_LALU"		    : 0,
                            "HUJAN_24_JAM_LALU"		    : 0,
                        },
                        "RAW_6" : {
                            "AR_AWAN_RENDAH"		    : "-",
                            "AR_ARAH_SUDUT_ELEVASI"		: 0,
                            "AR_AWAN_MENENGAH"		    : "0",
                            "AR_ARAH"			        : 0,
                            "AR_TINGGI"			        : 0,
                            "BLTA"				        : 0,
                            "AT_TINGGI_DASAR"		    : 0,
                            "AT_TINGGI_PUNCAK"		    : 0,
                            "AT_BANYAK"			        : 0,
                            "AT_AWAN_TINGGI"			: "-",
                            "AT_ARAH"			        : 0,
                            "AT_TINGGI"			        : 0
                        },
                        "RAW_7" : {
                            "JENIS" 			        : "-",
                            "TINGGI"			        : 0,
                            "BANYAK"			        : 0
                        },
                        "RAW_8" : {
                            "DATA_PENGUAPAN"		    : "-",
                            "PENGUAPAN_24_JAM_LALU"		: 0,
                            "RADIASI_24_JAM_LALU"		: 0,
                            "PENYINARAN_MATAHARI"		: 0
                        },
                        "RAW_9" : {
                            "KEADAAN_TANAH" 		    : "",
                            "CATATAN"			        : "",
                        },
                        "RAW_10" : {
                            "PARAF" 			        : "",
                            "NAMA_TERANG"			    : ""
                        },
                    },
                    "07.00" : {
                        "RAW_2" : {
                            "DATA_ANGIN" 		        : 0,
                            "ARAH_ANGIN" 		        : 0,
                            "KEC_ANGIN"   		        : 0,
                            "PENGLIHATAN_MENDATAR" 	    : 0,
                            "PENGENAL_DATA"		        : "-",
                            "KEADAAN"		            : "-",
                            "CUACA_LALU_1"		        : "-",
                            "CUACA_LALU_2"		        : "-"
                        },
                        "RAW_3" : {
                            "DERAJAT_PANAS"		        : 0,
                            "KOREKSI_QFF_1"		        : 0,
                            "KOREKSI_QFF_2"		        : 0,
                            "TEKANAN_UDARA"		        : 0,
                            "QFF_1"			            : 0,
                            "QFF_2"			            : 0,
                            "PERUBAHAN_TEKANAN"	        : 0,
                            "3_JAM"			            : 0,
                            "24_JAM"		            : 0
                        },
                        "RAW_4" : {
                            "SU_BOLA_KERING" 	        : 0,
                            "SU_TITIK_EMBUN"	        : 0,
                            "SU_BOLA_BASAH"		        : 0,
                            "SU_LEMBAB_NISBI"	        : 0,
                            "SE_MAKSIMUM"		        : 0,
                            "SE_MINIMUM"		        : 0
                        },
                        "RAW_5" : {
                            "PENGENAL_DATA_HUJAN" 		: "-",
                            "HUJAN_TAKARAN_TERAKHIR"	: 0,
                            "HUJAN_6_JAM_LALU"		    : 0,
                            "HUJAN_24_JAM_LALU"		    : 0,
                        },
                        "RAW_6" : {
                            "AR_AWAN_RENDAH"		    : "-",
                            "AR_ARAH_SUDUT_ELEVASI"		: 0,
                            "AR_AWAN_MENENGAH"		    : "0",
                            "AR_ARAH"			        : 0,
                            "AR_TINGGI"			        : 0,
                            "BLTA"				        : 0,
                            "AT_TINGGI_DASAR"		    : 0,
                            "AT_TINGGI_PUNCAK"		    : 0,
                            "AT_BANYAK"			        : 0,
                            "AT_AWAN_TINGGI"			: "-",
                            "AT_ARAH"			        : 0,
                            "AT_TINGGI"			        : 0
                        },
                        "RAW_7" : {
                            "JENIS" 			        : "-",
                            "TINGGI"			        : 0,
                            "BANYAK"			        : 0
                        },
                        "RAW_8" : {
                            "DATA_PENGUAPAN"		    : "-",
                            "PENGUAPAN_24_JAM_LALU"		: 0,
                            "RADIASI_24_JAM_LALU"		: 0,
                            "PENYINARAN_MATAHARI"		: 0
                        },
                        "RAW_9" : {
                            "KEADAAN_TANAH" 		    : "-",
                            "CATATAN"			        : "-",
                        },
                        "RAW_10" : {
                            "PARAF" 			        : "-",
                            "NAMA_TERANG"			    : "-"
                        },
                    },
                },
            }

            var no=0;
            for(var i in datas){
                var dataIndex = datas[i];
                for(var iTime in dataIndex){
                    datas[i][iTime]["index"] = no;
                    no++;
                }
            }
            resolve(datas);
        });
    },

    async storeDataME_48(WMO_ID,START_DATE,END_DATE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_48(WMO_ID,START_DATE,END_DATE);
            var data = axios.get(url, Helper.getConfig());
            resolve(data);
        });
    },

    async callDataME_48(WMO_ID,START_DATE,END_DATE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY == true){
                var datas = await this.dummyData();
                resolve(datas);
            }else{
                var thisData = await this.storeDataME_48(WMO_ID,START_DATE,END_DATE);
                var datas = thisData.data;
                for(var i in datas){
                    var no=0;
                    var dataIndex = datas[i];
                    for(var iTime in dataIndex){
                        datas[i][iTime]["index"] = no;
                        no++;
                    }
                }
                resolve(datas);
            }
        });
    }
}