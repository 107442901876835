import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import decodeSandiMETAR from "./DecodeSandiMETAR";
import moment from 'moment'

export default {
    async storeDataMETAR(WMO_ID,START_DATE,END_DATE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_METAR(WMO_ID,START_DATE,END_DATE);
            var data = axios.get(url, Helper.getConfig());
            resolve(data);
        });
    },

    async callDataMETAR(WMO_ID,START_DATE,END_DATE){
        return  new Promise(async (resolve) => {
            var thisData = await this.storeDataMETAR(WMO_ID,START_DATE,END_DATE);
            var datas = thisData.data;
            datas = datas.map(data => {
                const [tahun, bulan, tanggal] = data.data_timestamp.split("T")[0].split("-");
                const decoded_metar_speci = decodeSandiMETAR(data.encoded_metar_speci, tahun, bulan);
                return {
                    ...data,
                    decoded_metar_speci,
                };
            });
            resolve(datas);
        });
    }
}
