import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_CLIMAT = false;
export default {
    async dummyDataCLIMAT(){
        return  new Promise((resolve) => {
            var datas = [
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"},
                {"TGL" : "2022-01-01" , "VALUES" : "1212 teb \t fffff \t   enter \n 1212 tab \t fffff teb \t   enter \n"}
            ]
            resolve(datas);
        });
    },

    async storeDataCLIMAT(STATION_ID,PERIODE){
        return new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_CLIMAT(STATION_ID,PERIODE);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataCLIMAT(STATION_ID,PERIODE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_CLIMAT == true){
                var datas = await this.dummyDataCLIMAT();
                resolve(datas);
            }else{
                var thisData = await this.storeDataCLIMAT(STATION_ID,PERIODE);
                var datas = [{"TGL" : PERIODE , "VALUES" : thisData.data}];
                resolve(datas);
            }
        });
    }
}