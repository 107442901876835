<template>
  <div style="margin: 25px">
    <div v-for="(item, periode) in dataPDF.data" :key="periode">
      <div slot="pdf-content">
        <div style="float: right; font-size: 10px; padding: 10px">Halaman 1</div>
        <div style="clear: both"></div>
        <div class="title-report-FKIKLIM71">
          {{ dataPDF.title }}
          <div v-if="dataPDF.station != undefined">{{ dataPDF.station[0].station_name }}</div>
        </div>
        <div v-if="dataPDF.station != undefined">
          <div style="font-size: 12px; margin-top: 20px; margin-bottom: 20px; color: #000">
            <div style="float: left; min-width: 50%">
              <div style="float: left; width: 120px">BULAN</div>
              <div style="float: left; width: 20px">:</div>
              <div style="float: left; min-width: 200px">{{ dataPDF.bulan }}</div>
            </div>
            <div style="float: right; min-width: 50%">
              <div style="float: right; min-width: 100px">{{ dataPDF.station[0].current_latitude }} {{ Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS" }}</div>
              <div style="float: right; width: 20px">:</div>
              <div style="float: right; width: 120px">LINTANG</div>
            </div>
            <div style="clear: both"></div>

            <div style="float: left; min-width: 50%">
              <div style="float: left; width: 120px">TAHUN</div>
              <div style="float: left; width: 20px">:</div>
              <div style="float: left; min-width: 200px">{{ dataPDF.tahun }}</div>
            </div>
            <div style="float: right; min-width: 50%">
              <div style="float: right; min-width: 100px">{{ dataPDF.station[0].current_longitude }} BT</div>
              <div style="float: right; width: 20px">:</div>
              <div style="float: right; width: 120px">BUJUR</div>
            </div>
            <div style="clear: both"></div>

            <div style="float: left; min-width: 50%">
              <div style="float: left; width: 120px"></div>
              <div style="float: left; width: 20px"></div>
              <div style="float: left; min-width: 200px"></div>
            </div>
            <div style="float: right; min-width: 50%">
              <div style="float: right; min-width: 100px">{{ dataPDF.station[0].station_elevation }} m</div>
              <div style="float: right; width: 20px">:</div>
              <div style="float: right; width: 120px">ELEVASI</div>
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
      <div slot="pdf-content">
        <table class="table-report-FKIKLIM71" style="margin-top: 10px" border="0">
          <thead>
            <tr>
              <td rowspan="2">TGL</td>
              <td colspan="6">TEMPERATUR <sup>o</sup>C</td>
              <td rowspan="2">CURAH HUJAN DITAKAR JAM 07.00 (mm)</td>
              <td rowspan="2">PENYINARAN MATAHARI (%) 08.00-16.00</td>
              <td rowspan="2">PERISTIWA CUACA KHUSUS</td>
            </tr>
            <tr>
              <td style="border-top: none">07.00</td>
              <td style="border-top: none">13.00</td>
              <td style="border-top: none">18.00</td>
              <td style="border-top: none">RATA<sup>2</sup></td>
              <td style="border-top: none">MAX</td>
              <td style="border-top: none; border-right: none !important">MIN</td>
            </tr>
            <tr>
              <td style="border-top: none; width: 100px"></td>
              <td style="border-top: none; width: 100px">1</td>
              <td style="border-top: none; width: 100px">2</td>
              <td style="border-top: none; width: 100px">3</td>
              <td style="border-top: none; width: 100px">4</td>
              <td style="border-top: none; width: 100px">5</td>
              <td style="border-top: none; width: 100px">6</td>
              <td style="border-top: none; width: 100px">7</td>
              <td style="border-top: none; width: 100px">8</td>
              <td style="border-top: none; width: 100px">9</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(raw, indexRaw) in item.RAW_1" :key="indexRaw">
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(indexRaw + 1) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL1"]) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL2"]) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL3"]) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL4"]) }}</td>
              <td style="border-top: none; text-align: right">{{ setValue(raw["COL5"], "MAX") }}</td>
              <td style="border-top: none; text-align: right">{{ setValue(raw["COL6"], "MIN") }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL7"]) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL8"]) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL9"]) }}</td>
            </tr>
            <tr v-for="n in 31 - item.RAW_1.length" :key="n + 'N'">
              <td style="border-top: none !important">{{ n + item.RAW_1.length }}</td>
              <td style="border-top: none !important"></td>
              <td style="border-top: none !important"></td>
              <td style="border-top: none !important"></td>
              <td style="border-top: none !important"></td>
              <td style="border-top: none !important"></td>
              <td style="border-top: none !important"></td>
              <td style="border-top: none !important"></td>
              <td style="border-top: none !important"></td>
              <td style="border-top: none !important"></td>
            </tr>
            <tr>
              <td style="border-top: none; text-align: left">Jumlah</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH.COL1) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH.COL2) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH.COL3) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH.COL4) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH.COL5) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH.COL6) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH.COL7) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH.COL8) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.JUMLAH.COL9) }}</td>
            </tr>
            <tr>
              <td style="border-top: none; text-align: left">Rata<sup>2</sup></td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA.COL1) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA.COL2) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA.COL3) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA.COL4) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA.COL5) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA.COL6) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA.COL7) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.RATA.COL8) }}</td>
              <td style="border-top: none; text-align: right">{{ item.TOTAL_RAW_1.RATA.COL9 }}</td>
            </tr>
            <tr>
              <td style="border-top: none; text-align: left" colspan="5">T Min abs</td>
              <td style="border-top: none; text-align: right">{{ MinVal }}<sup>o</sup>C</td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
            </tr>
            <tr>
              <td style="border-top: none; text-align: left" colspan="6">T Max abs</td>
              <td style="border-top: none; text-align: right">{{ MaxVal }}<sup>o</sup>C</td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
            </tr>
            <tr>
              <td style="border-top: none; text-align: left" colspan="7">Hari hujan (>=1 mm)</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_1.HARI_HUJAN) }} hari</td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
            </tr>
            <tr>
              <td style="border-top: none; text-align: right" colspan="10">Fklim 71</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="html2pdf__page-break" />

      <div style="float: right; font-size: 10px; padding: 10px">Halaman 2</div>
      <div style="clear: both"></div>
      <div>
        <div style="font-size: 12px; margin-top: 20px; margin-bottom: 20px">
          <div style="float: left; min-width: 50%">
            <div style="float: left; width: 120px">BULAN</div>
            <div style="float: left; width: 20px">:</div>
            <div style="float: left; min-width: 200px">{{ dataPDF.bulan }}</div>
          </div>
          <div style="float: right; min-width: 50%">
            <div style="float: right; min-width: 100px">{{ dataPDF.station[0].current_latitude }} {{ Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS" }}</div>
            <div style="float: right; width: 20px">:</div>
            <div style="float: right; width: 120px">LINTANG</div>
          </div>
          <div style="clear: both"></div>

          <div style="float: left; min-width: 50%">
            <div style="float: left; width: 120px">TAHUN</div>
            <div style="float: left; width: 20px">:</div>
            <div style="float: left; min-width: 200px">{{ dataPDF.tahun }}</div>
          </div>
          <div style="float: right; min-width: 50%">
            <div style="float: right; min-width: 100px">{{ dataPDF.station[0].current_longitude }} BT</div>
            <div style="float: right; width: 20px">:</div>
            <div style="float: right; width: 120px">BUJUR</div>
          </div>
          <div style="clear: both"></div>

          <div style="float: left; min-width: 50%">
            <div style="float: left; width: 120px"></div>
            <div style="float: left; width: 20px"></div>
            <div style="float: left; min-width: 200px"></div>
          </div>
          <div style="float: right; min-width: 50%">
            <div style="float: right; min-width: 100px">{{ dataPDF.station[0].station_elevation }} m</div>
            <div style="float: right; width: 20px">:</div>
            <div style="float: right; width: 120px">ELEVASI</div>
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
      <div>
        <table class="table-report-FKIKLIM71" style="margin-top: 10px">
          <thead>
            <tr>
              <td rowspan="2">TGL</td>
              <td rowspan="2">TEKANAN UDARA (Dalam mb)</td>
              <td colspan="4">LEMBAB NISBI DALAM %</td>
              <td colspan="4">ANGIN</td>
            </tr>
            <tr>
              <td style="border-top: none">07.00</td>
              <td style="border-top: none">13.00</td>
              <td style="border-top: none">18.00</td>
              <td style="border-top: none">RATA<sup>2</sup></td>
              <td style="border-top: none">KECEPATAN RATA<sup>2</sup> (Km/Jam)</td>
              <td style="border-top: none">ARAH TERBANYAK</td>
              <td style="border-top: none">KECEPATAN TERBESAR (Knots)</td>
              <td style="border-top: none">ARAH</td>
            </tr>
            <tr>
              <td style="border-top: none; width: 100px"></td>
              <td style="border-top: none; width: 100px">10</td>
              <td style="border-top: none; width: 100px">11</td>
              <td style="border-top: none; width: 100px">12</td>
              <td style="border-top: none; width: 100px">13</td>
              <td style="border-top: none; width: 100px">14</td>
              <td style="border-top: none; width: 100px">15</td>
              <td style="border-top: none; width: 100px">16</td>
              <td style="border-top: none; width: 100px">17</td>
              <td style="border-top: none; width: 100px">18</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(raw, indexRaw) in item.RAW_2" :key="indexRaw">
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(indexRaw + 1) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL10"]) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL11"]) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL12"]) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL13"]) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL14"]) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL15"]) }}</td>
              <td style="border-top: none; text-align: right">{{ raw["COL16"] }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(raw["COL17"]) }}</td>
              <td style="border-top: none; text-align: right">{{ raw["COL18"] }}</td>
            </tr>
            <tr v-for="n in 31 - item.RAW_2.length" :key="n + 'N'">
              <td style="border-top: none">{{ n + item.RAW_2.length }}</td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
              <td style="border-top: none"></td>
            </tr>
            <tr>
              <td style="border-top: none; text-align: left">Jumlah</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH.COL10) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH.COL11) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH.COL12) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH.COL13) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH.COL14) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH.COL15) }}</td>
              <td style="border-top: none; text-align: right"></td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.JUMLAH.COL17) }}</td>
              <td style="border-top: none; text-align: right"></td>
            </tr>
            <tr>
              <td style="border-top: none; text-align: left">Rata<sup>2</sup></td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA.COL10) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA.COL11) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA.COL12) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA.COL13) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA.COL14) }}</td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA.COL15) }}</td>
              <td style="border-top: none; text-align: right"></td>
              <td style="border-top: none; text-align: right">{{ fixedValueNumber(item.TOTAL_RAW_2.RATA.COL17) }}</td>
              <td style="border-top: none; text-align: right"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="font-size: 10px">
        <span>Catatan : </span>
        <span style="float: right">Fklim 71</span>
      </div>
      <div>
        <table style="width: 100%; font-size: 12px; margin-top: 30px">
          <tbody>
            <tr>
              <td style="width: 50%; text-align: center">
                <table style="width: 100%">
                  <tbody>
                    <tr>
                      <td style="text-align: left; paddong: 10px">Kolom 4 dan 14</td>
                      <td style="paddong: 10px">
                        <div style="text-align: center; padding-bottom: 3px; paddin-left: 10px">= 2 x 07.00 + 13.00 + 18.00</div>
                        <div style="text-align: center">4</div>
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: left; paddong: 10px">Kolom 8</td>
                      <td style="paddong: 10px; text-align: left">= Rata-rata dari 8 jam</td>
                    </tr>
                    <tr>
                      <td style="text-align: left; paddong: 10px">++)</td>
                      <td style="paddong: 10px; text-align: left">= Arah Terbanyak</td>
                    </tr>
                    <tr>
                      <td style="text-align: left; paddong: 10px">+++)</td>
                      <td style="paddong: 10px; text-align: left">= Kecepatan Terbesar</td>
                    </tr>
                    <tr>
                      <td style="text-align: left; paddong: 10px">++++)</td>
                      <td style="paddong: 10px; text-align: left">= Arah pada saat kecepatan terbesar</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style="width: 50%; text-align: center">
                <div>Pengamat</div>
                <div style="width: 100%; height: 100px"></div>
                <div>{{ item.PENGAMAT }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// var MaxVal  = 0;
// var MinVal  = 0;
export default {
  name: "ReportFKIKLIM71",
  props: {
    dataPDF: Object,
  },
  methods: {
    setValue(values, type) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
        if (type == "MAX") {
          if (this.MaxVal < DataResult) {
            this.MaxVal = DataResult;
          }
        }
        if (type == "MIN") {
          if (this.MinVal == null) {
            this.MinVal = DataResult;
          }
          if (this.MinVal > DataResult) {
            this.MinVal = DataResult;
          }
        }
      }
      return DataResult;
    },

    fixedValueNumber(values) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult;
    },
  },
  data: function () {
    return {
      conter: 0,
      MaxVal: 0,
      MinVal: null,
    };
  },
};
</script>

<style>
.title-report-FKIKLIM71 {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
  text-transform: uppercase;
  color: #000;
}

.FKIKLIM71 td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

/* .table-report-FKIKLIM71{
  width:100%;
} */

.table-report-FKIKLIM71 td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-FKIKLIM71 > thead > tr > td:last-child {
  border-right: solid 0.5px #000 !important;
}

.table-report-FKIKLIM71 > thead > tr > td {
  background: #e0e4e7;
  color: #000;
  border-left: solid 0.5px #000 !important;

  border-bottom: solid 0.5px #000 !important;
}

.table-report-FKIKLIM71 > thead > tr > td {
  text-align: center;
  font-weight: bold;
  padding: 5px !important;
}

.table-report-FKIKLIM71 tbody > tr > td:last-child {
  border-right: solid 0.5px #000;
}

.table-report-FKIKLIM71 tbody > tr > td {
  padding: 5px !important;
  margin: 0px;
  text-align: center;
  color: #000;
  /* border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000; */
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
}
/* 
.table-report-FKIKLIM71  tbody > tr > td:last-of-type {
 border-right:solid 0.5px #000;
} */

.table-report-FKIKLIM71 tbody > tr {
  background-color: #fff;
}

.table-report-FKIKLIM71 tbody > tr:nth-child(even) {
  background-color: #fff;
}

/* .table-report-FKIKLIM71 tr {
  border:none !important;
} */

.table-report-FKIKLIM71 thead :not(.table-code) > td {
  /* padding: 3px; */
  /* border-left:solid 0.5px #FFFFFF;
  border-bottom:solid 0.5px #FFFFFF;
  border-top:solid 0.5px #FFFFFF; */
  border: none;
}

.table-report-FKIKLIM71 thead :not(.table-code) > td:last-of-type {
  /* border-right:solid 0.5px #FFFFFF; */
  border: none;
}

.table-report-FKIKLIM71 {
  font-size: 8.5px !important;
  margin-bottom: 20px !important;
  border-top: solid 0.5px #000000;
}
/* 
.table-report-FKIKLIM71 td{
  max-width: 90px;
  min-width: 50px;
} */
</style>
