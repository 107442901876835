import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_FORMHUJAN = false;
export default {
    async dummyDataFORMHUJAN(){
        return new Promise((resolve) => {
            var datas = {
                "2021-01" : [{
                    "OBSERVER_NAME"     : "BAMBANG", 
                    "OBSERVER_ADDRESS"  : "JAKARTA", 
			        "OBSERVER_RAW" : [
                        {
                            "TGL"           : "2021-01-01",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-02",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-03",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-04",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-05",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-06",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-07",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-08",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-09",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-10",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-11",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-12",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-13",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-14",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-15",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-16",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-17",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-18",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-19",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-20",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-21",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-22",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-23",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-24",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-25",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-26",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-27",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-28",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-29",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-30",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                        {
                            "TGL"           : "2021-01-31",
                            "VALUE"         : 100,
                            "HUJAN"         : 1,
                            "ALAT_RUSAK"    : 0,
                        },
                    ],
                }]
            }

            var dataBuild = {};
            for(var i in datas){
                dataBuild[i] = [];
                for(var j in datas[i]){
                    var data = { 
                        "OBSERVER_NAME" : datas[i][j]["OBSERVER_NAME"], 
                        "OBSERVER_ADDRESS" : datas[i][j]["OBSERVER_ADDRESS"],
                        "OBSERVER_RAW" : {"RAW" : [], "CALCULATION" : {} }
                    }

                    var shortData = datas[i][j]["OBSERVER_RAW"].sort(function(a, b) {
                        return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                    });
                    var NO = 1;
                    var POS = 1;
                    for(var k in shortData){
                        if(data["OBSERVER_RAW"]["CALCULATION"]["COUNT_COL_"+POS] == undefined){
                            data["OBSERVER_RAW"]["CALCULATION"]["COUNT_COL_"+POS] = 0;
                        }
                        data["OBSERVER_RAW"]["RAW"].push(shortData[k]);
                        data["OBSERVER_RAW"]["CALCULATION"]["COUNT_COL_"+POS] += shortData[k].VALUE;

                        if(NO % 10 == 0){
                            POS++;
                        }
                        NO++;
                    }
                    dataBuild[i].push(data);
                }
            }
            datas = dataBuild;
            
            resolve(datas);
        });
    },

    async storeDataFORMHUJAN(STATION_ID,PERIODE){
        return new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_FORMHUJAN(STATION_ID,PERIODE);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataFORMHUJAN(STATION_ID,PERIODE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_FORMHUJAN == true){
                var datas = await this.dummyDataFORMHUJAN();
                resolve(datas);
            }else{
                var thisData = await this.storeDataFORMHUJAN(STATION_ID,PERIODE);
                var datas = thisData.data;

                console.log(datas);
                var dataBuild = {};
                for(var i in datas){
                    dataBuild[i] = [];
                    for(var j in datas[i]){
                        var data = { 
                            "OBSERVER_NAME" : datas[i][j]["OBSERVER_NAME"], 
                            "OBSERVER_ADDRESS" : datas[i][j]["OBSERVER_ADDRESS"],
                            "OBSERVER_RAW" : {"RAW" : [], "CALCULATION" : {} }
                        }

                        var shortData = datas[i][j]["OBSERVER_RAW"].sort(function(a, b) {
                            return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                        });
                        var NO = 1;
                        var POS = 1;

                        var DataCombine=[];
                        for(var iDate=1; iDate<= 31; iDate++){
                            DataCombine.push({
                                "TGL"           : i+"-"+iDate,
                                "VALUE"         : "",
                                "HUJAN"         : "",
                                "ALAT_RUSAK"    : "",
                            });
                        }
                        for(var k in shortData){
                            var thisDate = new Date(shortData[k]["TGL"]);
                            DataCombine[(Number(thisDate.getDate())-1)] = shortData[k];
                        }


                        for(var k in DataCombine){
                            if(data["OBSERVER_RAW"]["CALCULATION"]["COUNT_COL_"+POS] == undefined){
                                data["OBSERVER_RAW"]["CALCULATION"]["COUNT_COL_"+POS] = 0;
                            }
                            data["OBSERVER_RAW"]["RAW"].push(DataCombine[k]);
                            data["OBSERVER_RAW"]["CALCULATION"]["COUNT_COL_"+POS] += DataCombine[k]["VALUE"];

                            if(NO % 10 == 0){
                                POS++;
                            }
                            NO++;
                        }
                        dataBuild[i].push(data);
                    }
                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}