<template>
  <div slot="pdf-content" style="margin:25px; width: 1303px;">
    <div class="title-report-WXREF">{{ dataPDF.title }}</div>
    <div style="width:100%; margin-bottom: 2px;">
      <div class="title-desc-WXREF" style="float:left;">BULAN: {{ dataPDF.bulan }} {{ dataPDF.tahun }}</div>
      <div class="title-desc-WXREF" style="float:right;">Stasiun: {{ dataPDF.station[0].station_name }}</div>
    </div>
    <table class="table-report-WXREF">
      <thead>
        <tr>
          <td rowspan="2">TGL</td>
          <td rowspan="2">MMYYGp</td>
          <td rowspan="2">Iiiiii</td>
          <td rowspan="2">aTTxTxTYnTn</td>
          <td rowspan="2">apPxPxPnPn</td>
          <td rowspan="2">auUxUxUnUn</td>
          <td rowspan="2">arRRRR</td>
          <td rowspan="2" colspan="2">rDrDDfmfm</td>
          <!--  -->
          <td rowspan="1" colspan="2">Dikirim</td>
          <!--  -->
          <td rowspan="2">Stasiun Penerima</td>
          <td rowspan="2">Keterangan</td>
        </tr>
        <tr>
          <!--  -->
          <!--  -->
          <!--  -->
          <!--  -->
          <!--  -->
          <!--  -->
          <!--  -->
          <!--  -->
          <!--  -->
          <td>Tanggal</td>
          <td>Jam Kirim</td>
          <!--  -->
          <!--  -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(TABLE_ROW, i) in dataPDF.data" :key="TABLE_ROW.TGL">
          <td>{{ TABLE_ROW.TGL.split("-")[2] || "-" }}</td>
          <td>{{ TABLE_ROW.COL1 || "-" }}</td>
          <td>{{ TABLE_ROW.COL2 || "-" }}</td>
          <td>{{ TABLE_ROW.COL3 || "-" }}</td>
          <td>{{ TABLE_ROW.COL4 || "-" }}</td>
          <td>{{ TABLE_ROW.COL5 || "-" }}</td>
          <td>{{ TABLE_ROW.COL6 || "-" }}</td>
          <td>{{ TABLE_ROW.COL7 || "-" }}</td>
          <td>{{ TABLE_ROW.COL8 || "-" }}</td>
          <td>{{ TABLE_ROW.COL9 || "-" }}</td>
          <td>{{ TABLE_ROW.COL10 || "-" }}</td>
          <td>{{ TABLE_ROW.COL11 || "-" }}</td>
          <td v-if="i == 0" :rowspan="dataPDF.data.length"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "ReportWXREV",
  props: {
    dataPDF: Object,
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.title-report-WXREF {
  width: 1303px;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
  color: #000000;
}

.title-desc-WXREF {
  margin-bottom: 2px;
  color: #000000;
}

.WXREV td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-WXREF td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-WXREF > thead > tr > td {
  border: solid 0.5px #000000;
  color: #000000;
  text-align: center;
  background-color: #e0e4e7;
}

.table-report-WXREF tbody > tr > td {
  border-left: solid 0.5px #000000;
  border-right: solid 0.5px #000000;
  color: #000000;
  padding: 2px !important;
  margin: 0px;
  text-align: center;
}

.table-report-WXREF tbody > tr:last-child {
  border-bottom: solid 0.5px #000000;
}

.table-report-WXREF td {
  height: min-content;
  width: min-content;
  max-width: calc(100% / 13);
}

.table-report-WXREF {
  font-size: 8.5px !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
  border-collapse: collapse;
  width: 100%;
}
</style>
