<template>
    <div style="margin:20px;">
        <div slot="pdf-content"   v-for="(item, indexItem) in dataPDF.data" :key="indexItem" style="margin:30px;border:solid 1px #ccc;width:100%;padding:10px;">
            <div  slot="pdf-content"  style="white-space: pre-wrap; word-wrap: break-word;font-family: inherit;"  v-html="item.VALUES"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReportCLIMAT',
    props: {
      dataPDF: Object
    },
    methods:{
       
    },
    data: function () {
        return {
         
        };
    },

};
</script>