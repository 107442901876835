<template>
  <div style="margin:25px;">
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <div class="title-report-GUNBELLANI">
        {{ dataPDF.title }}
        <div v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_name }}</div>
      </div>
      <!-- <div>
          <table style="width:100%; font-size:11px; font-weight:bold; width:100%;margin-top:10px;">
            <tbody>
              <tr>
                <td style="width:70%">BULAN : <span>{{dataPDF.bulan}}</span></td>
                <td style="width:30%">GARIS LINTANG&nbsp;&nbsp;: <span  v-if="dataPDF.station.length > 0">{{dataPDF.station[0].current_latitude}}</span></td>
              </tr>
              <tr>
                <td style="width:70%">TAHUN : <span>{{dataPDF.tahun}}</span></td>
                <td style="width:30%">GARIS BUJUR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <span  v-if="dataPDF.station.length > 0">{{dataPDF.station[0].current_longitude}}</span><span></span></td>
              </tr>
              <tr>
                <td style="width:70%">DPL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <span  v-if="dataPDF.station.length > 0">{{dataPDF.station[0].station_elevation}}</span></td>
                <td style="width:30%"></td>
              </tr>
            </tbody>
          </table>
        </div> -->
      <div>
        <div style="font-size:12px;margin-top:20px;margin-bottom:20px;color:#000;">
          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;">BULAN</div>
            <div style="float:left;width:20px;">:</div>
            <div style="float:left;min-width:200px;">{{ dataPDF.bulan }}</div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_latitude }} {{ (Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS")}}</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">LINTANG</div>
          </div>
          <div style="clear:both"></div>

          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;">TAHUN</div>
            <div style="float:left;width:20px;">:</div>
            <div style="float:left;min-width:200px;">{{ dataPDF.tahun }}</div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_longitude }} BT</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">BUJUR</div>
          </div>
          <div style="clear:both"></div>

          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;"></div>
            <div style="float:left;width:20px;"></div>
            <div style="float:left;min-width:200px;"></div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].station_elevation }} m</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">ELEVASI</div>
          </div>
          <div style="clear:both"></div>
        </div>
      </div>

      <div style="margin-top:10px;">
        <table class="table-report-GUNBELLANI">
          <thead>
            <tr>
              <td>TGL</td>
              <td>TINGGI AIR PEMBACAAN I ( H1 ) ml</td>
              <td>TINGGI AIR PEMBACAAN II ( H1 ) ml</td>
              <td>SELISIH PEMBACAAN</td>
              <td>RADIASI MTH ( cal / cm <sup>2</sup>)</td>
              <td>CATATAN</td>
            </tr>
            <tr>
              <td style="border-top:none;width:133px;"></td>
              <td style="border-top:none;width:133px;">1</td>
              <td style="border-top:none;width:133px;">2</td>
              <td style="border-top:none;width:133px;">3</td>
              <td style="border-top:none;width:133px;">4</td>
              <td style="border-top:none;width:133px;">5</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(raw, indexRaw) in item.RAW" :key="indexRaw + '_bodyGUNBELLANI'">
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(indexRaw + 1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL4) }}</td>
              <td style="border-top:none;text-align:left;">{{ raw.COL5 }}</td>
            </tr>
            <tr v-for="n in 31 - item.RAW.length" :key="n + '_bodyEmptyGUNBELLANI'">
              <td style="border-top:none;">{{ n + item.RAW.length }}</td>
              <td style="border-top:none;"></td>
              <td style="border-top:none;"></td>
              <td style="border-top:none;"></td>
              <td style="border-top:none;"></td>
              <td style="border-top:none;"></td>
            </tr>
            <tr>
              <td style="border-top:none;text-align:left;">Jumlah</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL4) }}</td>
              <td style="border-top:none;"></td>
            </tr>
            <tr>
              <td style="border-top:none;text-align:left;">Rata<sup>2</sup></td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL4) }}</td>
              <td style="border-top:none;"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="margin-top:10px;">
        <table style="font-size:12px !important;">
          <tbody>
            <tr>
              <td colspan="3">Keterangan :</td>
            </tr>
            <tr>
              <td style="width:100px;"></td>
              <td>Pembacaan I</td>
              <td>:</td>
              <td>Jam 07.00</td>
            </tr>
            <tr>
              <td style="width:100px;"></td>
              <td>Pembacaan II</td>
              <td>:</td>
              <td>Jam 07.00 ( hari berikutnya )</td>
            </tr>
            <tr>
              <td style="width:100px;"></td>
              <td>Jumlah Radiasi</td>
              <td>:</td>
              <td>( Pembaca II - Pembaca I ) x Koef</td>
            </tr>
            <tr>
              <td style="width:100px;"></td>
              <td>Koefision</td>
              <td>:</td>
              <td>20.8 gram cal / cm<sup>2</sup></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportGUNBELLANI",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult;
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.title-report-GUNBELLANI {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
  text-transform: uppercase;
  color: #000;
}

.GUNBELLANI td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-GUNBELLANI td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-GUNBELLANI > thead > tr > td {
  text-align: center;
  background: #e0e4e7;
  color: #000;
  border-left: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
  padding: 5px !important;
}

.table-report-GUNBELLANI tbody > tr > td {
  padding: 5px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
  color: #000;
}

.table-report-GUNBELLANI tbody > tr > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-GUNBELLANI tr {
  border: none !important;
}

.table-report-GUNBELLANI thead :not(.table-code) > td {
  padding: 5px;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
}

.table-report-GUNBELLANI thead :not(.table-code) > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-GUNBELLANI tbody > tr {
  background-color: #ffffff;
}

.table-report-GUNBELLANI tbody > tr:nth-child(even) {
  background-color: #ffffff;
}

.table-report-GUNBELLANI {
  font-size: 8.5px !important;
  margin-bottom: 20px !important;
  width: 100%;
}

/* .table-report-GUNBELLANI td{
  max-width: 90px;
  height: 27px;
} */
</style>
