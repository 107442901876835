<template>
  <div slot="pdf-content" style="margin:25px;">
      {{dataPDF}}
  </div>               
</template>



<script>
export default {
    name: 'ReportPETIR',
    props: {
      dataPDF: Object
    },
    data: function () {
        return {
         
        };
    },

};
</script>

<style>
.title-report-PETIR{
  width: 1303px;
  padding: 10px;
  text-align: center;
  font-weight: bold  !important;
}


.PETIR td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align:bottom;
}

.table-report-PETIR td.rotation_90 >  div{
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg);    /* Firefox */
  -o-transform: rotate(-90deg);      /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-PETIR > thead > tr > td {
  text-align: center;
}

.table-report-PETIR  tbody > tr > td {
  padding: 3px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
}

.table-report-PETIR  tbody > tr > td:last-of-type {
 border-right:solid 0.5px #000;
}

.table-report-PETIR tr {
  border:none !important;
}

.table-report-PETIR thead :not(.table-code) > td{
  padding: 3.5px;
  border-left:solid 0.5px #000;
  border-bottom:solid 0.5px #000;
  border-top:solid 0.5px #000;
}

.table-report-PETIR thead :not(.table-code) > td:last-of-type{
  border-right:solid 0.5px #000;
}


.table-report-PETIR {
  font-size: 8.5px  !important;
  font-weight: bold;
  margin-bottom: 20px !important;
}

.table-report-PETIR td{
  max-width: 90px;
  height: 30px;
}

</style>