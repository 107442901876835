<template>
  <div style="margin-left:20px; margint-right:20px;margin-top:10px; ">
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <div class="title-report-BAROGRAPH">
        {{ dataPDF.title }}
        <div v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_name }}</div>
      </div>
      <div style="width:1300px;font-size:11px;color:#000;">
        <div style="font-size:12px;margin-top:10px;margin-bottom:5px;">
          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;">BULAN</div>
            <div style="float:left;width:20px;">:</div>
            <div style="float:left;min-width:200px;">{{ dataPDF.bulan }}</div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_latitude }}  {{ (Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS")}}</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">LINTANG</div>
          </div>
          <div style="clear:both"></div>

          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;">TAHUN</div>
            <div style="float:left;width:20px;">:</div>
            <div style="float:left;min-width:200px;">{{ dataPDF.tahun }}</div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_longitude }} BT</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">BUJUR</div>
          </div>
          <div style="clear:both"></div>

          <div style="float:left;min-width:50%;">
            <div style="float:left;width:120px;"></div>
            <div style="float:left;width:20px;"></div>
            <div style="float:left;min-width:200px;"></div>
          </div>
          <div style="float:right;min-width:50%;">
            <div style="float:right;min-width:100px;">{{ dataPDF.station[0].station_elevation }} m</div>
            <div style="float:right;width:20px;">:</div>
            <div style="float:right;width:120px;">ELEVASI</div>
          </div>
          <div style="clear:both"></div>
        </div>
      </div>
      <div style="margin-top:10px;">
        <table class="table-report-BAROGRAPH">
          <thead>
            <tr>
              <td rowspan="2">TGL</td>
              <td colspan="25">JAM PEMBACAAN</td>
            </tr>
            <tr>
              <td style="border-top:none;">07.00</td>
              <td style="border-top:none;">08.00</td>
              <td style="border-top:none;">09.00</td>
              <td style="border-top:none;">10.00</td>
              <td style="border-top:none;">11.00</td>
              <td style="border-top:none;">12.00</td>
              <td style="border-top:none;">13.00</td>
              <td style="border-top:none;">14.00</td>
              <td style="border-top:none;">15.00</td>
              <td style="border-top:none;">16.00</td>
              <td style="border-top:none;">17.00</td>
              <td style="border-top:none;">18.00</td>
              <td style="border-top:none;">19.00</td>
              <td style="border-top:none;">20.00</td>
              <td style="border-top:none;">21.00</td>
              <td style="border-top:none;">22.00</td>
              <td style="border-top:none;">23.00</td>
              <td style="border-top:none;">24.00</td>
              <td style="border-top:none;">01.00</td>
              <td style="border-top:none;">02.00</td>
              <td style="border-top:none;">03.00</td>
              <td style="border-top:none;">04.00</td>
              <td style="border-top:none;">05.00</td>
              <td style="border-top:none;">06.00</td>
              <td style="border-top:none;">Rata<sup>2</sup></td>
            </tr>
            <tr>
              <td style="border-top:none;width:52px;"></td>
              <td style="border-top:none;width:52px;" v-for="i in 25" :key="i + '_headEmptyBAROGRAPH'">{{ i }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(raw, indexRaw) in item.RAW" :key="indexRaw + '_bodyBAROGRAPH'">
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(indexRaw + 1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL4) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL5) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL6) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL7) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL8) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL9) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL10) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL11) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL12) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL13) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL14) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL15) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL16) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL17) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL18) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL19) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL20) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL21) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL22) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL23) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL24) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(raw.COL25) }}</td>
            </tr>
            <tr v-for="i in 31 - item.RAW.length" :key="i + '_bodyEmptyBAROGRAPH'">
              <td style="border-top:none;">{{ i + item.RAW.length }}</td>
              <td v-for="j in 25" :key="j + '_colBodyEmptyBAROGRAPH'"></td>
            </tr>
            <tr>
              <td style="border-top:none;text-align:left;">Max</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL4) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL5) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL6) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL7) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL8) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL9) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL10) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL11) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL12) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL13) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL14) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL15) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL16) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL17) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL18) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL19) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL20) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL21) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL22) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL23) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL24) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MAX_RAW.COL25) }}</td>
            </tr>
            <tr>
              <td style="border-top:none;text-align:left;">Min</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL4) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL5) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL6) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL7) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL8) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL9) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL10) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL11) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL12) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL13) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL14) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL15) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL16) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL17) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL18) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL19) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL20) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL21) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL22) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL23) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL24) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.MIN_RAW.COL25) }}</td>
            </tr>
            <tr>
              <td style="border-top:none;text-align:left;">Rata<sup>2</sup></td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL1) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL2) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL3) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL4) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL5) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL6) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL7) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL8) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL9) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL10) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL11) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL12) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL13) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL14) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL15) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL16) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL17) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL18) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL19) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL20) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL21) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL22) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL23) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL24) }}</td>
              <td style="border-top:none;text-align:right;">{{ fixedValueNumber(item.CALCULATE.RATA.COL25) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportBAROGRAPH",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult;
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.title-report-BAROGRAPH {
  width: 1303px;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
  text-transform: uppercase;
  color: #000;
}

.BAROGRAPH td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-BAROGRAPH td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-BAROGRAPH > thead > tr > td {
  background: #e0e4e7;
  color: #000000;
  border-left: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
}

.table-report-BAROGRAPH tbody > tr > td {
  padding: 3px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
  color: #000;
}

.table-report-BAROGRAPH tbody > tr > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-BAROGRAPH tr {
  border: none !important;
}

.table-report-BAROGRAPH thead :not(.table-code) > td {
  padding: 2.5px;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
}

.table-report-BAROGRAPH thead :not(.table-code) > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-BAROGRAPH tbody > tr {
  background-color: #ffffff;
}

.table-report-BAROGRAPH tbody > tr:nth-child(even) {
  background-color: #ffffff;
}

.table-report-BAROGRAPH {
  font-size: 7.5px !important;
  margin-bottom: 20px !important;
  width: 1303px;
}

/* .table-report-BAROGRAPH td{
  max-width: 90px;
} */
</style>
