import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_THERMOHIGOGRAPH = false;
export default {
    async dummyDataTHERMOHIGOGRAPH(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01": [
                    {
                    "TGL"    : "2021-01",
                    "COL1"   : 1, 
                    "COL2"   : 2,
                    "COL3"   : 3,
                    "COL4"   : 4,
                    "COL5"   : 5,
                    "COL6"   : 6,
                    "COL7"   : 7,
                    "COL8"   : 8,
                    "COL9"   : 9,
                    "COL10"  : 10,
                    "COL11"  : 11,
                    "COL12"  : 12,
                    "COL13"  : 13,
                    "COL14"  : 14,
                    "COL15"  : 15,
                    "COL16"  : 16,
                    "COL17"  : 17,
                    "COL18"  : 18,
                    "COL19"  : 19,
                    "COL20"  : 20,
                    "COL21"  : 21,
                    "COL22"  : 22,
                    "COL23"  : 23,
                    "COL24"  : 24,
                    "COL25"  : 25,
                    "COL26"  : 26,
                    "COL27"  : 27,
                    },
                    {
                    "TGL"    : "2021-03",
                    "COL1"   : 3, 
                    "COL2"   : 2,
                    "COL3"   : 3,
                    "COL4"   : 4,
                    "COL5"   : 5,
                    "COL6"   : 6,
                    "COL7"   : 7,
                    "COL8"   : 8,
                    "COL9"   : 9,
                    "COL10"  : 10,
                    "COL11"  : 11,
                    "COL12"  : 12,
                    "COL13"  : 13,
                    "COL14"  : 14,
                    "COL15"  : 15,
                    "COL16"  : 16,
                    "COL17"  : 17,
                    "COL18"  : 18,
                    "COL19"  : 19,
                    "COL20"  : 20,
                    "COL21"  : 21,
                    "COL22"  : 22,
                    "COL23"  : 23,
                    "COL24"  : 24,
                    "COL25"  : 25,
                    "COL26"  : 26,
                    "COL27"  : 27,
                    },
                    {
                    "TGL"    : "2021-02",
                    "COL1"   : 2, 
                    "COL2"   : 2,
                    "COL3"   : 3,
                    "COL4"   : 4,
                    "COL5"   : 5,
                    "COL6"   : 6,
                    "COL7"   : 7,
                    "COL8"   : 8,
                    "COL9"   : 9,
                    "COL10"  : 10,
                    "COL11"  : 11,
                    "COL12"  : 12,
                    "COL13"  : 13,
                    "COL14"  : 14,
                    "COL15"  : 15,
                    "COL16"  : 16,
                    "COL17"  : 17,
                    "COL18"  : 18,
                    "COL19"  : 19,
                    "COL20"  : 20,
                    "COL21"  : 21,
                    "COL22"  : 22,
                    "COL23"  : 23,
                    "COL24"  : 24,
                    "COL25"  : 25,
                    "COL26"  : 26,
                    "COL27"  : 27,
                    },
                ]
            }

            var dataBuild = {};
            for(var i in datas){
                dataBuild[i] = {"RAW" : [], "CALCULATE" : {} };
                var rawPeriode = datas[i];
                dataBuild[i]["RAW"] = rawPeriode.sort(function(a, b) {
                    return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                });
                
                var Total = {};
                var Count = {};
                var Rata  = {};
                for(var j in rawPeriode){
                    var infoDara = rawPeriode[j];
                    for(var m in infoDara){
                        if(m == "TGL"){
                            continue;
                        }
                        if(Total[m] == undefined ){
                            Total[m] = 0;
                            Count[m] = 0;
                        }
                        Total[m] += infoDara[m];
                        Count[m]++;
                        Rata[m] = Total[m] / Count[m];
                    }
                }
                dataBuild[i]["CALCULATE"] = {JUMLAH : Total, RATA : Rata};
            }
            datas = dataBuild;
            resolve(datas);
        });
    },

    async storeDataTHERMOHIGOGRAPH(STATION_ID,PERIODE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_THERMOHIGOGRAPH(STATION_ID,PERIODE);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataTHERMOHIGOGRAPH(STATION_ID,PERIODE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_THERMOHIGOGRAPH == true){
                var datas = await this.dummyDataTHERMOHIGOGRAPH();
                resolve(datas);
            }else{
                var thisData = await this.storeDataTHERMOHIGOGRAPH(STATION_ID,PERIODE);
                var datas = thisData.data;
               
                var dataBuild = {};
                for(var i in datas){
                    dataBuild[i] = {"RAW" : [], "CALCULATE" : {} };
                    var rawPeriode = datas[i];
                    dataBuild[i]["RAW"] = rawPeriode.sort(function(a, b) {
                        return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                    });
                    
                    var Total = {};
                    var Count = {};
                    var Rata  = {};
                    var RAW_DATA = [];
                    for(var icount = 1; icount <= 31; icount++){
                        RAW_DATA.push({
                            "TGL"    : icount,
                            "COL1"   : "", 
                            "COL2"   : "",
                            "COL3"   : "",
                            "COL4"   : "",
                            "COL5"   : "",
                            "COL6"   : "",
                            "COL7"   : "",
                            "COL8"   : "",
                            "COL9"   : "",
                            "COL10"  : "",
                            "COL11"  : "",
                            "COL12"  : "",
                            "COL13"  : "",
                            "COL14"  : "",
                            "COL15"  : "",
                            "COL16"  : "",
                            "COL17"  : "",
                            "COL18"  : "",
                            "COL19"  : "",
                            "COL20"  : "",
                            "COL21"  : "",
                            "COL22"  : "",
                            "COL23"  : "",
                            "COL24"  : "",
                            "COL25"  : "",
                            "COL26"  : "",
                            "COL27"  : "",
                        });
                    }

                    for(var j in rawPeriode){
                        var infoDara = rawPeriode[j];
                        var dataDate = new Date(infoDara["TGL"]);
                        for(var m in infoDara){
                            if(m == "TGL"){
                                continue;
                            }
                            if(Total[m] == undefined ){
                                Total[m] = 0;
                                Count[m] = 0;
                            }

                            
                            
                            if(infoDara[m] != null && infoDara[m] !="null"){
                                Total[m] += Number(infoDara[m]);
                                Count[m]++;
                                Rata[m] = Number(Total[m] / Count[m]).toFixed(2);
                                Total[m] = Number(Total[m].toFixed(2));
                                dataBuild[i]["RAW"][j][m] = Number(Number(infoDara[m]).toFixed(2));
                                if(RAW_DATA[Number(dataDate.getDate())-1] != undefined ){
                                    RAW_DATA[Number(dataDate.getDate())-1][m] =  Number(Number(infoDara[m]).toFixed(2));// Number(infoDara[m]).toFixed(2);// shortData[k][m] == null || isNaN(Number(shortData[k][m]).toFixed(2))  ? "-" : Number().toFixed(2);
                                }
                            }else{
                                dataBuild[i]["RAW"][j][m] = "-";
                                if(RAW_DATA[Number(dataDate.getDate())-1] != undefined ){
                                    RAW_DATA[Number(dataDate.getDate())-1][m] =  "-";//Number(Number(infoDara[m]).toFixed(2));// Number(infoDara[m]).toFixed(2);// shortData[k][m] == null || isNaN(Number(shortData[k][m]).toFixed(2))  ? "-" : Number().toFixed(2);
                                }
                            }
                        }
                    }
                    dataBuild[i]["RAW"] = RAW_DATA;
                    dataBuild[i]["CALCULATE"] = {JUMLAH : Total, RATA : Rata};
                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}