function derajatToArahCode(derajat) {
    if (derajat < 0 || derajat > 360) {
        throw new Error("invalid derajat input");
    }else if (derajat >= 337.6 || derajat <= 22.5 ) {
        return "N";
    }else if (derajat >= 22.6  && derajat <= 67.5 ) {
        return "NE";
    }else if (derajat >= 67.6  && derajat <= 112.5) {
        return "E";
    }else if (derajat >= 112.6 && derajat <= 157.5) {
        return "SE";
    }else if (derajat >= 157.6 && derajat <= 202.5) {
        return "S";
    }else if (derajat >= 202.6 && derajat <= 247.5) {
        return "SW";
    }else if (derajat >= 247.6 && derajat <= 292.5) {
        return "W";
    }else if (derajat >= 292.6 && derajat <= 337.5) {
        return "NW";
    }
}

function roundToN(value, n) {
    return Math.round(value * n) / n;
}

function formatDecN(value, n) {
    return value.toFixed(n);
}

function createUTCDate(tahun = "2022", bulan = "01", tanggal = "01", jam = "00", menit = "00", ms = "00") {
    return new Date(`${tahun}-${bulan}-${tanggal} ${jam}:${menit}:${ms}Z`).toUTCString().replace("GMT", "UTC")
}

// converting things

const jenisAwanTo = {
    "FEW": "few (1-2 oktas)",
    "SCT": "scatter (3-4 oktas)",
    "BKN": "BKN (5-7 oktas)",
    "OVC": "OVC (1-2 oktas)",
};

const cbAwanTo = {
    "CB": " (cumulonimbus)",
    "TCU": " (tcu)",
};

function decodeSandi(sandi = "", tahun = "2022", bulan = "01") {
    const RES = [];
    const RAW_GENERAL = [];
    let RESULT_GENERAL;
    const RAW_TREND = [];
    let RESULT_TREND;
    let tanggal = "";

    // RAW DATA
    const pushResult = (label, text) => {
        const itemToPush = {label, text};
        RES.push(itemToPush);
        return itemToPush;
    };

    RESULT_GENERAL = pushResult("Raw data", "");

    // cek jenis pengamatan
    if (true) {
        pushResult("Jenis pengamatan", "pengamatan manual");
    }else {
        // otomatis
    }

    
    if (sandi.indexOf("=") > -1) {
        sandi = sandi.substring(0, sandi.indexOf("="));
    }
    sandi = sandi.split("  ").join(" ").split(" ").reverse();

    let PART_SANDI = sandi.pop();

    if (PART_SANDI == "METAR" || PART_SANDI == "SPECI") {
        // skip
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if (PART_SANDI == "COR") {
        // skip
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if (/^[a-zA-Z]{4}$/.test(PART_SANDI)) { // WASS
        // skip
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if (/^(\d{2})(\d{2})(\d{2})Z$/.test(PART_SANDI)) { // 010630Z
        const [match, Mtanggal, Mjam, Mmenit] = PART_SANDI.match(/^(\d{2})(\d{2})(\d{2})Z$/);
        // new Date("YYYY-MM-DD hh:mm:ss")
        // const setTime = new Date(new Date(DATETIME_NOW.setDate(Mtanggal)).setHours(Mjam, Mmenit, 0, 0)).toLocaleString();
        tanggal = Mtanggal;
        const setTime = createUTCDate(tahun, bulan, Mtanggal, Mjam, Mmenit);

        pushResult("Waktu pengamatan", setTime);
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if (PART_SANDI == "NIL") {
        // skip
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if (PART_SANDI == "AUTO") {
        // skip
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if (/^(\w{3})(\d{2})(G(\d{2}))?KT$/.test(PART_SANDI)) { // 09004KT
        const [match, Mdir, Mspeed, MGpart, Mgust] = PART_SANDI.match(/^(\w{3})(\d{2})(G(\d{2}))?KT$/);

        let dir;
        if(Mdir === "VRB") {
            dir = "variabel";
        }else {
            const derajat = parseInt(Mdir);
            dir = `dari ${derajatToArahCode(derajat)} (${derajat}°)`;
        }

        let speedkt = parseFloat(Mspeed);
        let speedms = (speedkt * 0.514444);
        let speed = `${formatDecN(speedkt, 1)} knot (${formatDecN(roundToN(speedms, 10), 1)} m/s)`;

        let gust = "";
        if(Mgust) {
            let gust_kt = formatDecN(parseFloat(Mgust), 1);
            let gust_ms = (gust_kt * 0.514444);
            gust = `, gust ${gust_kt} knot (${formatDecN(roundToN(gust_ms, 10), 1)} m/s)`
        }

        pushResult("Angin", `${dir}, ${speed}${gust}`);
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if(/^(\d+)V(\d+)$/.test(PART_SANDI)) { // 30V90
        const [match, Mmin, Mmax] = PART_SANDI.match(/^(\d+)V(\d+)$/);
        
        pushResult("Variasi Angin", `min ${Mmin}, max ${Mmax}`);
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if (PART_SANDI == "CAVOK") {
        pushResult("CAVOK", "CAVOK");
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if(/^(\d{4})(NDV)?$/.test(PART_SANDI)) { // 4000
        const [match, Mvis, Mndv] = PART_SANDI.match(/^(\d{4})(NDV)??$/);

        let vis;
        if (Mvis == "9999") {
            vis = "≥10 km";
        }else {
            vis = parseInt(Mvis);
            vis = `${formatDecN(vis / 1000, 1)} km`;
        }

        let ndv = "";
        if(Mndv) {
            ndv = `, ${Mndv}`;
        }

        pushResult("Jarak pandang", vis + ndv);
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if(/^(\d{4})(\w{2})?$/.test(PART_SANDI)) { // 2000NE
        const [match, Mvis, Marah] = PART_SANDI.match(/^(\d{4})(\w{2})?$/);

        let min_vis = parseInt(Mvis);
        min_vis = `${formatDecN(min_vis / 1000, 1)} km`;

        let arah = "";
        if(Marah) {
            arah = `, arah ${Marah}`;
        }

        pushResult("Jarak pandang minimum", `${min_vis}${arah}`);
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if(/^(R\d{2}\w?)\/(\w?)(\d{4})(\w?)$/.test(PART_SANDI)) { // R12L/P2000M R14C/M0050L ...
        let rvrs = [];
        do {
            const [match, Mrunway, Mmore_less, Mvrvr, Mtendecy] = PART_SANDI.match(/^(R\d{2}\w?)\/(\w?)(\d{4})(\w?)$/);
            rvrs.push({
                runway: Mrunway,
                more_less: Mmore_less,
                vrvr: Mvrvr,
                tendecy: Mtendecy,
            });
            RAW_GENERAL.push(PART_SANDI);
            PART_SANDI = sandi.pop();
        } while (/^(R\d{2}\w?)\/(\w?)(\d{4})(\w?)$/.test(PART_SANDI));

        const rvrsText = rvrs.map(item => {
            return `${item.runway} ${item.more_less} ${item.vrvr} ${item.tendecy}`;
        })
        pushResult("RVR", rvrsText.join(", "));
    }

    if(/^(?!RE)[-+]?[a-zA-Z]{2,4}$/.test(PART_SANDI)) { // -RA ... ...
        let cuacas = [];
        do {
            cuacas.push(PART_SANDI);
            RAW_GENERAL.push(PART_SANDI);
            PART_SANDI = sandi.pop();
        } while (/^(?!RE)[-+]?[a-zA-Z]{2,4}$/.test(PART_SANDI));

        const cuacasText = cuacas.map(cuaca => `${cuaca} ()`)
        pushResult("Cuaca", cuacasText.join(", "));
    }

    if(/^(FEW|SCT|BKN|OVC)(\d{3})(CB|TCU)?$/.test(PART_SANDI)) { // FEW017CB SCT018 ...
        let awans = [];
        do {
            const [match, Mjenis, Mtinggi, Mcb] = PART_SANDI.match(/^(FEW|SCT|BKN|OVC)(\d{3})(CB|TCU)?$/);
            awans.push({Mjenis, Mtinggi, Mcb});
            RAW_GENERAL.push(PART_SANDI);
            PART_SANDI = sandi.pop();
        } while (/^(FEW|SCT|BKN|OVC)(\d{3})(CB|TCU)?$/.test(PART_SANDI))

        const awansText = awans.map(({Mjenis, Mtinggi, Mcb}) => {
            const jenis = jenisAwanTo[Mjenis];
            const tinggi = parseInt(Mtinggi) * 100;
            let cb = "";
            if (Mcb) {
                cb = cbAwanTo[Mcb];
            }
            return `${jenis} di ketinggian ${tinggi} kaki${cb}`;
        });
        pushResult("Awan", awansText.join(",\n"));
    }

    if(/^VV(\d{3})$/.test(PART_SANDI)) { // VV005
        const [match, Mvv] = PART_SANDI.match(/^VV(\d{3})$/);

        const vv = parseInt(Mvv) * 100;

        pushResult("Vertical Visibility", `${vv} kaki`);
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if(/^(\d{2})\/(\d{2})$/.test(PART_SANDI)) { // 29/24
        const [match, Msuhu, Mdew] = PART_SANDI.match(/^(\d{2})\/(\d{2})$/);

        const suhu = `${Msuhu}°C`;
        const dew = `${Mdew}°C`;

        pushResult("Suhu", suhu);
        pushResult("Titik embun", dew);
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if(/^Q(\d{4})$/.test(PART_SANDI)) { // Q1009
        const [match, Mtekanan] = PART_SANDI.match(/^Q(\d{4})$/);

        const tekanan_hpa = parseInt(Mtekanan);
        const tekanan_inchg = formatDecN(tekanan_hpa * 0.029529983071445, 2);
        
        pushResult("Tekanan udara", `${tekanan_hpa} hPa (${tekanan_inchg} incHg)`);
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if(/^RE([a-zA-Z]{2,4})$/.test(PART_SANDI)) { // RERA RETS ...
        let reCuacas = [];
        do {
            reCuacas.push(PART_SANDI);
            RAW_GENERAL.push(PART_SANDI);
            PART_SANDI = sandi.pop();
        } while (/^RE([a-zA-Z]{2,4})$/.test(PART_SANDI));

        const reCuacasText = reCuacas.map(cuaca => `${cuaca} ()`)
        pushResult("Cuaca sebelumnya", reCuacasText.join(", "));
    }

    if(/^WS$/.test(PART_SANDI)) { // WS R23 R12 ...
        // skip WS
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();

        if (PART_SANDI == "ALL") {
            // skip ALL
            RAW_GENERAL.push(PART_SANDI);
            PART_SANDI = sandi.pop();
            // skip RWY
            RAW_GENERAL.push(PART_SANDI);
            PART_SANDI = sandi.pop();

            pushResult("Wind shear", "ALL RWY");
        }else {
            let ws_runways = [];
            do {
                ws_runways.push(PART_SANDI);
                RAW_GENERAL.push(PART_SANDI);
                PART_SANDI = sandi.pop();
            } while (/^R\d{2}$/.test(PART_SANDI)); // R23 R12 ...

            pushResult("Wind shear", ws_runways.join(", "));
        }
    }

    if(/^W(\d{2})\/(\d{2})$/.test(PART_SANDI)) { // W23/02
        const [match, Msuhu_laut, Mkeadaan_laut] = PART_SANDI.match(/^W(\d{2})\/(\d{2})$/);

        const suhu_laut = `${Msuhu_laut}°C`;
        const keadaan_laut = Mkeadaan_laut;

        pushResult("Suhu laut", suhu_laut);
        pushResult("Keadaan laut", keadaan_laut);
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if(/^(R\d{2})(\d{1})(\d{1})(\d{2})(\d{2})$/.test(PART_SANDI)) { // R12290102 ...
        let states = [];
        do {
            const [match, Mrunway, Mstate, Mcontamination, Mdepth, Mbraking] = PART_SANDI.match(/^(R\d{2})(\d{1})(\d{1})(\d{2})(\d{2})$/);
            states.push({
                runway: Mrunway,
                state: Mstate,
                contamination: Mcontamination,
                depth: Mdepth,
                braking: Mbraking
            });
            RAW_GENERAL.push(PART_SANDI);
            PART_SANDI = sandi.pop();
        } while (/^(R\d{2})(\d{1})(\d{1})(\d{2})(\d{2})$/.test(PART_SANDI));

        const statesText = states.map(item => {
            return `${item.runway} ${item.state} ${item.contamination} ${item.depth} ${item.braking}`;
        });
        pushResult("Keadaan Runway", statesText.join(", "));
    }

    // GENERAL RAW DATA
    RESULT_GENERAL.text = RAW_GENERAL.join(" ");

    RESULT_TREND = pushResult("Raw data", "");

    if (/^(BECMG|TEMPO|NOSIG)$/.test(PART_SANDI)) {
        const trendText = {
            "BECMG": "BECMG ()",
            "TEMPO": "TEMPO (temporer)",
            "NOSIG": "NOSIG (tidak ada perubahan signifikan)",
        };

        pushResult("Jenis trend", trendText[PART_SANDI]);
        RAW_TREND.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if (/^FM(\d{2})(\d{2})$/.test(PART_SANDI)) { // FM0101 TL0159
        const [, MFMjam, MFMmenit] = PART_SANDI.match(/^FM(\d{2})(\d{2})$/);
        const setFMPeriode = createUTCDate(tahun, bulan, tanggal, MFMjam, MFMmenit);

        RAW_TREND.push(PART_SANDI);
        PART_SANDI = sandi.pop();

        const [, MTLjam, MTLmenit] = PART_SANDI.match(/^TL(\d{2})(\d{2})$/);
        const setTLPeriode = createUTCDate(tahun, bulan, tanggal, MTLjam, MTLmenit);

        RAW_TREND.push(PART_SANDI);
        PART_SANDI = sandi.pop();

        pushResult("Periode", `${setFMPeriode} s/d. ${setTLPeriode}`);
    }

    if (/^AT(\d{2})(\d{2})$/.test(PART_SANDI)) { // AT0240
        const [match, Mjam, Mmenit] = PART_SANDI.match(/^AT(\d{2})(\d{2})$/);
        const setPeriode = createUTCDate(tahun, bulan, tanggal, Mjam, Mmenit);

        pushResult("Periode", `${setPeriode}`);
        RAW_TREND.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if (/^TL(\d{2})(\d{2})$/.test(PART_SANDI)) { // TL0100
        const [match, Mjam, Mmenit] = PART_SANDI.match(/^TL(\d{2})(\d{2})$/);
        const setPeriode = createUTCDate(tahun, bulan, tanggal, Mjam, Mmenit);

        pushResult("Periode", `s/d. ${setPeriode}`);
        RAW_TREND.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if (/^(\w{3})(\d{2})(G(\d{2}))?KT$/.test(PART_SANDI)) { // 09004KT
        const [match, Mdir, Mspeed, MGpart, Mgust] = PART_SANDI.match(/^(\w{3})(\d{2})(G(\d{2}))?KT$/);

        let dir;
        if(Mdir === "VRB") {
            dir = "variabel";
        }else {
            const derajat = parseInt(Mdir);
            dir = `dari ${derajatToArahCode(derajat)} (${derajat}°)`;
        }

        let speedkt = parseFloat(Mspeed);
        let speedms = (speedkt * 0.514444);
        let speed = `${formatDecN(speedkt, 1)} knot (${formatDecN(roundToN(speedms, 10), 1)} m/s)`;

        let gust = "";
        if(Mgust) {
            let gust_kt = formatDecN(parseFloat(Mgust), 1);
            let gust_ms = (gust_kt * 0.514444);
            gust = `, gust ${gust_kt} knot (${formatDecN(roundToN(gust_ms, 10), 1)} m/s)`
        }

        pushResult("Angin", `${dir}, ${speed}${gust}`);
        RAW_TREND.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if (PART_SANDI == "CAVOK") {
        pushResult("CAVOK", "CAVOK");
        RAW_GENERAL.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if(/^\d{4}$/.test(PART_SANDI)) { // 2000
        let vis;
        if (PART_SANDI == "9999") {
            vis = "≥10 km";
        }else {
            vis = parseInt(PART_SANDI);
            vis = `${formatDecN(vis / 1000, 1)} km`;
        }

        pushResult("Jarak pandang", vis);
        RAW_TREND.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if(/^(FEW|SCT|BKN|OVC)(\d{3})(CB|TCU)?$/.test(PART_SANDI)) { // FEW017CB SCT018 ...
        let awans = [];
        do {
            const [match, Mjenis, Mtinggi, Mcb] = PART_SANDI.match(/^(FEW|SCT|BKN|OVC)(\d{3})(CB|TCU)?$/);
            awans.push({Mjenis, Mtinggi, Mcb});
            RAW_TREND.push(PART_SANDI);
            PART_SANDI = sandi.pop();
        } while (/^(FEW|SCT|BKN|OVC)(\d{3})(CB|TCU)?$/.test(PART_SANDI))

        const awansText = awans.map(({Mjenis, Mtinggi, Mcb}) => {
            const jenis = jenisAwanTo[Mjenis];
            const tinggi = parseInt(Mtinggi) * 100;
            let cb = "";
            if (Mcb) {
                cb = cbAwanTo[Mcb];
            }
            return `${jenis} di ketinggian ${tinggi} kaki${cb}`;
        });
        pushResult("Awan", awansText.join(",\n"));
    }

    if(/^VV(\d{3})$/.test(PART_SANDI)) { // VV005
        const [match, Mvv] = PART_SANDI.match(/^VV(\d{3})$/);

        const vv = parseInt(Mvv) * 100;

        pushResult("Vertical Visibility", `${vv} kaki`);
        RAW_TREND.push(PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    if(/^(?!RE)[-+]?[a-zA-Z]{2,4}$/.test(PART_SANDI)) { // -RA ... ...
        let cuacas = [];
        do {
            cuacas.push(PART_SANDI);
            RAW_TREND.push(PART_SANDI);
            PART_SANDI = sandi.pop();
        } while (/^(?!RE)[-+]?[a-zA-Z]{2,4}$/.test(PART_SANDI));

        const cuacasText = cuacas.map(cuaca => `${cuaca} ()`)
        pushResult("Cuaca", cuacasText.join(", "));
    }

    // TREND RAW DATA
    RESULT_TREND.text = RAW_TREND.join(" ");

    while (PART_SANDI) {
        pushResult("ERROR", PART_SANDI);
        PART_SANDI = sandi.pop();
    }

    return RES;
}

function SHOW_TEST() {
    let sandi = "";
    const logIfError = (datas) => {
        if(datas.every(data => data.label != "ERROR")) return false;
        console.log(datas);
    }
    sandi = "METAR COR WAAA 312330Z 09004KT 8000 -RA FEW017CB SCT018 23/23 Q1012 RETS TEMPO TL0100 2000 TSRA=";
    logIfError(decodeSandi(sandi));
    sandi = "METAR WIII 010030Z 13013G23KT 30V90 4000 2000NE -BLSN RA FEW023 SCT030 BKN032 23/23 Q1100 REUP WS R23 R12 W23/02 R23110001 R24310205=";
    logIfError(decodeSandi(sandi));
    sandi = "METAR WIII 010100Z 14023G33KT CAVOK FEW012 23/12 Q1100 WS R12 R13 R12111107=";
    logIfError(decodeSandi(sandi));
    sandi = "METAR WIII 010130Z 23023G43KT 0500 0200 R12L/P2000M R14C/M0050L -TSPE +BLSN PRUP FEW012CB SCT013 BKN040 23/23 Q1100 REGR RERA REGS WS R12 R14 R12290102=";
    logIfError(decodeSandi(sandi));
    sandi = "METAR WIII 010130Z 23023G43KT 0500 0200 R12L/P2000M R14C/M0050L -TSPE +BLSN PRUP FEW012CB SCT013 BKN040 23/23 Q1100 REGR RERA REGS WS R12 R14 R12290102=";
    logIfError(decodeSandi(sandi));
    sandi = "METAR WAAA 010100Z 14023G33KT 1000NDV R13/P2000M R24/1200N R36/0500U R01/P2000L R05/0400D R06/P2000M R09/0100N R05/0050M RA +BLSN -PR FEW012 SCT024 BKN036 27/26 Q1100 REDZ REGR RERA WS R01 R12 R24 R36 R05 R07 R08 R09 W24/03 R12111107 R13110410 R02191410 R16251210 R17251515 R18101411 R21201312 R32110102 BECMG FM0101 TL0159 00000KT FEW001 SCT003 BKN012="
    logIfError(decodeSandi(sandi))
    sandi = "METAR WAAA 010130Z 23023G43KT 0500NDV R12L/P2000M R14C/M0050L -TSPE +BLSN PRUP VV005 23/23 Q1100 REGR RERA REGS WS ALL RWY R12290102 BECMG FM0131 TL0150 09005G15KT 4000 NSW NSC="
    logIfError(decodeSandi(sandi))
    sandi = "METAR WAAA 010200Z 05006KT 80V160 CAVOK NSC 28/26 Q1019 WS ALL RWY W26/08 TEMPO AT0240 12002KT CAVOK="
    logIfError(decodeSandi(sandi))
}

// SHOW_TEST();

export default decodeSandi;
