import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_KELEMBAPANTANAH = false;
export default {
    async dummyDataKELEMBAPANTANAH(){
        return new Promise((resolve) => {
            var datas = {
                "2021-01" : {
                   "07:00" :[
                        {
                        "TGL"       : "2021-01-01",
                        "COL1"      : 1, 
                        "COL2"      : 2,
                        "COL3"      : 3,
                        "COL4"      : 4,
                        "COL5"      : 5,
                        "COL6"      : 6,
                        },
                        {
                        "TGL"       : "2021-01-02",
                        "COL1"      : 5, 
                        "COL2"      : 2,
                        "COL3"      : 3,
                        "COL4"      : 4,
                        "COL5"      : 5,
                        "COL6"      : 6,
                        },
                    ],
                   "13:00" : [
                        {
                        "TGL"       : "2021-01-01",
                        "COL1"      : 1, 
                        "COL2"      : 2,
                        "COL3"      : 3,
                        "COL4"      : 4,
                        "COL5"      : 5,
                        "COL6"      : 6,
                        },
                        {
                        "TGL"       : "2021-01-02",
                        "COL1"      : 1, 
                        "COL2"      : 2,
                        "COL3"      : 3,
                        "COL4"      : 4,
                        "COL5"      : 5,
                        "COL6"      : 6,
                        },
                    ],
                    "18:00" : [
                         {
                         "TGL"       : "2021-01-01",
                         "COL1"      : 1, 
                         "COL2"      : 2,
                         "COL3"      : 3,
                         "COL4"      : 4,
                         "COL5"      : 5,
                         "COL6"      : 6,
                         },
                         {
                         "TGL"       : "2021-01-02",
                         "COL1"      : 1, 
                         "COL2"      : 2,
                         "COL3"      : 3,
                         "COL4"      : 4,
                         "COL5"      : 5,
                         "COL6"      : 6,
                         },
                    ]
               }
            }

            var dataBuild = {};
            for(var i in datas){
                dataBuild[i] = {};
                var rawPeriode = datas[i];
                for(var j in rawPeriode){
                    dataBuild[i][j] = {"RAW" : [], "CALCULATE" : {}}
                    var time = rawPeriode[j];

                    dataBuild[i][j]["RAW"] = time.sort(function(a, b) {
                        return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                    });
                    
                    var Total = {};
                    var Count = {};
                    var Rata  = {};

                    var RAW_DATA = [];
                    for(var icount = 1; icount <= 31; icount++){
                        RAW_DATA.push({
                            "TGL"       : icount,
                            "COL1"      : 1, 
                            "COL2"      : 2,
                            "COL3"      : 3,
                            "COL4"      : 4,
                            "COL5"      : 5,
                            "COL6"      : 6,
                        });
                    }

                    for(var indexTime in time){
                        var dataDate = new Date(time[indexTime]["TGL"]);
                        for(var m in time[indexTime]){
                            if(m == "TGL"){
                                continue;
                            }

                            if(RAW_DATA[Number(dataDate.getDate())-1] != undefined ){
                                RAW_DATA[Number(dataDate.getDate())-1][m] = time[indexTime][m] == "null" ||  time[indexTime][m] == null ? "-" : time[indexTime][m];
                            }

                            if(Total[m] == undefined ){
                                Total[m] = 0;
                                Count[m] = 0;
                            }
                            Total[m] += time[indexTime][m];
                            Count[m]++;
                            Rata[m] = Total[m] / Count[m];
                        }
                    }
                    dataBuild[i][j]["RAW"] = RAW_DATA;
                    dataBuild[i][j]["CALCULATE"] = {JUMLAH : Total, RATA : Rata};
                }
            }
            datas = dataBuild;
            resolve(datas);
        });
    },

    async storeDataKELEMBAPANTANAH(STATION_ID,PERIODE,WAKTU){
        return new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_KELEMBABAN_TANAH(STATION_ID,PERIODE,WAKTU);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataKELEMBAPANTANAH(STATION_ID,PERIODE,WAKTU){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_KELEMBAPANTANAH == true){
                var datas = await this.dummyDataKELEMBAPANTANAH();
                resolve(datas);
            }else{
                var thisData = await this.storeDataKELEMBAPANTANAH(STATION_ID,PERIODE,WAKTU);
                var datas = thisData.data;
                var dataBuild = {};
                for(var i in datas){
                    dataBuild[i] = {};
                    var rawPeriode = datas[i];
                    for(var j in rawPeriode){
                        dataBuild[i][j] = {"RAW" : [], "CALCULATE" : {}}
                        var time = rawPeriode[j];

                        dataBuild[i][j]["RAW"] = time.sort(function(a, b) {
                            return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                        });
                        
                        var Total = {};
                        var Count = {};
                        var Rata  = {};
                        var RAW_DATA = [];
                        for(var icount = 1; icount <= 31; icount++){
                            RAW_DATA.push({
                                "TGL"       : icount,
                                "COL1"      : "", 
                                "COL2"      : "",
                                "COL3"      : "",
                                "COL4"      : "",
                                "COL5"      : "",
                                "COL6"      : "",
                            });
                        }
                        for(var indexTime in time){
                            var dataDate = new Date(time[indexTime]["TGL"]);
                            for(var m in time[indexTime]){
                                if(m == "TGL"){
                                    continue;
                                }

                                if(RAW_DATA[Number(dataDate.getDate())-1] != undefined ){
                                    RAW_DATA[Number(dataDate.getDate())-1][m] = time[indexTime][m] == "null" ||  time[indexTime][m] == null ? "-" : time[indexTime][m];
                                }

                                if(Total[m] == undefined ){
                                    Total[m] = 0;
                                    Count[m] = 0;
                                }
                                Total[m] += time[indexTime][m];
                                Count[m]++;
                                Rata[m] = Number(Total[m] / Count[m]).toFixed(2);
                            }
                        }
                        for(var iT in Total){
                            Total[iT] = Number(Total[iT]).toFixed(2)
                        }
                        dataBuild[i][j]["RAW"] = RAW_DATA;
                        dataBuild[i][j]["CALCULATE"] = {JUMLAH : Total, RATA : Rata};
                    }
                }
                datas = dataBuild;
                resolve(datas);
            }
        });
    }
}