import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_HUJANHELMAN = false;
export default {
    async dummyDataHUJANHELMAN(){
        return  new Promise((resolve) => {
            var datas = {
                "2021-01": {
                    "FORM_A" : {
                        "OBSERVER_NAME" : "",
                        "OBSERVER_ADDRESS": "",
                        "TYPE_PENAKAR": "" ,
                        "RAW" : [
                                {
                                "TGL":"2021-01-01",
                                "PERIODE5M":"",
                                "PERIODE10M":"",
                                "PERIODE15M":"", 
                                "PERIODE30M":"", 
                                "PERIODE45M": "", 
                                "PERIODE80M":"",  
                                "PERIODE120M":"", 
                                "PERIODE3J":"", 
                                "PERIODE6J":"", 
                                "PERIODE12J":"", 
                                "COL1":"",
                                "COL2":"",
                                "COL3":"", 
                                "COL4":"", 
                                "COL5": "", 
                                "COL6":"",  
                                "COL7":"", 
                                "COL8":"", 
                                "COL9":"", 
                                "COL10":"",  
                                "COL11":"",  
                                "COL12":"", 
                                "COL13":"", 
                                "COL14":"",
                                "COL15":"",
                                "COL16":"",  
                                "COL17":"",  
                                "COL18":"", 
                                "COL19":"", 
                                "COL20":"", 
                                "COL21":"",  
                                "COL22":"", 
                                "COL23":"", 
                                "COL24":"",
                                "COL25":"",  
                                },
                                {
                                "TGL":"2021-01-02",
                                "PERIODE5M":"",
                                "PERIODE10M":"",
                                "PERIODE15M":"", 
                                "PERIODE30M":"", 
                                "PERIODE45M": "", 
                                "PERIODE80M":"",  
                                "PERIODE120M":"", 
                                "PERIODE3J":"", 
                                "PERIODE6J":"", 
                                "PERIODE12J":"", 
                                "COL1":"",
                                "COL2":"",
                                "COL3":"", 
                                "COL4":"", 
                                "COL5": "", 
                                "COL6":"",  
                                "COL7":"", 
                                "COL8":"", 
                                "COL9":"", 
                                "COL10":"",  
                                "COL11":"",  
                                "COL12":"", 
                                "COL13":"", 
                                "COL14":"",
                                "COL15":"",
                                "COL16":"",  
                                "COL17":"",  
                                "COL18":"", 
                                "COL19":"", 
                                "COL20":"", 
                                "COL21":"",  
                                "COL22":"", 
                                "COL23":"", 
                                "COL24":"",
                                "COL25":"",  
                                },
                            ],
                    },
                    "FORM_B":{
                        "OBSERVER_NAME" : "",
                        "OBSERVER_ADDRESS" : "",
                        "TYPE_PENAKAR" : "",
                        "RAW_HAL1":{
                            "RAW" : [
                                {
                                "TGL":"2021-01-01", 
                                "COL1":"",
                                "COL2":"",
                                "COL3":"", 
                                "COL4":"", 
                                "COL5": "", 
                                "COL6":"",  
                                "COL7":"", 
                                "COL8":"", 
                                "COL9":"", 
                                "COL10":"",  
                                "COL11":"",  
                                "COL12":"", 
                                "COL13":"", 
                                "COL14":"",
                                "COL15":"",
                                "COL16":"",  
                                "COL17":"",  
                                "COL18":"", 
                                "COL19":"", 
                                "COL20":"", 
                                "COL21":"",  
                                "COL22":"", 
                                "COL23":"", 
                                "COL24":"",
                                "COL25":"", 
                                },
                                {
                                "TGL":"2021-01-02", 
                                "COL1":"",
                                "COL2":"",
                                "COL3":"", 
                                "COL4":"", 
                                "COL5": "", 
                                "COL6":"",  
                                "COL7":"", 
                                "COL8":"", 
                                "COL9":"", 
                                "COL10":"",  
                                "COL11":"",  
                                "COL12":"", 
                                "COL13":"", 
                                "COL14":"",
                                "COL15":"",
                                "COL16":"",  
                                "COL17":"",  
                                "COL18":"", 
                                "COL19":"", 
                                "COL20":"", 
                                "COL21":"",  
                                "COL22":"", 
                                "COL23":"", 
                                "COL24":"",
                                "COL25":"", 
                                },
                            ],
                        },
                        "RAW_HAL2":{
                            "RAW" : [
                                {
                                "TGL":"2021-01-01", 
                                "COL1":"",
                                "COL2":"",
                                "COL3":"", 
                                "COL4":"", 
                                "COL5": "", 
                                "COL6":"",  
                                "COL7":"", 
                                "COL8":"", 
                                "COL9":"", 
                                "COL10":"",  
                                "COL11":"",  
                                },
                                {
                                "TGL":"2021-01-02", 
                                "COL1":"",
                                "COL2":"",
                                "COL3":"", 
                                "COL4":"", 
                                "COL5": "", 
                                "COL6":"",  
                                "COL7":"", 
                                "COL8":"", 
                                "COL9":"", 
                                "COL10":"",  
                                "COL11":"", 
                                },
                            ],
                        }
                    }
                }
            }

            
            var dataBuild = {};
            for(var i in datas){
                var Periode = i;
                var rawPeriode = datas[i];
                dataBuild[i] = {
                    "FORM_A" : {
                        "OBSERVER_NAME" : rawPeriode.FORM_A.OBSERVER_NAME,
                        "OBSERVER_ADDRESS": rawPeriode.FORM_A.OBSERVER_ADDRESS,
                        "TYPE_PENAKAR": rawPeriode.FORM_A.TYPE_PENAKAR ,
                        "RAW" : [],
                        "INTENCE_MAX" : {},
                        "JUMLAH" : {},
                        "MAX_HUJAN" : {},
                    },
                    "FORM_B" : {
                        "OBSERVER_NAME" :  rawPeriode.FORM_B.OBSERVER_NAME,
                        "OBSERVER_ADDRESS": rawPeriode.FORM_B.OBSERVER_ADDRESS,
                        "TYPE_PENAKAR":rawPeriode.FORM_B.TYPE_PENAKAR,
                        "RAW_HAL1":{
                            "RAW" : [],
                            "MAX_HUJAN" : {},
                            "JUMLAH" : {},
                        },
                        "RAW_HAL2":{
                            "RAW"           : [],
                            "INTENCE_MAX"   : {},
                            "JUMLAH"        : {},
                            "MAX_HUJAN"     : {},
                        }
                    }
                }

                // FORM A
                var FORMA = rawPeriode.FORM_A.RAW.sort(function(a, b) {
                    return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                });

                var INTENCE_MAX_FORMA = {};
                var INTENCE_MAX_DATE_FORMA = {};
                var MAX_HUJAN_FORMA = {};
                var JML_HUJAN_FORMA = {};

                var RAW_DATA = [];
                for(var icount = 1; icount <= 31; icount++){
                    RAW_DATA.push({
                        "TGL":  "00"+icount.toString().substring(icount.length,2),
                        "PERIODE5M":"",
                        "PERIODE10M":"",
                        "PERIODE15M":"", 
                        "PERIODE30M":"", 
                        "PERIODE45M": "", 
                        "PERIODE80M":"",  
                        "PERIODE120M":"", 
                        "PERIODE3J":"", 
                        "PERIODE6J":"", 
                        "PERIODE12J":"", 
                        "COL1":"",
                        "COL2":"",
                        "COL3":"", 
                        "COL4":"", 
                        "COL5": "", 
                        "COL6":"",  
                        "COL7":"", 
                        "COL8":"", 
                        "COL9":"", 
                        "COL10":"",  
                        "COL11":"",  
                        "COL12":"", 
                        "COL13":"", 
                        "COL14":"",
                        "COL15":"",
                        "COL16":"",  
                        "COL17":"",  
                        "COL18":"", 
                        "COL19":"", 
                        "COL20":"", 
                        "COL21":"",  
                        "COL22":"", 
                        "COL23":"", 
                        "COL24":"",
                        "COL25":"",  
                    });
                }
                
                for(var iFORMA in  FORMA){
                    var dataFORMA = FORMA[iFORMA];
                    var thisDate = new Date(dataFORMA.TGL);

                    var intenceMax = ["PERIODE5M","PERIODE10M","PERIODE15M","PERIODE30M","PERIODE45M","PERIODE80M","PERIODE120M","PERIODE3J","PERIODE6J","PERIODE12J"];
                    dataFORMA["TGL"] = thisDate.getDate();

                    if(RAW_DATA[Number(thisDate.getDate())-1] != undefined ){
                        RAW_DATA[Number(thisDate.getDate())-1] = dataFORMA;
                    }

                    // dataBuild[i]["FORM_A"]["RAW"].push(dataFORMA);
                    
                    for(var j in dataFORMA){
                        if(intenceMax.indexOf(j) != -1){
                            if(INTENCE_MAX_FORMA[j] == undefined){
                                INTENCE_MAX_FORMA[j] = 0;
                                INTENCE_MAX_DATE_FORMA[j] = "";
                            }
                        }
                        if(Number(dataFORMA[j]) > INTENCE_MAX_FORMA[j] ){
                            INTENCE_MAX_FORMA[j] = Number(dataFORMA[j]);
                            INTENCE_MAX_DATE_FORMA[j] = thisDate.getDate();
                        }

                        if(intenceMax.indexOf(j) == -1){
                            if(j != "TGL"){
                                if(MAX_HUJAN_FORMA[j] == undefined){
                                    MAX_HUJAN_FORMA[j]  = 0;
                                    JML_HUJAN_FORMA[j]  = 0;
                                }
                                if(Number(dataFORMA[j]) > MAX_HUJAN_FORMA[j]){
                                    MAX_HUJAN_FORMA[j]  = Number(dataFORMA[j]);
                                }
                                JML_HUJAN_FORMA[j] +=  Number(dataFORMA[j]);
                            }
                        }
                    }
                }
                dataBuild[i]["FORM_A"]["RAW"] = RAW_DATA;
                dataBuild[i]["FORM_A"]["JUMLAH"] = JML_HUJAN_FORMA;
                dataBuild[i]["FORM_A"]["MAX_HUJAN"] = MAX_HUJAN_FORMA;
                dataBuild[i]["FORM_A"]["INTENCE_MAX"] = {"DATE" : INTENCE_MAX_DATE_FORMA, "VALUES" : INTENCE_MAX_FORMA};

                // FORM B HAL 1
                var FORMB_1 = rawPeriode.FORM_B.RAW_HAL1.RAW.sort(function(a, b) {
                    return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                });

                var MAX_HUJAN_FORMB_1 = {};
                var JML_HUJAN_FORMB_1 = {}; 
                for(var iFORMB_1 in FORMB_1){
                    var dataFORMB_1 = FORMB_1[iFORMB_1];
                    var thisDate = new Date(dataFORMB_1.TGL);
                    dataFORMB_1["TGL"] = thisDate.getDate();


                    

                    dataBuild[i]["FORM_B"]["RAW_HAL1"]["RAW"].push(dataFORMB_1);
                    
                    for(var j in dataFORMB_1){
                        var SKIP_CALCULATE = ["TGL"];
                        if(SKIP_CALCULATE.indexOf(j) == -1){
                            if(MAX_HUJAN_FORMB_1[j] == undefined){
                                MAX_HUJAN_FORMB_1[j] = 0;
                                JML_HUJAN_FORMB_1[j] = 0;
                            }
                            if(Number(dataFORMB_1[j]) > MAX_HUJAN_FORMB_1[j]){
                                MAX_HUJAN_FORMB_1[j] = Number(dataFORMB_1[j]);
                            }
                            JML_HUJAN_FORMB_1[j] = Number(dataFORMB_1[j])
                        }
                    }
                }
                dataBuild[i]["FORM_B"]["RAW_HAL1"]["MAX_HUJAN"] =   MAX_HUJAN_FORMB_1;
                dataBuild[i]["FORM_B"]["RAW_HAL1"]["JUMLAH"]    = JML_HUJAN_FORMB_1;


                // FORM B HAL 2
                var FORMB_2 = rawPeriode.FORM_B.RAW_HAL2.RAW.sort(function(a, b) {
                    return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                });

                var INTENCE_MAX_FORMB = {};
                var INTENCE_MAX_DATE_FORMB = {};

                var MAX_JML = {};
                var JML     = {};
                for(var iFORMB_2 in FORMB_2){
                    var dataFORMB_2 = FORMB_2[iFORMB_2];
                    var dataDate = new Date(dataFORMB_2["TGL"]);

                    var COL_KOREKSI = ["COL4","COL5","COL6","COL7","COL8","COL9","COL10","COL11"];
                    var COL_JUMLAH  = ["COL1","COL2","COL3"];
                    
                    dataFORMB_2["TGL"] = dataDate.getDate();
                    dataBuild[i]["FORM_B"]["RAW_HAL2"]["RAW"].push(dataFORMB_2);

                    for(var j in dataFORMB_2){
                        if(j != "TGL"){
                            if(JML[j] == undefined){
                                JML[j] = 0;
                                MAX_JML[j]  = 0;
                            }
                            if(Number(dataFORMB_2[j]) > Number(MAX_JML[j]) ){
                                MAX_JML[j] = Number(dataFORMB_2[j]);

                                if(COL_KOREKSI.indexOf(j) != -1){
                                    if(INTENCE_MAX_DATE_FORMB[j] == undefined){
                                        INTENCE_MAX_DATE_FORMB[j] = "";
                                        INTENCE_MAX_FORMB[j] = 0;
                                    }
                                    INTENCE_MAX_FORMB[j]  = Number(dataFORMB_2[j]);
                                    INTENCE_MAX_DATE_FORMB[j] = dataDate.getDate();
                                }
                            }
                            JML[j] += Number(dataFORMB_2[j]);
                        }
                    }
                }

                dataBuild[i]["FORM_B"]["RAW_HAL2"]["MAX_HUJAN"] =   MAX_JML;
                dataBuild[i]["FORM_B"]["RAW_HAL2"]["JUMLAH"]    = JML;
                dataBuild[i]["FORM_B"]["RAW_HAL2"]["INTENCE_MAX"]    = {"DATE" : INTENCE_MAX_DATE_FORMB, "VALUES" : INTENCE_MAX_FORMB};

            }
            datas = dataBuild;
            resolve(datas);
        });
    },

    async storeDataHUJANHELMAN(STATION_ID,PERIODE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REPORT_FORMHUJANHELMAN(STATION_ID,PERIODE);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataHUJANHELMAN(STATION_ID,PERIODE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_HUJANHELMAN == true){
                var datas = await this.dummyDataHUJANHELMAN();
                resolve(datas);
            }else{
                var thisData = await this.storeDataHUJANHELMAN(STATION_ID,PERIODE);
                var datas = thisData.data;
                var dataBuild = {};
                for(var i in datas){
                    var dataIObserverA = [];
                    var dataIObserverB = [];
                    var ObserverName_A = "";
                    var ObserverName_B = "";
                    dataBuild[i] = [];
                    dataBuild[i][0] = {
                        "FORM_A" : {
                            "OBSERVER_NAME" : "",
                            "OBSERVER_ADDRESS": "",
                            "TYPE_PENAKAR": "" ,
                            "RAW" : [],
                            "INTENCE_MAX" : {},
                            "JUMLAH" : {},
                            "MAX_HUJAN" : {},
                        },
                        "FORM_B" : {
                            "OBSERVER_NAME" : "",
                            "OBSERVER_ADDRESS": "",
                            "TYPE_PENAKAR": "",
                            "RAW_HAL1":{
                                "RAW" : [],
                                "MAX_HUJAN" : {},
                                "JUMLAH" : {},
                            },
                            "RAW_HAL2":{
                                "RAW"           : [],
                                "INTENCE_MAX"   : {},
                                "JUMLAH"        : {},
                                "MAX_HUJAN"     : {},
                            }
                        }
                    };

                    var INTENCE_MAX_FORMA = {};
                    var INTENCE_MAX_DATE_FORMA = {};
                    var MAX_HUJAN_FORMA = {};
                    var JML_HUJAN_FORMA = {};

                    var RAW_DATA = [];
                    for(var icount = 1; icount <= 31; icount++){
                        RAW_DATA.push({
                            "TGL": icount,
                            "PERIODE5M":"",
                            "PERIODE10M":"",
                            "PERIODE15M":"", 
                            "PERIODE30M":"", 
                            "PERIODE45M": "", 
                            "PERIODE80M":"",  
                            "PERIODE120M":"", 
                            "PERIODE3J":"", 
                            "PERIODE6J":"", 
                            "PERIODE12J":"", 
                            "COL1":"",
                            "COL2":"",
                            "COL3":"", 
                            "COL4":"", 
                            "COL5": "", 
                            "COL6":"",  
                            "COL7":"", 
                            "COL8":"", 
                            "COL9":"", 
                            "COL10":"",  
                            "COL11":"",  
                            "COL12":"", 
                            "COL13":"", 
                            "COL14":"",
                            "COL15":"",
                            "COL16":"",  
                            "COL17":"",  
                            "COL18":"", 
                            "COL19":"", 
                            "COL20":"", 
                            "COL21":"",  
                            "COL22":"", 
                            "COL23":"", 
                            "COL24":"",
                            "COL25":"",  
                        });
                    }


                    var MAX_HUJAN_FORMB_1 = {};
                    var JML_HUJAN_FORMB_1 = {}; 

                    var RAW_DATA_1 = [];
                    for(var icount = 1; icount <= 31; icount++){
                        RAW_DATA_1.push(
                            {
                            "TGL":icount, 
                            "COL1":"",
                            "COL2":"",
                            "COL3":"", 
                            "COL4":"", 
                            "COL5": "", 
                            "COL6":"",  
                            "COL7":"", 
                            "COL8":"", 
                            "COL9":"", 
                            "COL10":"",  
                            "COL11":"",  
                            "COL12":"", 
                            "COL13":"", 
                            "COL14":"",
                            "COL15":"",
                            "COL16":"",  
                            "COL17":"",  
                            "COL18":"", 
                            "COL19":"", 
                            "COL20":"", 
                            "COL21":"",  
                            "COL22":"", 
                            "COL23":"", 
                            "COL24":"",
                            "COL25":"", 
                        });
                    }


                    var INTENCE_MAX_FORMB = {};
                    var INTENCE_MAX_DATE_FORMB = {};

                    var MAX_JML = {};
                    var JML     = {};

                    var RAW_DATA_2 = [];
                    for(var icount = 1; icount <= 31; icount++){
                        RAW_DATA_2.push({
                            "TGL":icount, 
                            "COL1":"",
                            "COL2":"",
                            "COL3":"", 
                            "COL4":"", 
                            "COL5": "", 
                            "COL6":"",  
                            "COL7":"", 
                            "COL8":"", 
                            "COL9":"", 
                            "COL10":"",  
                            "COL11":"", 
                        });
                    }

                    var rawPeriode1 = datas[i];
                    for(var jIndex in rawPeriode1){
                        var rawPeriode = rawPeriode1[jIndex];
                        if(dataIObserverA.indexOf(rawPeriode.FORM_A.OBSERVER_NAME) == -1){
                            dataIObserverA.push(rawPeriode.FORM_A.OBSERVER_NAME);
                            ObserverName_A += ","+rawPeriode.FORM_A.OBSERVER_NAME;
                        }
                        if(dataIObserverB.indexOf(rawPeriode.FORM_B.OBSERVER_NAME) == -1){
                            dataIObserverB.push(rawPeriode.FORM_B.OBSERVER_NAME);
                            ObserverName_B += ","+rawPeriode.FORM_B.OBSERVER_NAME;
                        }

                        var FORMA = rawPeriode.FORM_A.RAW.sort(function(a, b) {
                            return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                        });

                        for(var iFORMA in  FORMA){
                            var dataFORMA = FORMA[iFORMA];
                            var thisDate = new Date(dataFORMA.TGL);
                            var intenceMax = ["PERIODE5M","PERIODE10M","PERIODE15M","PERIODE30M","PERIODE45M","PERIODE80M","PERIODE120M","PERIODE3J","PERIODE6J","PERIODE12J"];
                            dataFORMA["TGL"] = thisDate.getDate();

                            if(RAW_DATA[Number(thisDate.getDate())-1] != undefined ){
                                RAW_DATA[Number(thisDate.getDate())-1] = dataFORMA;
                            }

                            // dataBuild[i][jIndex]["FORM_A"]["RAW"].push(dataFORMA);
                            
                            for(var j in dataFORMA){
                                if(intenceMax.indexOf(j) != -1){
                                    if(INTENCE_MAX_FORMA[j] == undefined){
                                        INTENCE_MAX_FORMA[j] = 0;
                                        INTENCE_MAX_DATE_FORMA[j] = "";
                                    }
                                }
                                if(Number(dataFORMA[j]) > INTENCE_MAX_FORMA[j] ){
                                    INTENCE_MAX_FORMA[j] = Number(dataFORMA[j]);
                                    INTENCE_MAX_DATE_FORMA[j] = thisDate.getDate();
                                }

                                if(intenceMax.indexOf(j) == -1){
                                    if(j != "TGL"){
                                        if(MAX_HUJAN_FORMA[j] == undefined){
                                            MAX_HUJAN_FORMA[j]  = 0;
                                            JML_HUJAN_FORMA[j]  = 0;
                                        }
                                        if(Number(dataFORMA[j]) > MAX_HUJAN_FORMA[j]){
                                            MAX_HUJAN_FORMA[j]  = Number(dataFORMA[j]);
                                        }
                                        JML_HUJAN_FORMA[j] +=  Number(dataFORMA[j]);
                                    }
                                }
                            }
                        }
                        dataBuild[i][0]["FORM_A"]["RAW"] = RAW_DATA;
                        dataBuild[i][0]["FORM_A"]["JUMLAH"] = JML_HUJAN_FORMA;
                        dataBuild[i][0]["FORM_A"]["MAX_HUJAN"] = MAX_HUJAN_FORMA;
                        dataBuild[i][0]["FORM_A"]["INTENCE_MAX"] = {"DATE" : INTENCE_MAX_DATE_FORMA, "VALUES" : INTENCE_MAX_FORMA};
                        
                        // FORM B HAL 1
                        var FORMB_1 = rawPeriode.FORM_B.RAW_HAL1.RAW.sort(function(a, b) {
                            return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                        });

                        for(var iFORMB_1 in FORMB_1){
                            var dataFORMB_1 = FORMB_1[iFORMB_1];
                            var thisDate = new Date(dataFORMB_1.TGL);
                            dataFORMB_1["TGL"] = thisDate.getDate();

                            if(RAW_DATA_1[Number(thisDate.getDate())-1] != undefined ){
                                RAW_DATA_1[Number(thisDate.getDate())-1] = dataFORMB_1;
                            }

                            // dataBuild[i][jIndex]["FORM_B"]["RAW_HAL1"]["RAW"].push(dataFORMB_1);
                            
                            for(var j in dataFORMB_1){
                                var SKIP_CALCULATE = ["TGL"];
                                if(SKIP_CALCULATE.indexOf(j) == -1){
                                    if(MAX_HUJAN_FORMB_1[j] == undefined){
                                        MAX_HUJAN_FORMB_1[j] = 0;
                                        JML_HUJAN_FORMB_1[j] = 0;
                                    }
                                    if(Number(dataFORMB_1[j]) > MAX_HUJAN_FORMB_1[j]){
                                        MAX_HUJAN_FORMB_1[j] = Number(dataFORMB_1[j]);
                                    }
                                    JML_HUJAN_FORMB_1[j] = Number(dataFORMB_1[j])
                                }
                            }
                        }
                        dataBuild[i][0]["FORM_B"]["RAW_HAL1"]["RAW"] = RAW_DATA_1;
                        dataBuild[i][0]["FORM_B"]["RAW_HAL1"]["MAX_HUJAN"] =   MAX_HUJAN_FORMB_1;
                        dataBuild[i][0]["FORM_B"]["RAW_HAL1"]["JUMLAH"]    = JML_HUJAN_FORMB_1;


                        // FORM B HAL 2
                        var FORMB_2 = rawPeriode.FORM_B.RAW_HAL2.RAW.sort(function(a, b) {
                            return new Date(a.TGL.toString()).getTime() - new Date(b.TGL).getTime();
                        });

                        for(var iFORMB_2 in FORMB_2){
                            var dataFORMB_2 = FORMB_2[iFORMB_2];
                            var dataDate = new Date(dataFORMB_2["TGL"]);

                            if(RAW_DATA_2[Number(dataDate.getDate())-1] != undefined ){
                                RAW_DATA_2[Number(dataDate.getDate())-1] = dataFORMB_2;
                            }

                            var COL_KOREKSI = ["COL4","COL5","COL6","COL7","COL8","COL9","COL10","COL11"];
                            var COL_JUMLAH  = ["COL1","COL2","COL3"];
                            
                            dataFORMB_2["TGL"] = dataDate.getDate();
                            // dataBuild[i][jIndex]["FORM_B"]["RAW_HAL2"]["RAW"].push(dataFORMB_2);

                            for(var j in dataFORMB_2){
                                if(j != "TGL"){
                                    if(JML[j] == undefined){
                                        JML[j] = 0;
                                        MAX_JML[j]  = 0;
                                    }
                                    if(Number(dataFORMB_2[j]) > Number(MAX_JML[j]) ){
                                        MAX_JML[j] = Number(dataFORMB_2[j]);

                                        if(COL_KOREKSI.indexOf(j) != -1){
                                            if(INTENCE_MAX_DATE_FORMB[j] == undefined){
                                                INTENCE_MAX_DATE_FORMB[j] = "";
                                                INTENCE_MAX_FORMB[j] = 0;
                                            }
                                            INTENCE_MAX_FORMB[j]  = Number(dataFORMB_2[j]);
                                            INTENCE_MAX_DATE_FORMB[j] = dataDate.getDate();
                                        }
                                    }
                                    JML[j] += Number(dataFORMB_2[j]);
                                }
                            }
                        }
                        dataBuild[i][0]["FORM_B"]["RAW_HAL2"]["RAW"] = RAW_DATA_2;
                        dataBuild[i][0]["FORM_B"]["RAW_HAL2"]["MAX_HUJAN"] =   MAX_JML;
                        dataBuild[i][0]["FORM_B"]["RAW_HAL2"]["JUMLAH"]    = JML;
                        dataBuild[i][0]["FORM_B"]["RAW_HAL2"]["INTENCE_MAX"]    = {"DATE" : INTENCE_MAX_DATE_FORMB, "VALUES" : INTENCE_MAX_FORMB};
                    }
                    
                    ObserverName_B += ","+rawPeriode.FORM_B.OBSERVER_NAME;
                    dataBuild[i][0]["FORM_A"]["OBSERVER_NAME"] =  ObserverName_A.substring(1);
                    dataBuild[i][0]["FORM_B"]["OBSERVER_NAME"] =  ObserverName_B.substring(1);
                }
                datas = dataBuild;
                console.log(datas);
                resolve(datas);
            }
        });
    }
}