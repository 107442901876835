<template>
    <div>
        <div slot="pdf-content" v-for="(itemForm, indexForm) in dataPDF.data" :key="indexForm">
            <div slot="pdf-content" v-for="(itemValue, indexVaue) in itemForm" :key="indexVaue" style="margin: 30px 10px 5px 10px;font-size:12px;">
                <div>
                    AERODROME CLIMATOLOGICAL SUMMARY
                </div>
                <div style="text-align:center;">
                    <div style="float:left;">TABULAR FORM</div>
                    {{DATA_HEADER[indexVaue].MODEL}}
                </div>
                <table style="width:100%;">
                    <tr>
                        <td>AERODROME</td>
                        <td>:</td>
                        <td>{{dataPDF.station.length > 0 ?dataPDF.station[0].station_name : ""}}</td>

                        <td>MONTH</td>
                        <td>:</td>
                        <td>{{dataPDF.month}}</td>

                        <td>PERIODE OF RECORD</td>
                        <td>:</td>
                        <td>{{dataPDF.years}}</td>
                    </tr>
                    <tr>
                        <td colspan="3">TOTAL NUMBER OF OBSERVATION </td>

                        <td></td>
                        <td>:</td>
                        <td>{{itemValue.TOTAL_OBS}}</td>

                        <td>OBSERVATION TIME</td>
                        <td>:</td>
                        <td>{{itemValue.TIME_OBS}}</td>
                    </tr>

                    <tr>
                        <td>LATITUDE</td>
                        <td>:</td>
                        <td>{{dataPDF.station.length > 0 ? dataPDF.station[0].current_latitude : "" }}  {{ (Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS")}}</td>

                        <td>LONGITUDE</td>
                        <td>:</td>
                        <td>{{dataPDF.station.length > 0 ? dataPDF.station[0].current_longitude : "" }}</td>

                        <td>ELEVATION ABOVE MSL</td>
                        <td>:</td>
                        <td>{{dataPDF.station.length > 0 ? dataPDF.station[0].station_elevation+" M" : "" }} </td>
                    </tr>
                </table>

                <table class="table-ACS">
                    <thead>
                        <tr>
                            <th v-bind:colspan="DATA_HEADER[indexVaue].THEAD_2.length">{{DATA_HEADER[indexVaue].TITLE}}</th>
                        </tr>
                        <tr>
                            <th rowspan="2" style="border-bottom:solid 1px #000;">{{DATA_HEADER[indexVaue].THEAD_1[0]}}</th>
                            <th  v-bind:colspan="DATA_HEADER[indexVaue].THEAD_2.length-1">{{DATA_HEADER[indexVaue].THEAD_1[1]}}</th>
                        </tr>
                        <tr>
                            <th style="border-bottom:solid 1px #000;" v-bind:style="{'width':(100/DATA_HEADER[indexVaue].THEAD_2.length)+'%'}" v-for="(itemHTable, indexHTable) in DATA_HEADER[indexVaue].THEAD_2" :key="indexHTable" v-if="itemHTable.SET_HEADER==true">
                                {{itemHTable.LABEL}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(itemRTable, indexRTable) in DATA_HEADER[indexVaue].RAW" :key="indexRTable" >
                            <td style="">
                                {{itemRTable.split("_").join("-").toUpperCase()}}
                            </td>
                            <td style="text-align:right;"  v-for="(itemHTable, indexHTable) in DATA_HEADER[indexVaue].THEAD_2" :key="indexHTable" v-if="itemHTable.SET_HEADER==true &&  itemRTable != 'calm'">
                            {{itemValue.RAW[itemRTable] != undefined ? itemValue.RAW[itemRTable][itemHTable.KEY] : ""}}
                            </td>
                            <td style="text-align:right; background:#ddd !important;"  v-for="(itemHTable, indexHTable) in DATA_HEADER[indexVaue].THEAD_2" :key="indexHTable" v-if="itemHTable.SET_HEADER==true && itemHTable.KEY != 'COL_TOTAL' && itemRTable == 'calm'">
                            
                            </td>
                            <td style="text-align:right;"  v-for="(itemHTable, indexHTable) in DATA_HEADER[indexVaue].THEAD_2" :key="indexHTable" v-if="itemHTable.SET_HEADER==true && itemHTable.KEY == 'COL_TOTAL' &&   itemRTable == 'calm'">
                            {{itemValue.RAW[itemRTable] != undefined ? itemValue.RAW[itemRTable][itemHTable.KEY] : ""}}
                            </td>
                        
                        </tr>
                        
                        <tr  v-if="DATA_HEADER[indexVaue].SUMMARY.indexOf('RATA') != -1">
                            <td>MEAN</td>
                            <td style="text-align:right;"  v-for="(itemHTable, indexHTable) in DATA_HEADER[indexVaue].THEAD_2" :key="indexHTable" v-if="itemHTable.SET_HEADER==true">
                            {{itemValue.RATA[itemHTable.KEY] != undefined ? itemValue.RATA[itemHTable.KEY] : ""}}
                            </td>
                        </tr>
                        <tr  v-if="DATA_HEADER[indexVaue].SUMMARY.indexOf('TOTAL') != -1">
                            <td>TOTAL</td>
                            <td style="text-align:right;"  v-for="(itemHTable, indexHTable) in DATA_HEADER[indexVaue].THEAD_2" :key="indexHTable" v-if="itemHTable.SET_HEADER==true">
                            {{itemValue.JUMLAH[itemHTable.KEY] != undefined ? itemValue.JUMLAH[itemHTable.KEY] : ""}}
                            </td>
                        </tr>
                        <tr  v-if="itemValue.REMARKS != undefined">
                            <td style="text-align:left;"  v-bind:colspan="DATA_HEADER[indexVaue].THEAD_2.length">{{itemValue.REMARKS}}&nbsp;</td>
                        </tr>
                    </tbody>    
                </table>
                <div class="html2pdf__page-break" v-if="indexVaue != 'E_PERCENT'"/>
            </div>
        </div>
    </div>             
</template>



<script>
export default {
    name: 'ReportAKTINOGRAPH',
    props: {
      dataPDF: Object
    },
    
    methods: {
      fixedValueNumber(values){
        var DataResult = "";
        if(values == null  || values == "-" || values == "NaN"){
          DataResult = "-";
        }else if(values == ""){
          DataResult = "";
        }else{
          DataResult = parseFloat(Number(values).toFixed(1));
        }
        return DataResult;
      }
    },
    data: function () {
        return {
            DATA_HEADER : {
                B_VALUE : {
                    MODEL : "MODEL B",
                    TITLE : "FREQUENCIES OF VISIBILITY BELOW SPECIFIED VALUES (IN METRES) AS SPECIFIED TIMES",
                    THEAD_1 : ["TIME (GMT)","VISIBILITY"],
                    THEAD_2 : [
                        {KEY : "COL_UTC", LABEL : "TIME (GMT)" , SET_HEADER : false},
                        {KEY : "COL_LTE_200", LABEL : "< 200", SET_HEADER : true},
                        {KEY : "COL_LTE_400", LABEL : "< 400", SET_HEADER : true},
                        {KEY : "COL_LTE_600", LABEL : "< 600", SET_HEADER : true},
                        {KEY : "COL_LTE_800", LABEL : "< 800", SET_HEADER : true},
                        {KEY : "COL_LTE_1500", LABEL : "< 1500", SET_HEADER : true},
                        {KEY : "COL_LTE_3000", LABEL : "< 3000", SET_HEADER : true},
                        {KEY : "COL_LTE_5000", LABEL : "< 5000", SET_HEADER : true},
                        {KEY : "COL_LTE_8000", LABEL : "< 8000", SET_HEADER : true},
                    ],
                    RAW : ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
                    SUMMARY : ["RATA"],
                },
                B_PERCENT : {
                    MODEL : "MODEL B",
                    TITLE : "FREQUENCIES (PERCENT) OF VISIBILITY BELOW SPECIFIED VALUES (IN METRES) AS SPECIFIED TIMES",
                    THEAD_1 : ["TIME (GMT)","VISIBILITY"],
                    THEAD_2 : [
                        {KEY : "COL_UTC", LABEL : "TIME (GMT)" , SET_HEADER : false},
                        {KEY : "COL_LTE_200", LABEL : "< 200", SET_HEADER : true},
                        {KEY : "COL_LTE_400", LABEL : "< 400", SET_HEADER : true},
                        {KEY : "COL_LTE_600", LABEL : "< 600", SET_HEADER : true},
                        {KEY : "COL_LTE_800", LABEL : "< 800", SET_HEADER : true},
                        {KEY : "COL_LTE_1500", LABEL : "< 1500", SET_HEADER : true},
                        {KEY : "COL_LTE_3000", LABEL : "< 3000", SET_HEADER : true},
                        {KEY : "COL_LTE_5000", LABEL : "< 5000", SET_HEADER : true},
                        {KEY : "COL_LTE_8000", LABEL : "< 8000", SET_HEADER : true},
                    ],
                    RAW : ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
                    SUMMARY : ["RATA"],
                },
                C_VALUE : {
                    MODEL : "MODEL C",
                    TITLE : "FREQUENCIES (VALUES) OF THE HEIGHT OF THE BASE OF THE LOWEST CLOUD LAYER (FEET) OF BKN OR OVC EXTENT BELOW SPECFIED VALUES AT SPECFIED TIMES",
                    THEAD_1 : ["TIME (GMT)","Hs"],
                    THEAD_2 : [
                        {KEY : "COL_UTC", LABEL : "TIME (GMT)" , SET_HEADER : false},
                        {KEY : "COL_LTE_150", LABEL : "< 150", SET_HEADER : true},
                        {KEY : "COL_LTE_200", LABEL : "< 200", SET_HEADER : true},
                        {KEY : "COL_LTE_300", LABEL : "< 300", SET_HEADER : true},
                        {KEY : "COL_LTE_500", LABEL : "< 500", SET_HEADER : true},
                        {KEY : "COL_LTE_1000", LABEL : "< 1000", SET_HEADER : true},
                        {KEY : "COL_LTE_1500", LABEL : "< 1500", SET_HEADER : true},
                    ],
                    RAW : ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
                    SUMMARY : ["RATA"],
                },
                C_PERCENT : {
                    MODEL : "MODEL C",
                    TITLE : "FREQUENCIES (PERCENT) OF THE HEIGHT OF THE BASE OF THE LOWEST CLOUD LAYER (FEET) OF BKN OR OVC EXTENT BELOW SPECFIED VALUES AT SPECFIED TIMES",
                    THEAD_1 : ["TIME (GMT)","Hs"],
                    THEAD_2 : [
                        {KEY : "COL_UTC", LABEL : "TIME (GMT)" , SET_HEADER : false},
                        {KEY : "COL_LTE_150", LABEL : "< 150", SET_HEADER : true},
                        {KEY : "COL_LTE_200", LABEL : "< 200", SET_HEADER : true},
                        {KEY : "COL_LTE_300", LABEL : "< 300", SET_HEADER : true},
                        {KEY : "COL_LTE_500", LABEL : "< 500", SET_HEADER : true},
                        {KEY : "COL_LTE_1000", LABEL : "< 1000", SET_HEADER : true},
                        {KEY : "COL_LTE_1500", LABEL : "< 1500", SET_HEADER : true},
                    ],
                    RAW : ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
                    SUMMARY : ["RATA"],
                },
                D_VALUE : {
                    MODEL : "MODEL D",
                    TITLE : "FREQUENCIES (VALUES) OF CONCURENT WIND DIRECTION (IN 30 SECTOR) AND SPEED (IN KNOTS) WITHIN SPECIFIED RANGES",
                    THEAD_1 : ["WIND DIRECTION","WIND SPEED"],
                    THEAD_2 : [
                        {KEY : "WIND_DIR", LABEL : "WIND DIRECTION", SET_HEADER : false},
                        {KEY : "COL_1_5", LABEL : "1 - 5", SET_HEADER : true},
                        {KEY : "COL_6_10", LABEL : "6 - 10", SET_HEADER : true},
                        {KEY : "COL_11_15", LABEL : "11 - 15", SET_HEADER : true},
                        {KEY : "COL_16_20", LABEL : "16 - 20", SET_HEADER : true},
                        {KEY : "COL_21_25", LABEL : "21 - 25", SET_HEADER : true},
                        {KEY : "COL_26_30", LABEL : "26 - 30", SET_HEADER : true},
                        {KEY : "COL_31_35", LABEL : "31 - 35", SET_HEADER : true},
                        {KEY : "COL_36_40", LABEL : "36 - 40", SET_HEADER : true},
                        {KEY : "COL_41_45", LABEL : "41 - 45", SET_HEADER : true},
                        {KEY : "COL_GTE_45", LABEL : ">45", SET_HEADER : true},
                        {KEY : "COL_TOTAL", LABEL : "TOTAL", SET_HEADER : true},
                    ],
                    RAW : ["calm","variable","35_36_01","02_03_04","05_06_07","08_09_10","11_12_13","14_15_16","17_18_19","20_21_22","23_24_25","26_27_28","29_30_31","32_33_34"],
                    SUMMARY : ["TOTAL"],
                },
                D_PERCENT : {
                    MODEL : "MODEL D",
                    TITLE : "FREQUENCIES (PERCENT) OF CONCURENT WIND DIRECTION (IN 30 SECTOR) AND SPEED (IN KNOTS) WITHIN SPECIFIED RANGES",
                    THEAD_1 : ["WIND DIRECTION","WIND SPEED"],
                    THEAD_2 : [
                        {KEY : "WIND_DIR", LABEL : "WIND DIRECTION", SET_HEADER : false},
                        {KEY : "COL_1_5", LABEL : "1 - 5", SET_HEADER : true},
                        {KEY : "COL_6_10", LABEL : "6 - 10", SET_HEADER : true},
                        {KEY : "COL_11_15", LABEL : "11 - 15", SET_HEADER : true},
                        {KEY : "COL_16_20", LABEL : "16 - 20", SET_HEADER : true},
                        {KEY : "COL_21_25", LABEL : "21 - 25", SET_HEADER : true},
                        {KEY : "COL_26_30", LABEL : "26 - 30", SET_HEADER : true},
                        {KEY : "COL_31_35", LABEL : "31 - 35", SET_HEADER : true},
                        {KEY : "COL_36_40", LABEL : "36 - 40", SET_HEADER : true},
                        {KEY : "COL_41_45", LABEL : "41 - 45", SET_HEADER : true},
                        {KEY : "COL_GTE_45", LABEL : ">45", SET_HEADER : true},
                        {KEY : "COL_TOTAL", LABEL : "TOTAL", SET_HEADER : true},
                    ],
                    RAW : ["calm","variable","35_36_01","02_03_04","05_06_07","08_09_10","11_12_13","14_15_16","17_18_19","20_21_22","23_24_25","26_27_28","29_30_31","32_33_34"],
                    SUMMARY : ["TOTAL"],
                },
                E_VALUE : {
                    MODEL : "MODEL E",
                    TITLE : "FREQUENCIES OF SURFACE TEMPERATURE (SCREEN) IN SPECIFIED RANGES OF 5° C AT SPECIFIED TIMES",
                    THEAD_1 : ["TIME (GMT)","TEMPERATURE"],
                    THEAD_2 : [
                        {KEY : "COL_UTC", LABEL : "TIME (GMT)", SET_HEADER : false},
                        {KEY : "COL_5_0", LABEL : "-5 - 0", SET_HEADER : true},
                        {KEY : "COL_0_5", LABEL : "0 - 5", SET_HEADER : true},
                        {KEY : "COL_5_10", LABEL : "5 - 10", SET_HEADER : true},
                        {KEY : "COL_10_15", LABEL : "10 - 15", SET_HEADER : true},
                        {KEY : "COL_15_20", LABEL : "15 - 20", SET_HEADER : true},
                        {KEY : "COL_20_25", LABEL : "20 - 25", SET_HEADER : true},
                        {KEY : "COL_25_30", LABEL : "25 - 30", SET_HEADER : true},
                        {KEY : "COL_30_35", LABEL : "30 -35", SET_HEADER : true},
                        {KEY : "COL_GTE_35", LABEL : ">35", SET_HEADER : true}
                    ],
                    RAW : ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
                    SUMMARY : ["RATA"],
                },
                E_PERCENT : {
                    MODEL : "MODEL E",
                    TITLE : "FREQUENCIES (PERCENT) OF SURFACE TEMPERATURE (SCREEN) IN SPECIFIED RANGES OF 5° C AT SPECIFIED TIMES",
                    THEAD_1 : ["TIME (GMT)","TEMPERATURE"],
                    THEAD_2 : [
                        {KEY : "COL_UTC", LABEL : "TIME (GMT)", SET_HEADER : false},
                        {KEY : "COL_5_0", LABEL : "-5 - 0", SET_HEADER : true},
                        {KEY : "COL_0_5", LABEL : "0 - 5", SET_HEADER : true},
                        {KEY : "COL_5_10", LABEL : "5 - 10", SET_HEADER : true},
                        {KEY : "COL_10_15", LABEL : "10 - 15", SET_HEADER : true},
                        {KEY : "COL_15_20", LABEL : "15 - 20", SET_HEADER : true},
                        {KEY : "COL_20_25", LABEL : "20 - 25", SET_HEADER : true},
                        {KEY : "COL_25_30", LABEL : "25 - 30", SET_HEADER : true},
                        {KEY : "COL_30_35", LABEL : "30 -35", SET_HEADER : true},
                        {KEY : "COL_GTE_35", LABEL : ">35", SET_HEADER : true}
                    ],
                    RAW : ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
                    SUMMARY : ["RATA"],
                },
            }
        };
    },

};
</script>

<style >
.table-ACS{
    width:100%;
    margin-top:20px ;
}

.table-ACS thead > tr  > th:last-of-type{
  border-right:solid 1px #000;
}

.table-ACS > thead > tr > th {
  text-align: center;
  background: #56a1fc;
  color: #FFFFFF;
  border-left: solid 1px #000 !important;
  border-top: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
  padding: 2px !important;
}

.table-ACS  tbody > tr > td {
  margin: 0px;
  text-align: center;
  border-left: solid 0.5px #000;
  border-bottom: solid 0.5px #000;
  padding:5px;
}

.table-ACS  tbody > tr > td:last-of-type {
 border-right:solid 0.5px #000;
}
</style>