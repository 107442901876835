<template>
  <div style="margin:25px;">
    <div slot="pdf-content" v-for="(item, periode) in dataPDF.data" :key="periode">
      <div class="title-report-PICHEPENGUAPAN">
        {{ dataPDF.title }}
        <div v-if="dataPDF.station.length > 0">{{ dataPDF.station[0].station_name }}</div>
      </div>

      <div style="font-size:12px;margin-top:10px;margin-bottom:10px;color:#000;">
        <div style="float:left;min-width:50%;">
          <div style="float:left;width:120px;">BULAN</div>
          <div style="float:left;width:20px;">:</div>
          <div style="float:left;min-width:200px;">{{ dataPDF.bulan }}</div>
        </div>
        <div style="float:right;min-width:50%;">
          <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_latitude }} {{ Number(dataPDF.station[0].current_latitude) >= 0 ? "LU" : "LS" }}</div>
          <div style="float:right;width:20px;">:</div>
          <div style="float:right;width:120px;">LINTANG</div>
        </div>
        <div style="clear:both"></div>

        <div style="float:left;min-width:50%;">
          <div style="float:left;width:120px;">TAHUN</div>
          <div style="float:left;width:20px;">:</div>
          <div style="float:left;min-width:200px;">{{ dataPDF.tahun }}</div>
        </div>
        <div style="float:right;min-width:50%;">
          <div style="float:right;min-width:100px;">{{ dataPDF.station[0].current_longitude }} BT</div>
          <div style="float:right;width:20px;">:</div>
          <div style="float:right;width:120px;">BUJUR</div>
        </div>
        <div style="clear:both"></div>

        <div style="float:left;min-width:50%;">
          <div style="float:left;width:120px;"></div>
          <div style="float:left;width:20px;"></div>
          <div style="float:left;min-width:200px;"></div>
        </div>
        <div style="float:right;min-width:50%;">
          <div style="float:right;min-width:100px;">{{ dataPDF.station[0].station_elevation }} m</div>
          <div style="float:right;width:20px;">:</div>
          <div style="float:right;width:120px;">ELEVASI</div>
        </div>
        <div style="clear:both"></div>
      </div>

      <div style="margin-top:10px;">
        <table class="table-report-PICHEPENGUAPAN">
          <thead>
            <tr>
              <td rowspan="2">TGL</td>
              <td colspan="3">PEMBACAAN</td>
              <td colspan="3">PENGUAPAN</td>
              <td rowspan="2">JUMLAH</td>
            </tr>
            <tr>
              <td style="border-top:none;">I</td>
              <td style="border-top:none;">II</td>
              <td style="border-top:none;">III</td>
              <td style="border-top:none;">I</td>
              <td style="border-top:none;">II</td>
              <td style="border-top:none;border-right:none;">III</td>
            </tr>
            <tr>
              <td style="border-top:none; width:115px;">1</td>
              <td style="border-top:none; width:115px;" v-for="n in 7" :key="n + '_headNumberPICHEPENGUAPAN'">{{ n + 1 }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(raw, indexRaw) in item.RAW" :key="indexRaw + '_bodyPICHEPENGUAPAN'">
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(indexRaw + 1) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL1) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL2) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL3) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL4) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL5) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL6) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(raw.COL7) }}</td>
            </tr>
            <tr v-for="o in 31 - item.RAW.length" :key="o + item.RAW.length + '_bodyEmptyPICHEPENGUAPAN'">
              <td style="border-top:none;">{{ o + item.RAW.length }}</td>
              <td v-for="j in 7" :key="j + '_collPICHEPENGUAPAN'" style="border-top:none;"></td>
            </tr>
            <tr>
              <td style="border-top:none;text-align:left;">Jumlah</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL1) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL2) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL3) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL4) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL5) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL6) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.JUMLAH.COL7) }}</td>
            </tr>
            <tr>
              <td style="border-top:none;text-align:left;">Rata<sup>2</sup></td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.RATA.COL1) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.RATA.COL2) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.RATA.COL3) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.RATA.COL4) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.RATA.COL5) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.RATA.COL6) }}</td>
              <td style="border-top:none;text-align:center;">{{ fixedValueNumber(item.CALCULATE.RATA.COL7) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportPICHEPENGUAPAN",
  props: {
    dataPDF: Object,
  },
  methods: {
    fixedValueNumber(values) {
      var DataResult = "";
      if (values == null || values == "-" || values == "NaN") {
        DataResult = "-";
      } else if (values == "") {
        DataResult = "";
      } else {
        DataResult = parseFloat(Number(values).toFixed(1));
      }
      return DataResult;
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style>
.title-report-PICHEPENGUAPAN {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: bold !important;
}

.PICHEPENGUAPAN td.rotation_90 {
  max-width: 50px;
  height: 100%;
  padding-bottom: 0px;
  text-align: center;
  vertical-align: bottom;
}

.table-report-PICHEPENGUAPAN td.rotation_90 > div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  margin-bottom: 20px;
}

.table-report-PICHEPENGUAPAN > thead > tr > td {
  text-align: center;
  background: #e0e4e7;
  color: #000000;
  border-left: solid 1px #000 !important;
  text-align: center;
  font-weight: bold;
}

.table-report-PICHEPENGUAPAN tbody > tr > td {
  padding: 3px !important;
  margin: 0px;
  text-align: center;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
}

.table-report-PICHEPENGUAPAN tbody > tr > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-PICHEPENGUAPAN tr {
  border: none !important;
}

.table-report-PICHEPENGUAPAN thead :not(.table-code) > td {
  padding: 5px;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
}

.table-report-PICHEPENGUAPAN thead :not(.table-code) > td:last-of-type {
  border-right: solid 1px #000;
}

.table-report-PICHEPENGUAPAN tbody > tr {
  background-color: #ffffff;
}

.table-report-PICHEPENGUAPAN tbody > tr:nth-child(even) {
  background-color: #ffffff;
}

.table-report-PICHEPENGUAPAN {
  font-size: 8.5px !important;
  margin-bottom: 20px !important;
  width: 100%;
}
/* 
.table-report-PICHEPENGUAPAN td{
  max-width: 90px;
  height: 30px;
} */
</style>
